import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, Select } from 'antd';

import { useReceiveFromBusinessContext } from 'customHooks';

import { ReactComponent as ProfileIcon } from 'assets/bosta-icons/Profile.svg';

import './ContactPersonSelect.less';

const ContactPersonSelect = () => {
  const intl = useIntl();
  const { businessContactPersons, loading, form } =
    useReceiveFromBusinessContext();
  const firstContactPerson = businessContactPersons[0]?.contactPerson;

  const contactPersonListDisabled =
    loading || businessContactPersons.length < 2 || !firstContactPerson;

  const contacts = businessContactPersons.flatMap(
    (businessLocation) => businessLocation.contacts
  );

  useEffect(() => {
    form.setFieldsValue({
      contactPerson: firstContactPerson?._id
    });
  }, [firstContactPerson, form]);

  return (
    <div className="pickup-scanning__business-info__contact-person">
      <label htmlFor="contactPerson">
        {intl.formatMessage({
          id: 'hubs.receive_from_business.pickup_scanning.business_info.contact_person'
        })}
        <br />
        <span>
          {intl.formatMessage({
            id: 'hubs.receive_from_business.pickup_scanning.business_info.contact_person_note'
          })}
        </span>
      </label>

      <Form.Item
        name="contactPerson"
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'hubs.receive_from_business.pickup_scanning.business_info.contact_person_validation'
            })
          }
        ]}
        initialValue={firstContactPerson?._id}
      >
        <Select
          dropdownMatchSelectWidth
          disabled={
            contactPersonListDisabled || businessContactPersons.length === 1
          }
          suffixIcon={<ProfileIcon />}
          getPopupContainer={(trigger) => trigger.parentNode}
          value={firstContactPerson?._id}
        >
          {contacts.map(({ _id, name, phone }) => (
            <Select.Option key={_id} value={_id}>
              {name}
              <span className="business-info__contact-person__phone-number">
                {phone}
              </span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default ContactPersonSelect;
