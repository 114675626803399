import { useIntl } from 'react-intl';

import { ReactComponent as ScanningIcon } from 'assets/bosta-icons/scanning.svg';

import './BusinessInfoHeader.less';

const BusinessInfoHeader = () => {
  const intl = useIntl();
  return (
    <>
      <div className="business-info__header-icon display-flex align-center justify-center">
        <ScanningIcon />
      </div>
      {intl.formatMessage({
        id: 'hubs.receive_from_business.pickup_scanning.business_info.title'
      })}
    </>
  );
};

export default BusinessInfoHeader;
