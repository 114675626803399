import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import InvalidatedBusinessModal from 'components/Businesses/InvalidatedBusinessModal/InvalidatedBusinessModal';
import BusinessSwitch from '../BusinessSwitch';

const BusinessValiditySwitch = ({ businessData, submitBusinessUpdate }) => {
  const { _id: businessId, status: { value: businessStatus } = {} } =
    businessData;
  const isBusinessValid = businessStatus?.toLowerCase() === 'valid';

  const disableSwitch = !isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
    ACL_MATRIX.BUSINESS_VALIDATE_STATUS
  ]);

  const status = isBusinessValid ? 'Invalid' : 'Valid';

  const switchBusinessValidity = ({ value, ...options }) => {
    submitBusinessUpdate({ status }, options);
  };

  const confirmationModal = isBusinessValid && {
    modal: InvalidatedBusinessModal,
    modalProps: ({ value, ...options }) => ({
      businessId,
      handleChangeBusinessStatus: ({ restPayload }) => {
        submitBusinessUpdate(
          {
            status,
            ...restPayload
          },
          options
        );
      }
    })
  };

  return (
    <BusinessSwitch
      disabled={disableSwitch}
      initialChecked={isBusinessValid}
      onChange={switchBusinessValidity}
      confirmationModal={confirmationModal}
    />
  );
};

export default BusinessValiditySwitch;
