import { ManageBusinessCells } from 'constants/business-details';

import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import BusinessSettingsPanel from './BusinessSettingsPanel/BusinessSettingsPanel';

import './ManageBusiness.less';

const ManageBusiness = ({ businessData, refreshData }) => {
  const renderSettings = () => (
    <BusinessSettingsPanel
      businessData={businessData}
      refreshData={refreshData}
    />
  );

  return (
    <BRInformationTable
      className="br-business-details__settings-panel"
      cells={ManageBusinessCells(renderSettings)}
    />
  );
};

export default ManageBusiness;
