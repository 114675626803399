import { fmt } from 'IntlWrapper/IntlWrapper';

export const OPS_CONTROL_ROOM_HUB_DISPATCHING_DELIVERY_STATES = {
  PENDING: 'PENDING',
  TO_BE_DISPATCHED: 'TO_BE_DISPATCHED',
  ADDED_TO_ROUTE: 'ADDED_TO_ROUTE',
  PENDING_NOT_RECEIVED: 'PENDING_NOT_RECEIVED',
  LATE: 'LATE',
  MISSED: 'MISSED'
};

export const HUBS_OVERVIEW_TABLE_COLUMNS = [
  {
    title: fmt({ id: 'ops_control.hubs_overview.hubs_table.hub' }),
    dataIndex: 'hub'
  },
  {
    title: fmt({ id: 'ops_control.hubs_overview.hubs_table.to_be_dispatched' }),
    dataIndex: 'to_be_dispatched',
    sorter: (a, b) => a.to_be_dispatched - b.to_be_dispatched
  },
  {
    title: fmt({ id: 'ops_control.hubs_overview.hubs_table.dispatched' }),
    dataIndex: 'dispatched',
    sorter: (a, b) => a.dispatched - b.dispatched
  },
  {
    title: fmt({ id: 'ops_control.hubs_overview.hubs_table.late' }),
    dataIndex: 'late',
    sorter: (a, b) => a.late - b.late
  },
  {
    title: fmt({
      id: 'ops_control.hubs_overview.hubs_table.missed'
    }),
    dataIndex: 'missed',
    sorter: (a, b) => a.missed - b.missed
  }
];

export const SINGLE_CARDS_INFOS = (
  {
    toBeDispatched,
    pending,
    addedToRoute,
    dispatched,
    late,
    missed,
    exceeded,
    attempts
  },
  exportFunction,
  exportDispatchingCard
) => [
  {
    data: toBeDispatched,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.to_be_dispatched'
    }),
    exportFunction,
    deliveryReceivedState:
      OPS_CONTROL_ROOM_HUB_DISPATCHING_DELIVERY_STATES.TO_BE_DISPATCHED
  },
  {
    data: pending,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.pending'
    }),
    exportFunction,
    deliveryReceivedState:
      OPS_CONTROL_ROOM_HUB_DISPATCHING_DELIVERY_STATES.PENDING_NOT_RECEIVED
  },
  {
    data: addedToRoute,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.added_to_route'
    }),
    exportFunction,
    deliveryReceivedState:
      OPS_CONTROL_ROOM_HUB_DISPATCHING_DELIVERY_STATES.ADDED_TO_ROUTE
  },
  {
    data: dispatched,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.dispatched'
    }),
    exportFunction: exportDispatchingCard
  },
  {
    data: late,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.late'
    }),
    exportFunction,
    deliveryReceivedState: OPS_CONTROL_ROOM_HUB_DISPATCHING_DELIVERY_STATES.LATE
  },
  {
    data: missed,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.missed'
    }),
    exportFunction,
    deliveryReceivedState:
      OPS_CONTROL_ROOM_HUB_DISPATCHING_DELIVERY_STATES.MISSED
  },
  {
    data: attempts,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.attempts'
    }),
    exportFunction,
    zeroAttemptsOrders: true
  },
  {
    data: exceeded,
    title: fmt({
      id: 'ops_control.hubs_overview.single_cards_titles.exceeded'
    }),
    exportFunction,
    exceededSlaOrders: true
  }
];

export const HUB_VIEW_TABLE_COLUMNS = [
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.tracking_number'
    }),
    dataIndex: 'tracking_number'
  },
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.comments'
    }),
    dataIndex: 'comments'
  },
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.order_type'
    }),
    dataIndex: 'order_type'
  },
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.hub_aging'
    }),
    dataIndex: 'hub_aging'
  },
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.e2e_aging'
    }),
    dataIndex: 'e2e_aging'
  },
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.attempts'
    }),
    dataIndex: 'attempts'
  },
  {
    title: fmt({
      id: 'ops_control.hub_view.table_columns.ticket'
    }),
    dataIndex: 'ticket_number'
  }
];

export const OFD_EXPORT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
