import {useEffect, useState} from 'react';

import {getBusinessContactPersons} from "services/business";

import {notify} from "components/Notify/Notify";

const useBusinessContactPersons = (businessId) => {
  const [loading, setLoading] = useState(false);
  const [businessContactPersons, setBusinessContactPersons] = useState([]);

  useEffect(() => {
    if(!businessId) {
      setBusinessContactPersons([]);
      return;
    }

    (async () => {
      setLoading(true);
      try {
        const { data } = await getBusinessContactPersons({ businessId });
        setBusinessContactPersons(data);
      } catch (error) {
        notify(error.message);
        setBusinessContactPersons([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [businessId]);

  return {
    loadingBusinessContactPersons: loading,
    businessContactPersons,
  };
}

export default useBusinessContactPersons;