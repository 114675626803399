import { Select } from 'antd';

const HubSelection = ({
  allHubs,
  currentHub,
  intl,
  loading,
  handleHubChange,
  hubSelectionPlaceholder,
  isHubFilterDisabled
}) => {
  const hubsOptions = allHubs?.map((hub) => {
    return { value: hub._id, label: hub.name };
  });
  return (
    <Select
      disabled={loading || isHubFilterDisabled}
      value={currentHub}
      onChange={handleHubChange}
      allowClear
      showSearch
      showArrow
      options={hubsOptions}
      optionFilterProp="label"
      placeholder={
        hubSelectionPlaceholder ||
        intl.formatMessage({
          id: 'ops_control.hub_selector_placeholder'
        })
      }
    />
  );
};
export default HubSelection;
