import { useIntl } from 'react-intl';

import { RECURRING_PICKUP_BUSINESS_TIERS } from 'constants/pickups';
import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import BusinessSwitch from '../BusinessSwitch';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';

const BusinessRecurringPickupsSwitch = ({
  businessData = {},
  submitBusinessUpdate
}) => {
  const intl = useIntl();

  const {
    _id: businessId,
    name: businessName,
    businessTier,
    allowRecurringPickups
  } = businessData;

  const isBusinessTierActivated =
    RECURRING_PICKUP_BUSINESS_TIERS.includes(businessTier);
  const isRecurringPickupsActivated =
    allowRecurringPickups || isBusinessTierActivated;

  const disableSwitch = !(
    isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
      ACL_MATRIX.BUSINESS_UPDATE_RECURRING_PICKUPS_OPTION
    ]) && !isBusinessTierActivated
  );

  const confirmationModal = {
    modal: ConfirmationModal,
    modalProps: ({ value, ...options }) => ({
      title: intl.formatMessage({
        id: `business_details.activating_recurring_pickups.${
          !isRecurringPickupsActivated ? 'activate_title' : 'deactivate_title'
        }`
      }),
      content: intl.formatMessage(
        {
          id: `business_details.activating_recurring_pickups.${
            !isRecurringPickupsActivated
              ? 'activate_content'
              : 'deactivate_content'
          }`
        },
        {
          businessName,
          businessId,
          span: (children) => <span className="body-medium">{children}</span>
        }
      ),
      confirmButtonLabel: intl.formatMessage({ id: 'common.confirm' }),
      confirmButtonType: 'primary',
      modalAction: () => {
        submitBusinessUpdate(
          {
            allowRecurringPickups: !isRecurringPickupsActivated
          },
          options
        );
      }
    })
  };

  return (
    <BusinessSwitch
      confirmationModal={confirmationModal}
      disabled={disableSwitch}
      initialChecked={isRecurringPickupsActivated}
    />
  );
};

export default BusinessRecurringPickupsSwitch;
