import { useContext, useState } from 'react';
import { Select, Form } from 'antd';
import { useIntl } from 'react-intl';
import compact from 'lodash/compact';

import { HubsContext } from 'contexts/hub.context';
import { getStarsActionsLocation } from 'services/stars';
import { getHubZones, getStreetCodes } from 'services/zoning';
import { openModal } from 'utils/modal';

import BRButton from 'components/BRButton/BRButton';
import CreateNewDistrictModal from 'components/BostaCoding/components/DistrictsMapping/components/CreateNewDistrictModal/CreateNewDistrictModal';
import AddToDistrictModal from 'components/BostaCoding/components/DistrictsMapping/components/AddToDistrictModal/AddToDistrictModal';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as Plus } from 'assets/bosta-icons/Plus.svg';

import './DistrictsMappingFilters.less';

const DistrictsMappingFilters = ({
  setIsLoading,
  isLoading,
  setHubStarsActionsPolygons,
  setExistingHubStreetCodesPolygons,
  form,
  selectedPolygonIndex,
  polygonRef
}) => {
  const [hubZones, setHubZones] = useState([]);

  const { allHubs } = useContext(HubsContext);

  const intl = useIntl();
  const selectedHubId = Form.useWatch('selectedHubId', form);

  const fetchZoneStreetCodes = async (zonesIds) => {
    try {
      const { data } = await getStreetCodes({
        zoneId: zonesIds
      });
      const zonesPolygons = data.map((zone) => {
        const zoneGeometry = zone.geometry?.coordinates?.[0];

        if (zoneGeometry) {
          return zoneGeometry.map((coordinate) => ({
            lat: coordinate[1],
            lng: coordinate[0]
          }));
        }

        return null;
      });

      setExistingHubStreetCodesPolygons(compact(zonesPolygons));
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchHubZones = async (hubId) => {
    try {
      const data = await getHubZones(hubId);
      const zonesIds = data.map((zone) => zone._id);
      setHubZones(data);
      fetchZoneStreetCodes(zonesIds);
    } catch (error) {
      notify(error.message);
    }
  };

  const formatPolygonCords = (polygons) => {
    return polygons.map((polygon) =>
      polygon.map((coordinate) => ({ lat: coordinate[0], lng: coordinate[1] }))
    );
  };

  const fetchStarActionsLocation = async (hubId) => {
    try {
      const { message } = await getStarsActionsLocation(hubId);
      const formattedPolygons = formatPolygonCords(message);
      setHubStarsActionsPolygons(formattedPolygons);
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnHubChange = async (hubId) => {
    setIsLoading(true);

    await Promise.all([fetchStarActionsLocation(hubId), fetchHubZones(hubId)]);

    setIsLoading(false);
  };

  const handleOnCreateNewDistrict = () => {
    openModal(CreateNewDistrictModal, {
      zones: hubZones,
      hubId: selectedHubId,
      polygonRef,
      onSuccess: () => {
        const zones = hubZones.map((zone) => zone._id);
        fetchZoneStreetCodes(zones);
      }
    });
  };

  const handleOnAddToDistrict = () => {
    openModal(AddToDistrictModal, {
      zones: hubZones,
      hubId: selectedHubId,
      polygonRef,
      onSuccess: () => {
        const zones = hubZones.map((zone) => zone._id);
        fetchZoneStreetCodes(zones);
      }
    });
  };

  return (
    <div className="br-districts-mapping__filters-container">
      <div className="br-districts-mapping__filters">
        <Form.Item name="selectedHubId">
          <Select
            options={allHubs}
            fieldNames={{ label: 'name', value: '_id' }}
            onChange={handleOnHubChange}
            showSearch
            showArrow
            optionFilterProp="name"
            placeholder={intl.formatMessage({
              id: 'geofences.route_geofences.tabs.yesterday_routes.select_hub'
            })}
          />
        </Form.Item>
      </div>
      <div className="br-districts-mapping__actions">
        <BRButton
          label={intl.formatMessage({
            id: 'bosta_coding.district_mapping.add_to_district'
          })}
          disabled={isLoading || !selectedPolygonIndex}
          onClick={handleOnAddToDistrict}
        />
        <BRButton
          type="primary"
          prefixIcon={<Plus />}
          label={intl.formatMessage({
            id: 'bosta_coding.district_mapping.create_new_district'
          })}
          disabled={isLoading || !selectedPolygonIndex}
          onClick={handleOnCreateNewDistrict}
        />
      </div>
    </div>
  );
};

export default DistrictsMappingFilters;
