import React, { useCallback } from 'react';
import { withRouter } from 'react-router';
import { Tabs } from 'antd';

import aclMatrix from 'common/aclMatrix';
import { isUserAuthorized } from 'utils/helpers';
import { ACL_MATRIX } from 'common/permissions';

const {
  HUB_MANAGEMENT,
  HUB_MANAGEMENT_TABS_EXCEPT_MONEY_DEBREIF_REPORT,
  RECEIVED_BY_MISTAKE
} = aclMatrix;

const listRoutes = [
  {
    path: '/hubs/hub-management/all-hubs',
    text: 'All Hubs',
    access: HUB_MANAGEMENT_TABS_EXCEPT_MONEY_DEBREIF_REPORT,
    permission: [ACL_MATRIX.HUBS_CREATE]
  },
  {
    path: '/hubs/hub-management/money-debriefing-report',
    text: 'Money Debriefing Report',
    access: HUB_MANAGEMENT,
    permission: [ACL_MATRIX.CASH_MONEY_GET_MONEY_DEBRIEFING_REPORT]
  },
  {
    path: '/hubs/hub-management/transfers',
    text: 'Hub Transfers',
    access: HUB_MANAGEMENT_TABS_EXCEPT_MONEY_DEBREIF_REPORT,
    permission: [ACL_MATRIX.HUBS_TRANSFER_LIST]
  },
  {
    path: '/hubs/hub-management/deliveries',
    text: 'Manage Deliveries',
    access: HUB_MANAGEMENT_TABS_EXCEPT_MONEY_DEBREIF_REPORT,
    permission: [ACL_MATRIX.DELIVERY_MARK_AS_AUTO_DELIVERED]
  },
  {
    path: '/hubs/hub-management/received-by-mistake',
    text: 'Received by Mistake',
    access: RECEIVED_BY_MISTAKE,
    permission: [ACL_MATRIX.DELIVERY_MARK_AS_RECEIVED_BY_MISTAKE]
  },
  {
    path: '/hubs/hub-management/update-cod',
    text: 'Update Cod',
    access: [],
    permission: [ACL_MATRIX.DELIVERY_UPDATE_COD_SKIPPING_CHECKS]
  }
];

function HubManagmentContainer({
  children,
  title = 'HUB MANAGEMENT',
  history,
  location: { pathname }
}) {
  const handleTabChanges = useCallback(
    (key) => {
      history.push(key);
    },
    [history]
  );
  return (
    <Tabs defaultActiveKey={pathname} onChange={handleTabChanges}>
      {listRoutes.map(
        ({ text, path, access, permission }) =>
          isUserAuthorized(access, permission) && (
            <Tabs.TabPane tab={text} key={path}>
              {children}
            </Tabs.TabPane>
          )
      )}
    </Tabs>
  );
}

export default withRouter(HubManagmentContainer);
