import { Form } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ReceiveFromBusinessContext } from 'contexts/hub.context';
import { validateScannedPackage } from 'utils/hubs';
import { IS_SAUDI } from 'constants/helper';
import { receivePackagesAtHub } from 'services/hubs';
import useBusinessContactPersons from 'hooks/hubs/useBusinessContactPersons';
import useHubPackages from 'hooks/hubs/useHubPackages';
import useTrackingNumberValidation from 'hooks/hubs/useTrackingNumberValidation';
import useSealNumberValidation from 'hooks/hubs/useSealNumberValidation';
import usePackageSizes from 'hooks/hubs/usePackageSizes';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import PickupsTabActions from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/PickupsTabActions/PickupsTabActions';
import PickupScanning from './components/PickupScanning/PickupScanning';

import './ReceiveFromBusiness.less';

const useWeights = IS_SAUDI;

const validatePackageBelongsToSameBusiness = (packageInfo, businessInfo) => {
  if (!businessInfo) return true;
  return packageInfo.sender._id === businessInfo._id;
};

const validatePackageState = (packageInfo) => {
  return packageInfo.state.code === 10;
};

const ReceiveFromBusiness = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [submittingPackages, setSubmittingPackages] = useState(false);

  const { packages, addPackage, removePackage, clearPackages } =
    useHubPackages();
  const businessInfo = packages?.[0]?.sender;

  const { allPricingPackageSize, loadingPricingPackageSizes } =
    usePackageSizes();

  const { handleTrackingNumberChange, loadingTrackingNumberValidation } =
    useTrackingNumberValidation(
      packages,
      () => {},
      (args) => {
        handleChangeOfPackages({ ...args, isScannedFromSeal: false });
      },
      [allPricingPackageSize]
    );

  const { handleSealNumberChange, loadingSealNumberValidation } =
    useSealNumberValidation(
      packages,
      (args) => {
        handleChangeOfPackages({
          ...args,
          isScannedFromSeal: true
        });
      },
      [allPricingPackageSize]
    );

  const { businessContactPersons, loadingBusinessContactPersons } =
    useBusinessContactPersons(businessInfo?._id);

  const loading = [
    loadingPricingPackageSizes,
    loadingTrackingNumberValidation,
    loadingSealNumberValidation,
    loadingBusinessContactPersons,
    submittingPackages
  ].some((el) => el);

  const allowSubmit = [
    !loading,
    packages.length > 0,
    !loadingBusinessContactPersons,
    businessContactPersons.length > 0
  ].every((el) => el);

  const handleChangeOfPackages = (args) => {
    const validatedPackage = validateScannedPackage({
      intl,
      packages,
      ...args
    });
    if (!validatedPackage) {
      return;
    }

    const packageBelongsToSameBusiness = validatePackageBelongsToSameBusiness(
      validatedPackage,
      businessInfo
    );
    if (!packageBelongsToSameBusiness) {
      notify('Package does not belong to same business', 'error', true);
      return;
    }

    const packageIsInCreatedState = validatePackageState(validatedPackage);
    if (!packageIsInCreatedState) {
      notify(
        'Package cannot be received by business at this time',
        'error',
        true
      );
      return;
    }

    addPackage(validatedPackage);
    form.setFieldsValue({
      deliveries: [...packages, validatedPackage].map(
        ({ _id, pricingPackageSize, scanType, packageWeight }) => ({
          _id,
          pricingPackageSize,
          scanType,
          packageWeight
        })
      )
    });
    notify('Package added successfully', 'success', true);
  };

  const handleSubmitForm = async (values) => {
    setSubmittingPackages(true);

    const contactPerson = businessContactPersons.find(
      (el) => el.contactPerson._id === values.contactPerson
    ).contactPerson;

    try {
      const { failedDeliveries } = await receivePackagesAtHub({
        ...values,
        contactPerson
      });

      const notification =
        failedDeliveries?.length
          ? {
              message: intl.formatMessage(
                { id: 'hubs.receive_from_business.submit.failed_deliveries' },
                {
                  deliveries: failedDeliveries.join(', ')
                }
              ),
              type: 'error'
            }
          : {
              message: intl.formatMessage({
                id: 'hubs.receive_from_business.submit.success'
              }),
              type: 'success'
            };

      notify(notification.message, notification.type);
    } catch (error) {
      notify(error.message);
    } finally {
      setSubmittingPackages(false);
      clearPackages(false);
      form.resetFields();
    }
  };

  return (
    <LoadingWrapper loading={loading}>
      <Form
        onFinish={handleSubmitForm}
        className="br-hubs-receive-from-business"
        form={form}
      >
        <PickupsTabActions
          checkTrackingNumberExistance={handleTrackingNumberChange}
          checkSealNumberExistance={handleSealNumberChange}
          packageSizes={allPricingPackageSize}
          buttonDisabled={loading}
          useWeights={useWeights}
        />

        <ReceiveFromBusinessContext.Provider
          value={{
            packages,
            removePackage,
            businessInfo,
            businessContactPersons,
            loading,
            allowSubmit,
            form
          }}
        >
          <PickupScanning />
        </ReceiveFromBusinessContext.Provider>

        <Form.Item name="updateType" initialValue="businessReceiving" hidden />
      </Form>
    </LoadingWrapper>
  );
};

export default ReceiveFromBusiness;
