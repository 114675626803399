import debounce from 'lodash/debounce';
import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import isNil from 'lodash/isNil';

import { scanDeliveries } from 'services/hubs';
import { DELIVERIES_SCAN_TYPES } from 'constants/hubs';

import { notify } from 'components/Notify/Notify';

const validateTrackingNumber = async ({
  intl,
  value,
  onSuccess,
  onComplete
}) => {
  const re = /^[0-9\b]+$/;
  const [trackingNumber, packageNumber] = value.split('/');
  const parsedPackageNumber = parseInt(packageNumber);

  if (trackingNumber.length > 13) {
    notify(
      'the tracking number should be 7 numbers or 6 numbers or 10 numbers '
    );

    return onComplete?.();
  }

  if (
    !isNil(packageNumber) &&
    (!re.test(parsedPackageNumber) || parsedPackageNumber === 0)
  ) {
    notify(
      intl.formatMessage({
        id: 'hubs.receive_new_pickup.invalid_multipackage'
      })
    );
    return onComplete?.();
  }

  if (!re.test(trackingNumber)) {
    notify('The tracking number you entered should be number only!');
    return onComplete?.();
  }

  try {
    const payload = {
      trackingNumbers: [trackingNumber],
      searchType: DELIVERIES_SCAN_TYPES.PICKUPS_RECEIVE_ORDER_SCAN
    };

    const {
      data: [item]
    } = await scanDeliveries(payload);

    if (item) {
      onSuccess?.(item, packageNumber);
    } else {
      notify('The tracking number you entered is not valid!', 'error', true);
    }
  } catch (error) {
    notify(error.message);
  }

  return onComplete?.();
};

const debouncedValidateTrackingNumber = debounce(validateTrackingNumber, 500);

const useTrackingNumberValidation = (
  packages,
  removeDeliveryFromPickups,
  cb,
  deps
) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const handleTrackingNumberChange = useCallback(
    async (
      value,
      selectedScanningType,
      resetInputs,
      scanType,
      packageWeight,
      packageSizeRef
    ) => {
      if (packages.length === 300) {
        notify(
          `You have reached the maximum number of receiving package per time.`
        );
        return;
      }

      const onSuccess = (item, packageNumber) => {
        cb?.({
          item,
          selectedScanningType,
          scanType,
          packageWeight,
          packageNumber
        });

        removeDeliveryFromPickups(item);

        if (packageSizeRef?.current) {
          packageSizeRef.current.focus({
            cursor: 'start'
          });
        }
      };

      setLoading(true);
      debouncedValidateTrackingNumber({
        intl,
        value: value.trim(),
        onSuccess,
        onComplete: () => {
          resetInputs?.(scanType === 'Selected');
          setLoading(false);
        }
      });
    },
    [
      packages.length,
      ...(deps ?? [])
    ]
  );

  return {
    handleTrackingNumberChange,
    loadingTrackingNumberValidation: loading
  };
}

export default useTrackingNumberValidation;