import { isInternationalShippingAvailable } from 'common/countries/countries-mapping';
import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import BusinessSwitch from '../BusinessSwitch';

const BusinessIntlShippingSwitch = ({
  businessData = {},
  submitBusinessUpdate
}) => {
  const { internationalShippingEnabled } = businessData;

  const disableSwitch = ![
    isInternationalShippingAvailable(),
    isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
      ACL_MATRIX.BUSINESS_ACTIVATE_INTERNATIONAL_SHIPPING
    ])
  ].every((condition) => condition);

  const switchBusinessIntlShipping = ({ value, ...options }) =>
    submitBusinessUpdate({ internationalShippingEnabled: value }, options);

  return (
    <BusinessSwitch
      disabled={disableSwitch}
      initialChecked={internationalShippingEnabled}
      onChange={switchBusinessIntlShipping}
    />
  );
};

export default BusinessIntlShippingSwitch;
