import aclMatrix, { EXPERIENCE, OPERATOR, SUPER_ADMIN } from 'common/aclMatrix';
import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as documentIcon } from 'assets/imgRevamp/documentIcon.svg';
import { ReactComponent as boxIcon } from 'assets/imgRevamp/boxIcon.svg';
import { ReactComponent as bulkyIcon } from 'assets/imgRevamp/bulkyIcon.svg';
import { ReactComponent as A4Icon } from 'assets/bosta-icons/A4.svg';
import { ReactComponent as A4CombinedIcon } from 'assets/bosta-icons/A4_Combined.svg';
import { ReactComponent as A5Icon } from 'assets/bosta-icons/A5.svg';
import { ReactComponent as A6Icon } from 'assets/bosta-icons/A6.svg';

export const DISABLE_COD_UPDATE_BUSINESSES = {
  BOSTA_MATERIALS_EG: 'HnRWYTmEgoBELeiPd',
  BOSTA_MATERIALS_SA: '2LbbTCDYIZC0xq8iPkh0M'
};

export const PROMISE = {
  MET: 'met',
  NOT_MET: 'not_met',
  IN_PROGRESS: 'in_progress',
  NOT_STARTED: 'not_started'
};

export const FINAL_STATS = [
  'Delivered',
  'Returned_to_business',
  'Terminated',
  'Returned to business',
  'Delivery Confirmed',
  'Lost',
  'Damaged'
];

export const MAP_DELIVERIES_CODES = {
  10: 'send',
  15: 'cash_collection',
  20: 'return_to_origin',
  25: 'customer_return_pickup',
  30: 'exchange',
  35: 'sign_and_return',
  200: 'fullfilment'
};

export const DELIVERY_STATES_CODES = {
  PICKUP_REQUESTED: 10,
  ROUTE_ASSIGNED: 20,
  PICKED_UP_FROM_BUSINESS: 21,
  OUT_FOR_PICKUP: 22,
  PICKED_UP_FROM_CONSIGNEE: 23,
  RECEIVED_AT_WAREHOUSE: 24,
  IN_TRANSIT_BETWEEN_HUBS: 30,
  OUT_FOR_COLLECTION: 40,
  OUT_FOR_DELIVERY: 41,
  DELIVERED: 45,
  RETURNED_TO_BUSINESS: 46,
  EXCEPTION: 47,
  TERMINATED: 48,
  UNCOVERED_CITY: 49,
  COLLECTION_FAILED: 50,
  LOST: 100,
  DAMAGED: 101,
  INVESTIGATION: 102,
  ARCHIVED: 104,
  ON_HOLD: 105
};

export const DELIVERY_CHILD_STATES = {
  READY_TO_DISPATCH: 5,
  AIR_TRANSIT: 12,
  WAITING_FOR_REJECTED_RETURN_ACTION: 106,
  REJECTED_RETURN: 107,
  REJECTED_RETURN_ARCHIVED: 108
};

export const INITIAL_STATES = [10, 11];

export const ORDER_SUCCESS_FINAL_STATES = [
  45, // Delivered
  46 // Returned to business
];

export const ORDER_FAILED_FINAL_STATES = [
  // 48, // Terminated
  100, // Lost
  101 // Damaged
  // 104 // Archived
];

export const ORDER_FINAL_STATES = [
  ...ORDER_SUCCESS_FINAL_STATES,
  ...ORDER_FAILED_FINAL_STATES
];

export const ALL_ORDER_FINAL_STATES = [
  ...ORDER_SUCCESS_FINAL_STATES,
  48, // Terminated
  100, // Lost
  101, // Damaged
  104 // Archived
];

export const DELIVERY_TYPES_CODES = {
  FORWARD: 10,
  SEND: 10,
  CASH_COLLECTION: 15,
  RETURN_TO_ORIGIN: 20,
  RTO: 20,
  CUSTOMER_RETURN_PICKUP: 25,
  EXCHANGE: 30,
  SIGN_AND_RETURN: 35,
  FXF_SEND: 200
};

export const STEP_CLASS = {
  Canceled: 'error',
  Rescheduled: 'exception',
  Exception: 'exception',
  Delivered: 'success',
  "Can't be delivered": 'error',
  Terminated: 'error'
};

export const orderTypes = [
  { label: 'Forward', value: 'SEND' },
  { label: 'Exchange', value: 'EXCHANGE' },
  {
    label: 'Cash Collection',
    value: 'CASH_COLLECTION'
  },
  {
    label: 'Customer Return Pickup (CRP)',
    value: 'CUSTOMER_RETURN_PICKUP'
  }
];

export const GUIDELINES_LINK =
  'https://bosta.freshdesk.com/en/support/solutions/articles/36000256737-what-are-the-packing-guidelines-';

export const TICKET_LINK = 'https://bosta.freshdesk.com/a/tickets/';

export const PACKAGE_TYPES = [
  { label: 'parcel', value: 'Parcel', icon: boxIcon },
  { label: 'document', value: 'Document', icon: documentIcon },
  { label: 'bulky', value: 'Bulky', icon: bulkyIcon }
];

export const EDIT_ORDER_PACKAGE_TYPES = [
  { label: 'Parcel', value: 'Parcel', icon: boxIcon },
  { label: 'Document', value: 'Document', icon: documentIcon },
  { label: 'Light Bulky', value: 'Light Bulky', icon: bulkyIcon },
  { label: 'Heavy Bulky', value: 'Heavy Bulky', icon: bulkyIcon }
];

export const FLYER_SIZE = { WHITE_BAG: 'White Bag' };

export const COD_OPTIONS = {
  COD: 'cod',
  REFUND: 'refund',
  WITH_OUT: 'without'
};

export const MASS_UPLOAD_ERRORS = {
  UNCOVERED_CITY: 'Uncovered City',
  CITY_NOT_FOUND: 'City Not Found',
  ZONE_NOT_FOUND: 'Zone Not Found',
  DISTRICT_NOT_FOUND: 'District Not Found',
  TRACKING_NUMBER_ALREADY_USED: 'This trackingNumber is already in use',
  WRONG_SKU: 'This BostaSKU doesnt relate to this business',
  INVALID_SKU_QUANTITY: 'This BostaSKU doesnt have more available quantity',
  UNCOVERED_ZONE: 'Uncovered drop off or pickup address'
};

export const ALLOWED_STATES_TO_EDIT_ORDER = [10];
export const PARCEL = 'Parcel';
export const PACKAGE_TYPES_BULKY = ['Light Bulky', 'Heavy Bulky'];

export const FINAL_STATES_CODES = [42, 45, 46, 48];

export const RECEIVED_AND_ON_HOLD_CODES = [4, 6, 8, 9, 12, 15];

export const RETURN_REJECTED_EXCEPTION_CODES = [24, 26, 27, 28, 29, 30];
export const REJECTED_RETURN_STATE_CODES = [47, 103, 105];
export const ONHOLD_STATE_CODES = [103, 105];
export const POSTPONED_ORDER = [3, 21];
export const CUSTOMER_REFUSED_RECEIVING = [8];
export const CANCELED_BY_SHIPPER = [6];
export const DATA_MODIFICATION = [13, 14];
export const CANCELED_CUSTOMER_WANTS_TO_OPEN_PACKAGE = [4];

export const RETURNED_STATE_CODE = 46;
export const EXCEPTION_STATE_CODE = 47;
export const UNCOVERED_STATE_CODE = 49;
export const OFD_STATE_CODE = 41;

export const SOHO = 'SOHO';

export const MANUAL_ASSIGN_HUB_ACCESS = [
  SUPER_ADMIN,
  OPERATOR,
  EXPERIENCE,
  ...aclMatrix.THREE_PL
];

export const ASSIGN_TO_HUB_UPDATE_TYPE = 'assignToHub';

export const ORDER_TYPES_STRING = {
  SEND: fmt({ id: 'shipments.order_types.send' }),
  CASH_COLLECTION: fmt({ id: 'shipments.order_types.cash_collection' }),
  EXCHANGE: fmt({ id: 'shipments.order_types.exchange' }),
  CUSTOMER_RETURN_PICKUP: fmt({
    id: 'shipments.order_types.customer_return_pickup'
  }),
  SIGN_AND_RETURN: fmt({ id: 'shipments.order_types.sign_and_return' }),
  FXF_SEND: fmt({ id: 'monitor_hub_operations.common.fulfillment' }),
  EXCHANGE_FORWARD: fmt({
    id: 'monitor_hub_operations.common.exchange_forward'
  }),
  EXCHANGE_RETURN: fmt({ id: 'monitor_hub_operations.common.exchange_return' }),
  CRP_FORWARD: fmt({ id: 'monitor_hub_operations.common.crp_forward' }),
  CRP_RETURN: fmt({ id: 'monitor_hub_operations.common.crp_return' }),
  RTO: fmt({
    id: 'hubs.packages_debrief.exceptions_tab.states.rto'
  })
};

export const TICKET_REQUESETER = {
  Business: fmt({ id: 'deliveries.delivery_details.delivery_logs.business' }),
  Customer: fmt({ id: 'deliveries.delivery_details.delivery_logs.consignee' }),
  Admin: fmt({ id: 'deliveries.delivery_details.delivery_logs.admin' })
};

export const LOG_DATE_FORMAT = 'ddd, DD MMM YYYY  h:mm a';

export const REJECTED_RETURN_SUBCATEGORY = [
  {
    label: fmt({ id: 'support_tickets.ticket_subcategory.delayed_return' }),
    value: 'Delayed Return'
  },
  {
    label: fmt({ id: 'support_tickets.ticket_subcategory.wrong_update' }),
    value: 'Wrong update'
  },
  {
    label: fmt({ id: 'support_tickets.ticket_subcategory.damaged_order' }),
    value: 'Damaged order'
  },
  {
    label: fmt({ id: 'support_tickets.ticket_subcategory.mixed_order' }),
    value: 'Mixed order'
  },
  {
    label: fmt({ id: 'support_tickets.ticket_subcategory.missing_item' }),
    value: 'Missing Item'
  },
  {
    label: fmt({ id: 'support_tickets.ticket_subcategory.empty_package' }),
    value: 'Empty Package'
  },
  {
    label: fmt({ id: 'support_tickets.ticket_subcategory.opened_shipment' }),
    value: 'Opened shipment'
  },
  {
    label: fmt({ id: 'support_tickets.ticket_subcategory.fake_update' }),
    value: 'Fake Update'
  }
];

export const UNCLEAR_ADDRESS_SUBCATEGORY = [
  {
    label: fmt({
      id: 'support_tickets.unclear_address_subcategory.change_address_business'
    }),
    value: 'Change Address (Business)'
  },
  {
    label: fmt({
      id: 'support_tickets.unclear_address_subcategory.change_address_consignee'
    }),
    value: 'Change Address (Consignee)'
  },
  {
    label: fmt({
      id: 'support_tickets.unclear_address_subcategory.change_phone_business'
    }),
    value: 'Change Phone number (Business)'
  },
  {
    label: fmt({
      id: 'support_tickets.unclear_address_subcategory.change_phone_consingee'
    }),
    value: 'Change Phone number (Consignee)'
  },
  {
    label: fmt({
      id: 'support_tickets.unclear_address_subcategory.change_phone_address_business'
    }),
    value: 'Phone number and address (Business)'
  },
  {
    label: fmt({
      id: 'support_tickets.unclear_address_subcategory.change_phone_address_consignee'
    }),
    value: 'Phone number and address (Consignee)'
  },
  {
    label: fmt({
      id: 'support_tickets.unclear_address_subcategory.add_another_phone_business'
    }),
    value: 'Add another number (Business)'
  },
  {
    label: fmt({
      id: 'support_tickets.unclear_address_subcategory.add_another_phone_consignee'
    }),
    value: 'Add another number (Consignee)'
  }
];

export const TECH_ISSUES_SUBCATEGORY = [
  {
    label: fmt({
      id: 'support_tickets.tech_issues_subcategory.pickup_requets'
    }),
    value: 'Pickup Requests'
  },
  {
    label: fmt({
      id: 'support_tickets.tech_issues_subcategory.integration'
    }),
    value: 'Integration'
  },
  {
    label: fmt({
      id: 'support_tickets.tech_issues_subcategory.printing_awb'
    }),
    value: 'Printing AWB'
  },
  {
    label: fmt({
      id: 'support_tickets.tech_issues_subcategory.reset_password'
    }),
    value: 'Reset Password'
  },
  {
    label: fmt({
      id: 'support_tickets.tech_issues_subcategory.change_address'
    }),
    value: 'Change Address'
  },
  {
    label: fmt({
      id: 'support_tickets.tech_issues_subcategory.change_phone_number'
    }),
    value: 'Change Phone Number'
  },
  {
    label: fmt({
      id: 'support_tickets.tech_issues_subcategory.material_request'
    }),
    value: 'Material Request'
  },
  {
    label: fmt({
      id: 'support_tickets.tech_issues_subcategory.fulfillment'
    }),
    value: 'Fulfillment'
  },
  {
    label: fmt({
      id: 'support_tickets.tech_issues_subcategory.mass_upload'
    }),
    value: 'Mass Upload'
  },
  {
    label: fmt({
      id: 'support_tickets.tech_issues_subcategory.bosta_box'
    }),
    value: 'Bosta Box'
  },
  {
    label: fmt({
      id: 'support_tickets.tech_issues_subcategory.bosta_app'
    }),
    value: 'Bosta App'
  }
];

const AWB_SUBCATEGORY = [
  {
    label: fmt({
      id: 'support_tickets.awb_subcategory.terminated_awb'
    }),
    value: 'Terminated AWB'
  },
  {
    label: fmt({
      id: 'support_tickets.awb_subcategory.duplicated_awb'
    }),
    value: 'Duplicated AWB'
  }
];

export const PUDO_STATES = {
  RECEIVED_AT_WAREHOUSE: 'received at warehouse',
  READY_TO_PICK: 'ready to pick',
  DELIVERD: 'delivered',
  CLOSED: 'closed'
};

export const PUDO_HUB_TYPE = 'THIRD_PARTY_HUB';

export const PUDO_PROVIDER = 'PUDO_PROVIDER';
export const MARKETPLACE = 'MARKETPLACE';

export const FIRST_LINE_MIN_LENGTH = 5;

export const CONSIGNEE_TRACKING_URL =
  window.env?.CONSIGNEE_TRACKING_URL ||
  'https://bosta.co/tracking-shipments?shipment-number=';

export const DELIVERIES_PAYMENT_METHODS = {
  EG: [
    { value: 'COD' },
    { value: 'Axis Wallet' },
    { value: 'Flash' },
    { value: 'Valu' }
  ],
  SA: [{ value: 'COD' }, { value: 'CCOD' }, { value: 'Bank Transfer' }]
};

export const BAD_ADDRESS = 'Bad Address';

export const MULTI_PACKAGES_RANGE = {
  MIN: 2,
  MAX: 10
};

export const ALLOWED_TYPES_FOR_MULTI_PACKAGE = [
  DELIVERY_TYPES_CODES.SEND,
  DELIVERY_TYPES_CODES.EXCHANGE,
  DELIVERY_TYPES_CODES.CUSTOMER_RETURN_PICKUP
];

export const REFUSAL_OPTIONS = {
  PACKAGE_WAS_OPEN: fmt({
    id: 'deliveries.delivery_details.delivery_logs.refusal_reason.package_was_open'
  }),
  MISSING_PRODUCT: fmt({
    id: 'deliveries.delivery_details.delivery_logs.refusal_reason.missing_product'
  }),
  BROKEN_SHIPMENT: fmt({
    id: 'deliveries.delivery_details.delivery_logs.refusal_reason.broken_shipment'
  }),
  NOT_CONSIGNEE_SHIPMENT: fmt({
    id: 'deliveries.delivery_details.delivery_logs.refusal_reason.not_consignee_shipment'
  })
};

export const MAX_ITEM_VALUE = 50000;
export const MAX_ITEM_COD_VALUE = 30000;
export const MIN_NEGATIVE_COD_VALUE = -15000;

export const FILE_EXTENTIONS = {
  PDF: 'pdf'
};

export const PRINTING_OPTIONS = [
  {
    label: fmt(
      { id: 'shipments.actions.print_options.a4_single_page' },
      {
        span: (children) => <span className="text-wraning">{children}</span>
      }
    ),
    value: 'AWB_A4',
    icon: <A4Icon />
  },
  {
    label: fmt(
      { id: 'shipments.actions.print_options.a4_double_page' },
      {
        span: (children) => <span className="text-wraning">{children}</span>
      }
    ),
    value: 'AWB_A5',
    isCombinedAWB: true,
    icon: <A4CombinedIcon />
  },
  {
    label: fmt({ id: 'shipments.actions.print_options.a5_single_page' }),
    value: 'AWB_A5',
    icon: <A5Icon />
  },
  {
    label: fmt({ id: 'shipments.actions.print_options.a6_single_page' }),
    value: 'AWB_A6',
    icon: <A6Icon />
  }
];

export const UPDATE_DELIVERY_REMOVED_KEYS = [
  'itemsCount',
  'description',
  'packageType',
  'weight'
];
