import { fmt } from 'IntlWrapper/IntlWrapper';

export const LOST_ORDERS_COLUMNS = (renderActions) => [
  {
    title: fmt({
      id: 'ops_control.lost_orders.table_columns.order_id'
    }),
    dataIndex: 'order_id'
  },
  {
    title: fmt({
      id: 'ops_control.lost_orders.table_columns.current_hub'
    }),
    dataIndex: 'current_hub'
  },
  {
    title: fmt({
      id: 'ops_control.lost_orders.table_columns.flagged_by'
    }),
    dataIndex: 'flagged_by'
  },
  {
    title: fmt({
      id: 'ops_control.lost_orders.table_columns.sla'
    }),
    dataIndex: 'sla'
  },
  {
    width: '230px',

    render: (_, order) => renderActions(order)
  }
];

export const LOST_ORDERS_QUICK_FILTERS = [
  {
    label: fmt({ id: 'ops_control.lost_orders.filters.pending_on_hub' }),
    value: 0
  }
];

export const LOST_ORDER_SLA_MAX_LEVEL = 3;

export const FOUND_IT = 'FOUND_IT';

export const STAR_LIABLE = 'STAR_LIABLE';

export const FOUND_PROOF_IMAGES = 'proofOfFoundDeliveryImages';

export const LIABLE_PROOF_IMAGES = 'proofOfLiabilityImages';
