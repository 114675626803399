import { useIntl } from 'react-intl';

import { ReactComponent as CheckIcon } from 'assets/bosta-icons/Check.svg';

import './ScannedPackagesHeader.less';

const ScannedPackagesHeader = ({ scannedPackagesCount }) => {
  const intl = useIntl();
  return (
    <>
      <div className="scanned-packages__header-icon display-flex align-center justify-center">
        <CheckIcon />
      </div>
      <span>
        {intl.formatMessage(
          {
            id: 'hubs.receive_from_business.pickup_scanning.scanned_packages.title'
          },
          {
            scannedPackagesCount
          }
        )}
      </span>
    </>
  );
};

export default ScannedPackagesHeader;
