import { Radio } from 'antd';

import classNames from 'classnames';
import './SelectableCard.less';

const SelectableCard = ({
  isClicked,
  isFake,
  data,
  value,
  title,
  percentage
}) => {
  return (
    <Radio
      disabled={data === 0}
      value={value}
      className={classNames('br-selectable-card__container', {
        'br-selected-card__active-card': isClicked,
        'br-selected-card__fake': isFake
      })}
    >
      <div className="br-selectable-card__header"></div>
      <span className="br-selectable-card__title body-medium">{title}</span>
      <div className="br-selectable-card__values-wrapper">
        <span className="br-selectable-card__main-value display-md">
          {data}
        </span>
        {(percentage || percentage === 0) && (
          <span className="br-selectable-card__sub-value body">
            {percentage}%
          </span>
        )}
      </div>
    </Radio>
  );
};

export default SelectableCard;
