import { useIntl } from 'react-intl';
import { Modal } from 'antd';

import BRButton from 'components/BRButton/BRButton';

const FulfillmentSwitchModal = ({
  businessData = {},
  close,
  onSuccess,
  ...props
}) => {
  const intl = useIntl();

  const { isBostaFulfillment } = businessData;

  const handleConfirm = () => {
    onSuccess();
    close();
  };

  return (
    <Modal
      {...props}
      title={null}
      footer={null}
      onCancel={close}
      className="br-request-pickup-modal"
    >
      <div className="br-request-pickup-modal__title">
        {isBostaFulfillment
          ? 'Deactivate Fulfillment Service'
          : 'Activate Fulfillment Service'}
      </div>

      <div className="br-request-pickup-modal__body">
        {intl.formatMessage(
          { id: 'fulfillment.fulfillment_activation' },
          {
            value: `${isBostaFulfillment ? ' deactivate' : ' activate'}`
          }
        )}
      </div>

      <div className="br-request-pickup-modal__footer">
        <BRButton
          onClick={close}
          label={intl.formatMessage({
            id: 'common.cancel'
          })}
        />

        <BRButton
          type="primary"
          onClick={handleConfirm}
          label={intl.formatMessage({
            id: 'common.confirm'
          })}
        />
      </div>
    </Modal>
  );
};

export default FulfillmentSwitchModal;
