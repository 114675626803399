import { isNil } from 'lodash';
import classnames from 'classnames';
import { Typography } from 'antd';

import './InfoCard.less';

const InfoCard = ({ title, value }) => {
  return (
    <div
      className={classnames('br-pickup-summary-info-card', {
        muted: isNil(value)
      })}
    >
      <Typography.Text className="br-pickup-summary-info-card__value">
        {value ?? '-'}
      </Typography.Text>
      <Typography.Text className="br-pickup-summary-info-card__title">
        {title}
      </Typography.Text>
    </div>
  );
};

export default InfoCard;
