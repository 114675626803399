import { injectIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Modal, Input, Select, Form, Radio } from 'antd';

import { getSignedUrl } from 'services/deliveries';
import { formatImages } from 'utils/upload';
import { formatOptions } from 'utils';
import {
  FOUND_IT,
  FOUND_PROOF_IMAGES,
  LIABLE_PROOF_IMAGES,
  STAR_LIABLE
} from 'constants/lost-or-damaged';
import { imageUpload } from 'services/upload';
import { ORDER_STATES } from 'constants/sorting-facility';
import aclMatrix from 'common/aclMatrix';
import { getCurrentUserHubInfo } from 'utils/hubs';
import { nonEmptyRule } from 'utils/forms';
import { getHubStars } from 'services/hubs';
import { isUserAuthorized } from 'utils/helpers';
import { ACL_MATRIX } from 'common/permissions';
import { COUNTRIES_CODE_NAME } from 'common/countries/constants/countries';

import UploadAttachment from 'components/SupportTickets/components/UploadAttachment/UploadAttachment';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import './LostOrDamagedModal.less';

const LostOrDamagedModal = ({
  intl,
  close,
  title,
  allHubs,
  delivery,
  modalType,
  confirmEndPoint,
  headerTitle,
  ...rest
}) => {
  const { TextArea } = Input;

  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [liability, setLiability] = useState(null);
  const [starsList, setStarsList] = useState([]);

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const currentUserHubId = getCurrentUserHubInfo()?._id;
  const isHubView = () => {
    return isUserAuthorized(
      aclMatrix.LOST_OR_DAMAGED_ORDERS_MODAL,
      [ACL_MATRIX.DElIVERY_MARK_STAR_AS_LIABLE_FOR_LOST_ORDER],
      {
        countries: [COUNTRIES_CODE_NAME.EG]
      }
    );
  };

  const fetchHubStars = async (hubId) => {
    setLoading(true);
    try {
      const { message } = await getHubStars(hubId);
      setStarsList(message);
    } catch (error) {
      setStarsList([]);
      notify(error.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (modalType === STAR_LIABLE && delivery?.liableHubId) {
      fetchHubStars(delivery?.liableHubId);
    } else if (currentUserHubId) {
      fetchHubStars(currentUserHubId);
    }
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const imagesUrls = values.proof
        ? await getImagesUrl(
            values?.proof,
            modalType === FOUND_IT ? FOUND_PROOF_IMAGES : LIABLE_PROOF_IMAGES
          )
        : [];
      const payload = {
        deliveryId: delivery?._id,
        starId: values?.star,
        ...(!values?.star && { warehouseId: values?.hub }),
        type: values?.type,
        comment: values.comment,
        ...(imagesUrls.length && { proofOfLiabilityImages: imagesUrls })
      };

      const lostActionsPayload = {
        comment: values.comment,
        ...(imagesUrls.length &&
          (modalType === FOUND_IT
            ? { proofOfFoundDeliveryImages: imagesUrls }
            : { proofOfLiabilityImages: imagesUrls })),
        starId: values?.star
      };
      const res = await confirmEndPoint(
        modalType ? lostActionsPayload : payload,
        delivery?._id
      );
      notify(res.message, 'success');
    } catch (error) {
      notify(error.message);
    } finally {
      setLoading(false);
      close();
    }
  };

  const getImagesUrl = async (values, type) => {
    let imagesUrls = [];
    try {
      const formattedImages = formatImages(values);
      for (let i = 0; i < formattedImages?.length; i++) {
        const payload = {
          type
        };
        const { data } = await getSignedUrl(payload);
        await imageUpload(data?.signedUrl, formattedImages[i]);
        imagesUrls.push(data?.signedUrl);
      }
    } catch (error) {
      notify(error.message);
    }
    return imagesUrls;
  };

  return (
    <Modal
      {...rest}
      title={
        <span className="display-xs">
          {modalType
            ? headerTitle
            : intl.formatMessage({ id: 'deliveries.lost_modal.modal_header' })}
        </span>
      }
      footer={
        <div className="br-lost-or-damaged-modal__footer">
          <BRButton
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={close}
          />
          <BRButton
            disabled={loading}
            type="primary"
            htmlType="submit"
            form="lost or damaged form"
            label={intl.formatMessage({ id: 'common.confirm' })}
          />
        </div>
      }
      centered
      onCancel={close}
    >
      <Form
        onFinish={onFinish}
        id="lost or damaged form"
        className="br-lost-modal__form-wrapper"
        initialValues={currentUserHubId && { hub: currentUserHubId }}
      >
        {!modalType && (
          <>
            <Form.Item
              className="br-lost-modal__input-row"
              rules={[{ required: true }]}
              label={
                <span className="body-medium">
                  {intl.formatMessage({
                    id: 'deliveries.lost_modal.type_header'
                  })}
                </span>
              }
              name="type"
            >
              <Radio.Group
                onChange={setType}
                value={type}
                className="br-lost-modal__radio-group__wrapper"
              >
                <Radio value={ORDER_STATES.LOST}>
                  {intl.formatMessage({
                    id: 'deliveries.lost_modal.lost'
                  })}
                </Radio>
                <Radio value={ORDER_STATES.DAMAGED}>
                  {intl.formatMessage({
                    id: 'deliveries.lost_modal.damaged'
                  })}
                </Radio>
              </Radio.Group>
            </Form.Item>
            {isHubView() && (
              <Form.Item
                rules={[{ required: true }]}
                label={
                  <span className="body-medium">
                    {intl.formatMessage({
                      id: 'deliveries.lost_modal.liability_header'
                    })}
                  </span>
                }
                name="liability"
                className="br-lost-modal__input-row"
              >
                <Radio.Group
                  onChange={setLiability}
                  value={liability}
                  className="br-lost-modal__radio-group__wrapper"
                >
                  <Radio value={false}>
                    {intl.formatMessage({
                      id: 'deliveries.lost_modal.hub'
                    })}
                  </Radio>
                  <Radio value={true}>
                    {intl.formatMessage({
                      id: 'deliveries.lost_modal.star'
                    })}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            )}
            <Form.Item
              rules={[{ required: true }]}
              label={
                <span className="body-medium">
                  {intl.formatMessage({
                    id: 'deliveries.lost_modal.hub_header'
                  })}
                </span>
              }
              name="hub"
              className="br-lost-modal__select-wrapper br-lost-modal__input-row"
            >
              <Select
                placeholder={intl.formatMessage({ id: 'common.select' })}
                allowClear
                showSearch
                showArrow
                onChange={(value) => {
                  if (liability?.target?.value) fetchHubStars(value);
                }}
                getPopupContainer={(trigger) => trigger.parentElement}
                filterOption={(input, option) =>
                  option.label
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.value
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={allHubs && formatOptions('_id', 'name', allHubs)}
              />
            </Form.Item>
          </>
        )}
        {(liability?.target?.value || modalType) && (
          <>
            {(modalType === STAR_LIABLE || liability?.target?.value) && (
              <Form.Item
                rules={[{ required: true }]}
                label={
                  <span className="body-medium">
                    {intl.formatMessage({
                      id: 'deliveries.lost_modal.star_header'
                    })}
                  </span>
                }
                name="star"
                className="br-lost-modal__select-wrapper br-lost-modal__input-row"
              >
                <Select
                  placeholder={intl.formatMessage({ id: 'common.select' })}
                  allowClear
                  showSearch
                  showArrow
                  getPopupContainer={(trigger) => trigger.parentElement}
                  filterOption={(input, option) =>
                    option.label
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.value
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={
                    formatOptions &&
                    formatOptions(
                      '_id',
                      'profile.firstName,profile.lastName',
                      starsList
                    )
                  }
                  disabled={!starsList?.length}
                />
              </Form.Item>
            )}
            <Form.Item
              label={
                <span className="body-medium">
                  {intl.formatMessage({
                    id: 'deliveries.lost_modal.comment'
                  })}
                </span>
              }
              name="comment"
              className=" br-lost-modal__input-row"
            >
              <TextArea
                autoSize={{ minRows: 4, maxRows: 4 }}
                placeholder={intl.formatMessage({
                  id: 'deliveries.lost_modal.comment_place_holder'
                })}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="body-medium">
                  {intl.formatMessage({
                    id: 'deliveries.lost_modal.proof'
                  })}
                </span>
              }
              name="proof"
              className=" br-lost-modal__input-row"
              rules={[
                { required: true },
                nonEmptyRule({
                  message: intl.formatMessage({
                    id: 'deliveries.lost_modal.select_one_image'
                  })
                })
              ]}
            >
              <UploadAttachment
                maxCount={3}
                accept=".jpg, .png, .svg, .GIF"
                customSubtitle={intl.formatMessage({
                  id: 'deliveries.lost_modal.proof_subtitle'
                })}
                hideUploadButtonAfterMaxCount
                disableEncoding
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default injectIntl(LostOrDamagedModal);
