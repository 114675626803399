import { BusinessInfoCells } from 'constants/business-details';

import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import DownloadDocumentButton from './DownloadDocumentButton/DownloadDocumentButton';

const BusinessInfo = ({ businessInfo }) => {
  const { businessId, canDownloadLegalDocuments, canDownloadPaymentDocuments } =
    businessInfo;

  const businessInfoCells = BusinessInfoCells({
    renderDownloadLegalButton: () => (
      <DownloadDocumentButton
        businessId={businessId}
        canBeDownloaded={canDownloadLegalDocuments}
      />
    ),
    renderDownloadPaymentButton: () => (
      <DownloadDocumentButton
        businessId={businessId}
        documentType="payment"
        canBeDownloaded={canDownloadPaymentDocuments}
      />
    )
  });

  return (
    <BRInformationTable
      cells={businessInfoCells}
      dataSource={businessInfo}
      totalCol={3}
    />
  );
};

export default BusinessInfo;
