import dayjs from 'dayjs';
import { BASE } from '../apis';
import { notify } from 'components/Notify/Notify';
import { openModal } from 'utils/modal';
import { downloadAsXlsx } from '../utils';
import http from 'utils/http';
import { getAWBLanguage } from 'common/countries/countries-mapping';

import FailedDeliveriesModal from 'components/FailedDeliveriesModal/FailedDeliveriesModal';

const userCountry = JSON.parse(localStorage.getItem('userInfo'))?.country?._id;

/**
 * get all deliveries by specific query
 * @param data
 * @returns {Promise<{deliveries: null}|*>}
 */
export const getAllDeliveries = async ({ data }) => {
  try {
    const res = await BASE.post(`/deliveries/search`, data);
    return res.data;
  } catch (e) {
    const { response } = e;
    notify(
      Array.isArray(response.data)
        ? response.data.map((el) => el.message).join(', ')
        : response.data.message
    );
    return { deliveries: null };
  }
};

/**
 * get single delivery
 * @param data
 * @returns {Promise<null|*>}
 */
export const getDelivery = async ({ data }) => {
  try {
    const res = await BASE.get(`/admin/deliveries?ids=${data}`);
    return res.data[0];
  } catch (e) {
    return null;
  }
};

/**
 * get single delivery
 * @returns {Promise<null|*>}
 * @param searchCriteria
 */
export const getDeliveryBySearchCriteria = async (searchCriteria) => {
  try {
    const queryStr = Object.entries(searchCriteria).reduce(
      (acc, value) =>
        value[1] ? `${acc}${value[0]}=${encodeURIComponent(value[1])}&` : acc,
      ''
    );
    const { data } = await BASE.get(`/admin/deliveries?${queryStr}`);
    return data;
  } catch (e) {
    return null;
  }
};

/**
 * get all hubs
 * @returns {Promise<null|*>}
 */
export const getAllHubs = async () => {
  try {
    const { data } = await BASE.get(`/hubs`);
    return data;
  } catch (e) {
    return null;
  }
};

/**
 * get all cities
 * @returns {Promise<*>}
 */
export const getAllCities = async () => {
  let url = `/cities`;
  if (userCountry) {
    url = `/cities?countryId=${userCountry}`;
  }
  const { data } = await BASE.get(url);
  return data;
};

/**
 * create new delivery
 * @param payload
 * @returns {Promise<*>}
 */
export const createDelivery = async (payload) => {
  try {
    const { data } = await BASE.post(`/admin/deliveries`, payload);
    return data;
  } catch (e) {
    const {
      response: { data }
    } = e;
    notify(
      Array.isArray(data)
        ? data.map((el) => el.message).join(',')
        : data.message
    );
    return null;
  }
};

export const createDeliveryByCsv = async (payload) => {
  try {
    const { data } = await BASE.post(`/deliveries/CreateByCsv`, payload);
    return data;
  } catch (e) {
    const {
      response: { data }
    } = e;
    notify(
      Array.isArray(data)
        ? data.map((el) => el.message).join(',')
        : data.message
    );
    return null;
  }
};

/**
 * get zones by city
 * @param id
 * @returns {Promise<*>}
 */
export const getZonesByCity = async ({ id }) => {
  const { data } = await BASE.get(`/cities/${id}/zones`);
  return data;
};

/**
 * get ready-print bill generated for specific delivery
 * @param id
 * @returns {Promise<null|*>}
 */
export const printAirWayBill = async (id) => {
  try {
    const { data } = await BASE.get(
      `/admin/deliveries/printawb?ids=${id}&lang=${getAWBLanguage()}`
    );
    return data;
  } catch (e) {
    const {
      response: { data: message }
    } = e;
    notify(message.message);
    return null;
  }
};

/**
 * cancel delivery with a given reason
 * @param id
 * @param reason
 * @returns {Promise<null|*>}
 */
export const cancelDeliveryWithReason = async ({ deliveries, reason }) => {
  try {
    const payload = {
      updateType: 'cancelMany',
      deliveries,
      reason
    };
    const { data } = await BASE.put(`/admin/deliveries/updateMany`, payload);
    notify('Delivery cancelled successfully!', 'success');
    return data;
  } catch (e) {
    const {
      response: { data: message }
    } = e;
    notify(message.message);
    return null;
  }
};

/**
 * receive many packages
 * @param deliveryIds
 * @returns {Promise<null|*>}
 */
export const receivePackagesAtHub = async (dataPayload) => {
  try {
    const payload = {
      updateType: 'receiveMany',
      ...dataPayload
    };
    const { data } = await BASE.put(`/admin/deliveries/updateMany`, payload);
    return data;
  } catch (e) {
    const {
      response: { data }
    } = e;
    notify(
      Array.isArray(data)
        ? data.map((el) => el.message).join(',')
        : data.message
    );
    return null;
  }
};

/**
 * add comment to specified delivery
 * @param id
 * @param comment
 * @returns {Promise<null|*>}
 */
export const addDeliveryComment = async ({ id, comment }) => {
  try {
    const payload = {
      updateType: 'addComment',
      comment
    };
    const { data } = await BASE.put(`/admin/deliveries/${id}`, payload);
    notify('Delivery comment added successfully!', 'success');
    return data;
  } catch (e) {
    notify('Error while adding comment to delivery!');
    return null;
  }
};

/**
 * re-request cancelled delivery
 * @param id
 * @returns {Promise<null|*>}
 */
export const rerequestDelivery = async ({ id }) => {
  try {
    const { data } = await BASE.post(`/admin/deliveries/${id}/rerequest`, {});
    notify('Delivery Re-requested successfully!', 'success');
    return data;
  } catch (e) {
    const {
      response: { data: message }
    } = e;
    notify(message.message);
    return null;
  }
};

/**
 * export filtered deliveries as csv file
 * @param data
 * @returns {Promise<null|*>}
 */
export const exportCSV = async (data) => {
  try {
    const config = {
      responseType: 'arraybuffer',
      headers: {
        Accept:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json'
      }
    };
    const res = await BASE.post(`/deliveries/exportxlsx?`, data, config);
    if (res.headers['content-type'].includes('application/json')) {
      notify('Deliveries exported to your mail successfully.', 'success');
    } else {
      notify(
        'Deliveries loaded successfully, exporting in progress.',
        'success'
      );
      return res.data;
    }
  } catch (e) {
    notify(e.message);
    return null;
  }
};

/**
 * return all stars with query
 * @returns {Promise<void>}
 */
export const getAllStars = async () => {
  try {
    const res = await BASE.get(`/users/stars/all`);
    return res.data.message;
  } catch (e) {
    const {
      response: { data: message }
    } = e;
    notify(message.message);
    return null;
  }
};

/**
 * return all active routes
 * @returns {Promise<void>}
 */
export const getAllActiveRoutes = async () => {
  try {
    const res = await BASE.get(`/routes?active=true&field=name&pageId=-1`);
    return res.data;
  } catch (e) {
    const {
      response: { data: message }
    } = e;
    notify(message.message);
    return null;
  }
};

/**
 * return deliveries to sender (business)
 * @param ids
 * @returns {Promise<null|*>}
 */
export const returnDeliveriesToSender = async (ids) => {
  const deliveries = ids?.map((_id) => {
    return { _id };
  });
  try {
    const payload = {
      updateType: 'return',
      deliveries
    };
    const { data } = await BASE.put(`/admin/deliveries/updateMany`, payload);
    notify('Returned Successfully!', 'success');
    return data;
  } catch (e) {
    const {
      response: { data: message }
    } = e;
    notify(message.message);
    return null;
  }
};

/**
 * add deliveries to route
 * @param routeId
 * @param ids
 * @returns {Promise<null|*>}
 */
export const addDeliveryToRoute = async ({ routeId, ids }) => {
  try {
    const payload = {
      routeId,
      deliveryIds: ids
    };
    const { data } = await BASE.put(`/admin/deliveries/addtoroute`, payload);
    notify('Added to route successfully!', 'success');
    return data;
  } catch (e) {
    const {
      response: { data: message }
    } = e;
    notify(message.message);
    return null;
  }
};

/**
 * terminate delivery
 * @param ids
 * @returns {Promise<null|*>}
 */
export const terminateDeliverys = async ({ ids }) => {
  const deliveries = ids?.map((_id) => {
    return { _id };
  });
  try {
    const payload = {
      updateType: 'terminate',
      deliveries
    };
    const { data } = await BASE.put(`/admin/deliveries/updateMany`, payload);
    notify('Delivery terminated successfully!', 'success');
    return data;
  } catch (e) {
    notify(
      e?.response?.data?.message ||
        'Error you can only terminate delivery with status Pickup requested'
    );

    return null;
  }
};

/**
 * reset deliveries
 * @param ids
 * @returns {Promise<null|*>}
 */
export const resetDeliveredDeliveries = async (ids) => {
  const deliveries = ids?.map((_id) => {
    return { _id };
  });

  try {
    const payload = {
      updateType: 'reset',
      deliveries
    };
    const { data } = await BASE.put(`/admin/deliveries/updateMany`, payload);
    if (data?.failedDeliveries?.length) {
      openModal(FailedDeliveriesModal, {
        failedDeliveries: data.failedDeliveries
      });
    } else {
      notify('Deliveries reseted successfully!', 'success');
    }

    return data;
  } catch (e) {
    notify(e?.response?.data?.message || 'Error while reset these deliveries!');
    return null;
  }
};

/**
 * add deliveries to route
 * @param id
 * @returns {Promise<null|*>}
 */
export const resetDeliveredDelivery = async ({ id }) => {
  try {
    const payload = {
      updateType: 'resetDeliveredDelivery'
    };
    const { data } = await BASE.put(`/admin/deliveries/${id}`, payload);
    notify('Delivery rest successfully!', 'success');
    return data;
  } catch (e) {
    notify(e?.response?.data?.message || 'Error while reset these deliveries!');
    return null;
  }
};
/**
 * add deliveries to route
 * @param id
 * @returns {Promise<null|*>}
 */
export const restartDeliveredDelivery = async ({ id }) => {
  try {
    const payload = {
      updateType: 'restart'
    };
    const { data } = await BASE.put(`/admin/deliveries/${id}`, payload);
    notify('Delivery restarted successfully!', 'success');
    return data;
  } catch (e) {
    notify(
      e?.response?.data?.message || 'Error while restarting these delivery!'
    );

    return null;
  }
};

/**
 * add deliveries to route
 * @param id
 * @returns {Promise<null|*>}
 */
export const redispatchRTODelivery = async ({ id }) => {
  try {
    const payload = {
      updateType: 'redispatchRTO'
    };
    const { data } = await BASE.put(`/admin/deliveries/${id}`, payload);
    notify('Delivery re-dispatched successfully!', 'success');
    return data;
  } catch (e) {
    notify(
      'You can only Re-dispatch the RTO orders with status Received at warehouse!'
    );
    return null;
  }
};

/**
 * update specific delivery
 * @param id
 * @param payload
 * @returns {Promise<null|*>}
 */
export const updateDelivery = async ({ id, payload }) => {
  try {
    const update = {
      updateType: 'update',
      updatedParams: {
        ...payload
      }
    };
    const { data } = await BASE.put(`/admin/deliveries/${id}`, update);
    notify('Delivery updated successfully!', 'success');
    return data;
  } catch (e) {
    const message = Array.isArray(e.response.data)
      ? e.response.data.map((el) => el.message).join(', ')
      : e.response.data.message;
    notify(message);
    return null;
  }
};

/**
 * get star daily debriefing report
 * @param starId
 * @returns {Promise<null|*>}
 */
export const debriefDeliveries = async ({ starId }) => {
  try {
    const { data } = await BASE.post(`/admin/deliveries/${starId}/debrief`, {});
    return data;
  } catch (e) {
    notify('Error while getting star debriefing data!');
    return null;
  }
};

/**
 * end star deliveries debriefing
 * @param starId
 * @returns {Promise<null|*>}
 */
export const endDeliveryDebrief = async ({ routeIds }) => {
  const config = {
    responseType: 'arraybuffer',
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  };
  const { data } = await BASE.put(`/routes/end-debrief`, { routeIds }, config);
  downloadAsXlsx(
    data,
    `Debriefed_${dayjs(new Date()).format('DD-MM-YYYY')}.xlsx`
  );
  return data;
};

/**
 * mark bulk of tracking numbers as delivered
 * @param payload
 * @returns {Promise<null|any>}
 */
export const markDeliveriesAsDelivered = async (payload) => {
  try {
    const { data } = await BASE.post(`/admin/deliveries/autodeliver`, payload);
    return data;
  } catch (e) {
    // notify(e.response.data.message);
    return null;
  }
};

/**
 * mark bulk of tracking numbers as delivered
 * @param payload
 * @returns {Promise<null|any>}
 */
export const markAsLostOrDamaged = async (payload) => {
  const res = await http.put(`/admin/deliveries/markAsLostOrDamage`, payload);
  return res;
};

/**
 * get star daily debriefing report
 * @param starId
 * @returns {Promise<null|*>}
 */
export const confirmReceivingDeliveries = async ({ deliveryIds, routeIds }) => {
  try {
    const { data } = await BASE.put(`/admin/deliveries/confirmDeliveries`, {
      deliveryIds,
      routeIds
    });
    return data;
  } catch (e) {
    notify('Error while getting star debriefing data!');
    return null;
  }
};
