import { useIntl } from 'react-intl';

import { isInternationalShippingAvailable } from 'common/countries/countries-mapping';
import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { MAX_NUMBER_OF_UPLOADABLE_FILES } from 'constants/Businesses';

import BusinessSwitch from '../BusinessSwitch';
import ActivateInternationalShippingModal from 'components/Businesses/ActivateInternationalShippingModal/ActivateInternationalShippingModal';
import NewConfirmationModal from 'components/BRModals/NewConfirmationModal/NewConfirmationModal';

const BusinessIntlShippingActivationSwitch = ({
  businessData = {},
  submitBusinessUpdate
}) => {
  const intl = useIntl();
  const {
    _id: businessId,
    internationalShippingEnabled,
    internationalShippingActivated
  } = businessData;

  const disableSwitch = ![
    isInternationalShippingAvailable(),
    internationalShippingEnabled,
    isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
      ACL_MATRIX.BUSINESS_ACTIVATE_INTERNATIONAL_SHIPPING
    ])
  ].every((condition) => condition);

  const activationModal = {
    modal: ActivateInternationalShippingModal,
    modalProps: ({ value, ...options }) => ({
      businessId,
      onConfirm: (numberOfUploadedFiles) => {
        const businessLegalInfo = {
          isInternationalContractUploaded: true
        };

        for (let i = 2; i <= MAX_NUMBER_OF_UPLOADABLE_FILES; i++) {
          businessLegalInfo[`isInternationalActivationDoc${i}Uploaded`] =
            i <= numberOfUploadedFiles;
        }

        submitBusinessUpdate(
          {
            businessLegalInfo,
            internationalShippingActivated: value
          },
          options
        );
      }
    })
  };

  const newConfirmationModal = {
    modal: NewConfirmationModal,
    modalProps: ({ value, ...options }) => ({
      title: intl.formatMessage({
        id: 'international_shipping.modal_deactivate_title'
      }),
      subtitle: intl.formatMessage(
        { id: 'international_shipping.international_shipping_activation' },
        {
          value: intl.formatMessage({
            id: 'international_shipping.modal_deactivate'
          })
        }
      ),
      confirmButtonLabel: intl.formatMessage({
        id: 'international_shipping.deactivate'
      }),
      confirmButtonType: 'destructive-primary',
      onConfirm: () => {
        submitBusinessUpdate(
          {
            internationalShippingActivated: value
          },
          options
        );
      }
    })
  };

  const confirmationModal = !internationalShippingActivated
    ? activationModal
    : newConfirmationModal;

  return (
    <BusinessSwitch
      disabled={disableSwitch}
      initialChecked={internationalShippingActivated}
      confirmationModal={confirmationModal}
    />
  );
};

export default BusinessIntlShippingActivationSwitch;
