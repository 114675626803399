import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { isUserAuthorized } from 'utils/helpers';
import { SllrCells } from 'constants/business-details';
import { getSllrData } from 'services/business';

import BRButton from 'components/BRButton/BRButton';
import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import { notify } from 'components/Notify/Notify';

const SllrInfo = ({ sllrInfo }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [extraInfo, setExtraInfo] = useState(undefined);

  const { businessId } = sllrInfo;
  const fullSllrInfo = {
    ...sllrInfo,
    ...extraInfo
  };

  const handleLoadMoreInfoOnClick = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getSllrData(businessId);
      setExtraInfo(data);
    } catch (e) {
      notify(e.message);
      setExtraInfo(undefined);
    } finally {
      setLoading(false);
    }
  }, [businessId]);

  const sllrInfoCells = useMemo(() => {
    const showLoadMoreInfoButton =
      isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
        ACL_MATRIX.BUSINESS_VIEW_SLLR_ANALYTICS
      ]) && !extraInfo;

    const renderActionBtn = showLoadMoreInfoButton && (
      <BRButton
        label={intl.formatMessage({
          id: 'businesses.details.sllrInfo.load_more_info'
        })}
        type="outline"
        loading={loading}
        disabled={loading}
        onClick={handleLoadMoreInfoOnClick}
      />
    );

    return SllrCells({
      extraInfo,
      renderActionBtn
    });
  }, [extraInfo, handleLoadMoreInfoOnClick, intl, loading]);

  return (
    <BRInformationTable
      cells={sllrInfoCells}
      dataSource={fullSllrInfo}
      totalCol={4}
    />
  );
};

export default SllrInfo;
