import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';

import { ReactComponent as HelpIcon } from 'assets/bosta-icons/help.svg';

const LocationInfo = ({ location = {} }) => {
  const intl = useIntl();
  const { city, area, building, apartment, floor, street, landmark } = location;

  const locationTooltipTitle = [
    {
      label: intl.formatMessage({
        id: 'businesses.details.businessLocations.building'
      }),
      value: building
    },
    {
      label: intl.formatMessage({
        id: 'businesses.details.businessLocations.floor'
      }),
      value: floor
    },
    {
      label: intl.formatMessage({
        id: 'businesses.details.businessLocations.apartment'
      }),
      value: apartment
    },
    {
      label: intl.formatMessage({
        id: 'businesses.details.businessLocations.landmark'
      }),
      value: landmark
    },
    {
      label: intl.formatMessage({
        id: 'businesses.details.businessLocations.street'
      }),
      value: street
    }
  ];

  const filteredLocationTooltipTitle = locationTooltipTitle.filter(
    ({ value }) => !!value
  );

  return (
    <div className="br-business-details__default-location-cell">
      <div>
        <span>{city}</span>
        {!!filteredLocationTooltipTitle.length && (
          <Tooltip
            title={
              <>
                {filteredLocationTooltipTitle.map(({ label, value }) => (
                  <div>
                    {label} <span>{value}</span>
                  </div>
                ))}
              </>
            }
          >
            <HelpIcon />
          </Tooltip>
        )}
      </div>
      <p>{area}</p>
    </div>
  );
};

export default LocationInfo;
