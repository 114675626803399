import { Card } from 'antd';

import BusinessInfo from './components/BusinessInfo/BusinessInfo';
import PickupScanningHeader from './components/PickupScanningHeader/PickupScanningHeader';
import ScannedPackages from './components/ScannedPackages/ScannedPackages';

import './PickupScanning.less';

const PickupScanning = () => {
  return (
    <Card
      title={<PickupScanningHeader />}
      bordered={false}
      className="br-hubs-receive-from-business__pickup-scanning"
    >
      <BusinessInfo />
      <ScannedPackages />
    </Card>
  );
};

export default PickupScanning;
