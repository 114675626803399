import dayjs from 'dayjs';

import {
  getAttemptsTypes,
  isMaxNumOfAttempts
} from 'common/countries/countries-mapping';
import { DAY_OF_WEEK } from './helper';
import { fmt } from 'IntlWrapper/IntlWrapper';

export const TAB_KEYS = {
  TASKS: 'tasks',
  EXCEPTIONS: 'exceptions',
  PICKUPS: 'pickups',
  SOHO_PICKUPS: 'sohoPickups',
  DELIVERED: 'delivered',
  RTO: 'rto',
  COD: 'cod',
  CCOD: 'ccod'
};

const RESCHEDULED_TOMORROW = `${fmt({
  id: 'hubs.packages_debrief.exceptions_tab.states.rescheduled'
})}: ${fmt({ id: 'hubs.packages_debrief.exceptions_tab.states.tomorrow' })}`;

const RETURN_REJECTED = fmt({
  id: 'hubs.packages_debrief.exceptions_tab.states.return_rejected'
});

export const REJECTED_RETURN = 'Return Rejected';
const ON_HOLD = fmt({
  id: 'hubs.packages_debrief.exceptions_tab.states.on_hold'
});
const SCHEDULED_TO = fmt({
  id: 'hubs.packages_debrief.exceptions_tab.states.scheduled_to'
});

export const RETURN_EXCEPTIONS_STATE_MAPPING = (delivery) => {
  const states = {
    20: RESCHEDULED_TOMORROW, // Business changed the address
    21: `${SCHEDULED_TO} [${DAY_OF_WEEK[dayjs(delivery?.scheduledAt).day()]}]`, // Business asked to postpone to another date
    22: RESCHEDULED_TOMORROW, // Data modification
    25: RESCHEDULED_TOMORROW, // Business not in the address
    26: RETURN_REJECTED, // Damaged Orders
    27: RETURN_REJECTED, // Empty order
    28: RETURN_REJECTED, // Missing Items
    29: RETURN_REJECTED, // Mixed Order
    30: RETURN_REJECTED // An opened - not allowed to be opened order
  };

  const didExceedReturnAttempts = isMaxNumOfAttempts(
    delivery?.attemptsCount - delivery?.forwardAttemptsCount
  ).RETURN;

  if (didExceedReturnAttempts) {
    return ON_HOLD;
  } else {
    return states[delivery?.state?.lastExceptionCode];
  }
};

export const EXCEPTIONS_STATE_ACTIONS_MAPPING = {
  RESCHEDULED_TO_TOMORROW: {
    title: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.rescheduled'
    }),
    subtitle: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.tomorrow'
    }),
    bgColor: 'bg-green',
    fullText: `${fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.rescheduled'
    })} ${fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.tomorrow'
    })}`
  },
  RESCHEDULED_TO_DATE: (date) => ({
    title: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.rescheduled'
    }),
    subtitle: dayjs(date).format('ddd, DD MMM'),
    bgColor: 'bg-gray',
    fullText: `${fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.rescheduled'
    })} (${dayjs(date).format('ddd')})`
  }),
  ASSIGN_TO_ON_HOLD: {
    title: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.assign_to'
    }),
    subtitle: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.on_hold'
    }),
    bgColor: 'bg-yellow',
    fullText: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.on_hold'
    })
  },
  ASSIGN_TO_RTO: {
    title: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.assign_to'
    }),
    subtitle: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.rto'
    }),
    bgColor: 'bg-red',
    fullText: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.rto'
    })
  },
  ASSIGN_TO_RETURN_ISSUES: {
    title: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.assign_to'
    }),
    subtitle: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.return_issues'
    }),
    bgColor: 'bg-pale-red',
    fullText: fmt({
      id: 'hubs.packages_debrief.exceptions_tab.states.return_rejected'
    })
  }
};

export const DELIVERY_ACTIONS_MAPPING = (delivery) => {
  const actions = {
    ADDRESS_UPDATED: {
      title: fmt({ id: 'action_center.address_updated' }),
      subtitle: fmt({
        id: 'action_center.order_details.action_notes.print_awb'
      }),
      bgColor: 'bg-blue'
    },
    UPDATED_DELIVERY_DETAILS: {
      title: fmt({ id: 'action_center.order_details_updated' }),
      subtitle: fmt({
        id: 'action_center.order_details.action_notes.print_awb'
      }),
      bgColor: 'bg-blue'
    },
    RESCHEDULED: {
      title: fmt({ id: 'orders.order_new_statues.rescheduled' }),
      subtitle: dayjs(delivery.scheduledAt).tz().format('dddd, DD MMM YYYY'),
      bgColor: 'bg-green'
    },
    RESCHEDULED_TOMORROW: {
      title: fmt({ id: 'orders.order_new_statues.rescheduled' }),
      subtitle: fmt({
        id: `hubs.packages_debrief.tomorrow`
      }),
      bgColor: 'bg-gray'
    },
    CANCEL_ORDER: {
      title: fmt({
        id: 'action_center.customer_request_to_cancel'
      }),
      bgColor: 'bg-red'
    }
  };

  return actions[delivery.actionType];
};
