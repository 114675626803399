import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';
import dayjs from 'dayjs';

import { OpsControl } from 'contexts/ops-control.context';
import {
  assignWrongZoneBulkOrders,
  exportWrongZoneOrders,
  getCurrentActiveDate,
  getWrongZoneOrders
} from 'services/ops-control';
import { getHubs } from 'services/hubs';
import {
  TRACKING_NUMBERS,
  WRONG_ZONE_COLUMNS,
  WRONG_ZONE_PAGE_OPTIONS,
  WRONG_ZONE_QUICK_FILTERS
} from 'constants/ops-wrong-zone';
import { LAST_MILE_HUB, SORTING_STATION_HUB } from 'constants/hubs';
import { CURRENT_DATE_PARAMS } from 'constants/sorting-facility';
import { DATE_FORMAT } from 'constants/date-picker';
import { cleanEmptyString, getPaginationCount } from 'utils/helpers';
import { openModal } from 'utils/modal';

import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';
import PendingTransitFilter from 'components/OpsControl/PendingTransit/PendingTransitFilter/PendingTransitFilter';
import WrongZoneTableHeader from './components/WrongZoneTableHeader/WrongZoneTableHeader';
import WrongZoneActions from './components/WrongZoneActions/WrongZoneActions';
import BRButton from 'components/BRButton/BRButton';
import ChangeHubModal from './components/WrongZoneActions/ChangeHubButton/ChangeHubModal/ChangeHubModal';

import './WrongZone.less';

const WrongZone = () => {
  const intl = useIntl();
  const { allHubs } = useContext(OpsControl);

  const [lastMileHubs, setLastMileHubs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchOptions, setSearchOptions] = useState({
    trackingNumbers: undefined,
    sourceHubId: undefined,
    date: undefined,
    takenAction: undefined
  });
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const refreshTableRef = useRef(() => {});

  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const { result } = await getHubs({
          types: [LAST_MILE_HUB, SORTING_STATION_HUB]
        });
        setLastMileHubs(result);
      } catch (error) {
        notify(error.message);
      }
    };
    fetchHubs();
  }, []);

  const handleSelectedRows = (rowKeys, records = []) => {
    setSelectedRowsIds(rowKeys);
  };

  const handleChangeHubBluky = () => {
    openModal(ChangeHubModal, {
      selectedRowsIds,
      allHubs: lastMileHubs,
      title: intl.formatMessage({
        id: 'ops_control.wrong_zone.modals.change_hub.title'
      }),
      dispatchOrderAction: dispatchBulkyActions
    });
  };

  const dispatchBulkyActions = async ({
    targetHubId,
    successMessage = ''
  } = {}) => {
    setLoading(true);
    try {
      const payload = {
        hubId: targetHubId,
        wrongZoneRecordIds: selectedRowsIds
      };
      await assignWrongZoneBulkOrders(payload);
      refreshTableRef.current?.();
      notify(successMessage, 'success');
    } catch (e) {
      notify(e.message);
    } finally {
      setLoading(false);
    }
  };

  const renderBulkHubActions = () => {
    return (
      <BRButton
        className="button-md br-wrong-zone__bulk-actions"
        label={intl.formatMessage({ id: 'ops_control.wrong_zone.bulk_hub' })}
        onClick={handleChangeHubBluky}
        disabled={!selectedRowsIds.length}
      />
    );
  };

  const showNoChangeAction =
    searchOptions.takenAction === WRONG_ZONE_QUICK_FILTERS[0].value;

  const updateSearchOption = useCallback((option) => {
    return (value) => {
      let updatedSearchOptions;

      setSearchOptions((curr) => {
        updatedSearchOptions = {
          ...curr,
          [option]:
            option === TRACKING_NUMBERS
              ? value?.split(/[\s,]+/).map(Number)
              : value
        };

        return updatedSearchOptions;
      });

      return updatedSearchOptions;
    };
  }, []);

  const handleHubChange = (hubValue) => {
    const updatedSearchOptions = updateSearchOption('sourceHubId')(hubValue);
    refreshTableRef.current({
      filterValues: {
        ...updatedSearchOptions,
        sourceHubId: hubValue
      }
    });
  };

  const handleDateChange = (dateValue) => {
    const formattedDate = dayjs(dateValue).format(DATE_FORMAT);

    const updatedSearchOptions = updateSearchOption('date')(formattedDate);

    refreshTableRef.current({
      filterValues: {
        ...updatedSearchOptions,
        date: formattedDate
      }
    });
  };

  const listFunction = useCallback(
    async ({
      page,
      limit,
      filterValues,
      searchInputText,
      quickFiltersValue = WRONG_ZONE_QUICK_FILTERS[0].value
    }) => {
      const updatedSearchOptions = {
        ...searchOptions,
        ...filterValues,
        trackingNumbers: searchInputText?.split(/[\s,]+/).map(Number),
        takenAction: quickFiltersValue
      };

      const result = {
        list: [],
        total: 0
      };

      if (!updatedSearchOptions.date) {
        return result;
      }

      setLoading(true);
      try {
        const { data } = await getWrongZoneOrders(
          cleanEmptyString({
            page,
            limit,
            ...updatedSearchOptions
          })
        );

        updateSearchOption('trackingNumbers')(searchInputText);
        updateSearchOption('takenAction')(quickFiltersValue);

        result.list = data || [];
        result.total = getPaginationCount({
          page,
          result: result.list,
          pageSize: limit
        });
      } catch (e) {
        notify(e.message);
      } finally {
        setLoading(false);
      }
      return result;
    },
    [searchOptions, updateSearchOption]
  );

  const handleExportListFile = useCallback(async () => {
    setLoading(true);
    try {
      const { success, message } = await exportWrongZoneOrders(
        cleanEmptyString(searchOptions)
      );
      notify(message, success ? 'success' : 'error');
    } catch (e) {
      notify(e.message);
    } finally {
      setLoading(false);
    }
  }, [searchOptions]);

  const initializeSelectedDate = useCallback(
    async (cancelToken = undefined) => {
      const payload = { pageName: CURRENT_DATE_PARAMS.WRONG_ZONE };
      setLoading(true);
      try {
        const {
          data: { currentDate }
        } = await getCurrentActiveDate(payload, { cancelToken });
        updateSearchOption('date')(currentDate);

        refreshTableRef.current({
          filterValues: {
            date: currentDate
          }
        });
      } catch (e) {
        if (e.status !== -1) {
          notify(e.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [updateSearchOption]
  );

  const shareMethods = (refreshMethod) => {
    refreshTableRef.current = refreshMethod;
  };

  const renderActions = (record) => (
    <WrongZoneActions
      lastMileHubs={lastMileHubs}
      order={record}
      refreshData={refreshTableRef.current}
      showNoChangeAction={showNoChangeAction}
    />
  );

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    initializeSelectedDate(cancelTokenSource.token);
    return () => cancelTokenSource.cancel();
  }, [initializeSelectedDate]);

  return (
    <div className="br-ops-control-wrong-zone">
      <PendingTransitFilter
        loading={loading}
        intl={intl}
        allHubs={allHubs}
        currentHub={searchOptions.sourceHubId}
        setCurrentHub={updateSearchOption('sourceHubId')}
        selectedDate={searchOptions.date}
        setSelectedDate={updateSearchOption('date')}
        handleHubChange={handleHubChange}
        changeDateValue={handleDateChange}
        hubSelectionPlaceholder={intl.formatMessage({
          id: 'ops_control.wrong_zone.filters.hubs'
        })}
      />
      <BRTable
        className="hide-pagination"
        rowKey="id"
        isLoading={loading}
        title={<WrongZoneTableHeader searchOptions={searchOptions} />}
        searchPlaceholder={intl.formatMessage({
          id: 'deliveries.search_filters_placeholder.tracking_number'
        })}
        onSearchInputChange={updateSearchOption('trackingNumbers')}
        listFunction={listFunction}
        exportListFileFunction={handleExportListFile}
        columns={WRONG_ZONE_COLUMNS({ renderActions, showNoChangeAction })}
        pageSizeOptions={WRONG_ZONE_PAGE_OPTIONS}
        quickFilters={WRONG_ZONE_QUICK_FILTERS}
        shareMethods={shareMethods}
        hideFilterButton
        showSearch
        allowSelection
        tableActions={renderBulkHubActions}
        handleSelectedRows={handleSelectedRows}
      />
    </div>
  );
};

export default WrongZone;
