import { useCallback, useRef, useState } from 'react';

import {
  BUSINESS_TEAM_MEMBERS_COLUMNS,
  TeamMembersCells
} from 'constants/business-details';
import { getBusinessTeamMembers } from 'services/business';

import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import BRTable from 'components/BRTable/BRTable';
import { notify } from 'components/Notify/Notify';

import './TeamMembers.less';

const TeamMembers = ({ businessId, refreshData }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const refreshDataRef = useRef(() => {});

  const fetchTeamMembersData = useCallback(
    async ({ page, limit }) => {
      const result = {
        list: [],
        total: 0
      };
      try {
        const {
          data: { list, total }
        } = await getBusinessTeamMembers(businessId, { page, limit });
        setTeamMembers(list);
        result.list = list;
        result.total = total;
      } catch (e) {
        notify(e.message);
      }

      return result;
    },
    [businessId]
  );

  const shareMethods = (refresh) => (refreshDataRef.current = refresh);

  const refreshBusinessAndTeamMembers = () => {
    refreshDataRef.current();
    refreshData();
  };

  const renderTeamMembersTable = () => (
    <BRTable
      listFunction={fetchTeamMembersData}
      shareMethods={shareMethods}
      columns={BUSINESS_TEAM_MEMBERS_COLUMNS(
        teamMembers,
        refreshBusinessAndTeamMembers
      )}
      showFilter={false}
      pageLimit={5}
      pageSizeOptions={[5, 10, 20]}
    />
  );

  return (
    <BRInformationTable
      className="br-business-details__team-members-table"
      cells={TeamMembersCells(renderTeamMembersTable)}
      totalCol={1}
    />
  );
};

export default TeamMembers;
