import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form } from 'antd';

import { formatImages } from 'utils/upload';
import { getProofUploadLink } from 'services/shipments';
import { imageUpload } from 'services/upload';
import { updateDelivery } from 'services/deliveries';
import { INITIAL_STATES } from 'constants/shipments';

import ItemValue from './ItemValue';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as CloseIcon } from 'assets/bosta-icons/Close.svg';

const UpdateItemValueModal = ({
  intl,
  close,
  initialValues,
  setIsLoading,
  onSuccess,
  state,
  ...props
}) => {
  const { id, businessId } = initialValues || {};
  const updateFormRef = useRef();

  const handleProofOfValue = async (image) => {
    if (image) {
      const formattedImage = formatImages(image)[0];
      try {
        const { data } = await getProofUploadLink(businessId);

        await imageUpload(data.url, formattedImage);
        return data?.attachmentKey;
      } catch (error) {
        notify(error.message);
        return null;
      }
    }
  };

  const handleFinish = async (values) => {
    setIsLoading(true);
    try {
      const proofKey = await handleProofOfValue(values?.valueProof);
      const payload = {
        updateType: 'update',
        updatedParams: {
          goodsInfo: {
            amount: values?.itemValue || initialValues?.amount,
            proofOfCostAttachmentKey: proofKey
          }
        }
      };
      const data = await updateDelivery(id, payload);
      notify(data.message, 'success');
      close();
      onSuccess();
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  return (
    <Modal
      open
      className="br-item-value__modal"
      title={
        <div className="br-item-value__modal__header">
          <p>
            {intl.formatMessage({
              id: 'shipments.new_order.terms_and_conditions'
            })}
          </p>
          <CloseIcon onClick={close} />
        </div>
      }
      footer={null}
      onCancel={close}
      {...props}
    >
      <div className="br-item-value__update-modal-content">
        <Form ref={updateFormRef} onFinish={handleFinish}>
          <ItemValue
            initialValues={initialValues}
            formRef={updateFormRef}
            isItemValueDisabled={!INITIAL_STATES.includes(state)}
          />
          <BRButton htmlType="submit" label="submit" />
        </Form>
      </div>
    </Modal>
  );
};

export default injectIntl(UpdateItemValueModal);
