import { useCallback, useContext, useEffect, useState } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { useIntl } from 'react-intl';
import { Form } from 'antd';

import { getPolygonCentroid, getZoomLevelForPolygon } from 'utils/bosta-coding';
import { GOOGLE_MAP_API_KEY } from 'constants/dynamic-routing';
import { HubsContext } from 'contexts/hub.context';
import { mapDefaultLocation } from 'common/countries/countries-mapping';

import BostaCodingPolygon from 'components/BostaCoding/components/BostaCodingPolygon/BostaCodingPolygon';

const containerStyle = {
  width: '100%',
  height: '75vh',
  borderRadius: '12px'
};

const DistrictsMappingMap = ({
  hubStarsActionsPolygons,
  existingHubStreetCodesPolygons,
  setSelectedPolygonIndex,
  selectedPolygonIndex,
  form,
  polygonRef
}) => {
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState(mapDefaultLocation);
  const [hubPolygon, setHubPolygon] = useState([]);

  const intl = useIntl();

  const { allHubs } = useContext(HubsContext);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY
  });

  const selectedHubId = Form.useWatch('selectedHubId', form);

  useEffect(() => {
    setSelectedPolygonIndex(null);

    if (selectedHubId) {
      const hub = allHubs.find((hub) => hub._id === selectedHubId);

      if (hub?.geometry) {
        const { coordinates } = hub.geometry;
        const polygon = coordinates[0].map((coordinate) => ({
          lat: coordinate[1],
          lng: coordinate[0]
        }));

        setHubPolygon(polygon);
        setCenter(getPolygonCentroid(polygon));
        setZoom(getZoomLevelForPolygon(polygon));
      }
    }
  }, [selectedHubId]);

  const renderStarActionsPolygon = useCallback(() => {
    if (!hubStarsActionsPolygons?.length) {
      return null;
    }

    return hubStarsActionsPolygons.map((polygon, index) => {
      const isEditable = index === selectedPolygonIndex;

      return (
        <BostaCodingPolygon
          geometryPath={polygon}
          polygonRef={isEditable ? polygonRef : null}
          onClick={() => setSelectedPolygonIndex(index)}
          options={{
            fillColor: isEditable ? '#0098A5' : '#FFA500',
            fillOpacity: 0.25,
            strokeColor: isEditable ? '#0098A5' : '#FFA500',
            strokeOpacity: 0.8,
            strokeWeight: 3,
            zIndex: 10,
            suppressUndo: true,
            draggable: isEditable,
            editable: isEditable
          }}
        />
      );
    });
  }, [hubStarsActionsPolygons, selectedPolygonIndex]);

  if (!isLoaded) {
    return intl.formatMessage({ id: 'common.map_failed_to_load' });
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={zoom}
      center={center}
      options={{
        streetViewControl: false,
        clickableIcons: false,
        mapTypeControl: false
      }}
    >
      <BostaCodingPolygon
        geometryPath={hubPolygon}
        options={{
          fillColor: '#800000',
          fillOpacity: 0.2,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 5,
          zIndex: 1
        }}
        editable={false}
      />
      {renderStarActionsPolygon()}
      {!!existingHubStreetCodesPolygons?.length &&
        existingHubStreetCodesPolygons.map((polygon) => (
          <BostaCodingPolygon
            geometryPath={polygon}
            options={{
              fillColor: '#000',
              fillOpacity: 0.8,
              strokeColor: '#28A745',
              strokeOpacity: 0.8,
              strokeWeight: 1,
              zIndex: 9,
              editable: false
            }}
          />
        ))}
    </GoogleMap>
  );
};

export default DistrictsMappingMap;
