import React from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Table, Tabs, Input, Button, Tooltip, Select } from 'antd';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import classnames from 'classnames';

import { mediaHook } from 'customHooks';
import diff from 'diffObj';
import { cleanEmptyString } from 'utils/helpers';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BREmptyState from 'components/BREmptyState/BREmptyState';
import TableFilters from './Components/TableFilters';
import MobileCards from './Components/MobileCards/MobileCards';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as Search } from 'assets/bosta-icons/Search.svg';
import { ReactComponent as Filters } from 'assets/bosta-icons/Filters.svg';
import { ReactComponent as Download } from 'assets/bosta-icons/Download.svg';
import { ReactComponent as ClearCircle } from 'assets/bosta-icons/Circle-clear.svg';
import { ReactComponent as Close } from 'assets/bosta-icons/Close.svg';
import { ReactComponent as Right } from 'assets/bosta-icons/Right.svg';
import { ReactComponent as Left } from 'assets/bosta-icons/Left.svg';

import './BRTable.less';

const PAGE_SIZE = 50;
class BRTable extends React.PureComponent {
  state = {
    listingData: [],
    pageSize: this.props.pageLimit || PAGE_SIZE,
    isLoading: this.props.isLoading || false,
    count: 0,
    pageNumber: 1,
    selectedRowKeys: this.props.selectedRowKeys || [],
    fixedTop: false,
    activeTabKey: !!this.props.quickFilters?.length
      ? this.props.initialQuickFilter
        ? this.props.initialQuickFilter
        : 0
      : null,
    filterValues: this.props.initialFilterValue || {},
    isUserUsedSearch: false,
    prevPayLoad: {},
    searchInputState: {
      isSearchInputActive: false,
      isSearchInputActiveWithoutShadow: false,
      showClearCircle: false
    },
    isFiltersClicked: false,
    filterLabels: [],
    selectedCards: [],
    isSelectButtonClicked: false
  };

  filtersRef = React.createRef('');

  getData = async ({
    pageNumber = 1,
    pageLimit,
    isInitialLoad,
    refresh = false,
    ...props
  }) => {
    const { listFunction, handleSelectedRows, allowUserUsedSearchOnErrors } =
      this.props;
    const { pageSize, filterValues, prevPayLoad } = this.state;
    const payload = {
      page: pageNumber,
      limit: pageLimit || pageSize,
      ...(filterValues && {
        ...filterValues
      }),
      ...props
    };
    const isNewPayload = diff(
      cleanEmptyString(payload),
      cleanEmptyString(prevPayLoad)
    );
    if (!Object.keys(isNewPayload).length && !refresh) {
      return;
    }
    if (handleSelectedRows) {
      handleSelectedRows([]);
    }
    try {
      this.setState({ isLoading: true });
      const fetchData = listFunction;
      const data = await fetchData(payload);
      if (data) {
        const { list, total } = data;
        this.setState({
          listingData: list,
          count: total,
          selectedRowKeys:
            isInitialLoad && this.props.selectAllInitially
              ? list.map((_, index) => index)
              : [],
          pageNumber,
          isLoading: false,
          isUserUsedSearch: !!filterValues?.searchInputText,
          prevPayLoad: payload
        });
      } else {
        this.setState({
          listingData: [],
          count: 0,
          pageNumber,
          isLoading: false,
          prevPayLoad: payload,
          isUserUsedSearch: allowUserUsedSearchOnErrors
            ? !!filterValues?.searchInputText
            : false
        });
      }
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  refresh({ pageNumber: pageNumberProp, clearSearch, filterValues } = {}) {
    const { pageNumber } = this.state;

    clearSearch && this.handleClearSearch();

    this.getData({
      pageNumber: pageNumberProp || pageNumber || 1,
      isInitialLoad: true,
      refresh: true,
      ...(filterValues && {
        filterValues
      })
    });
  }

  componentDidMount() {
    if (this.props.shareMethods) {
      this.props.shareMethods(this.refresh.bind(this));
    }
    if (this.props.listFunction) {
      this.getData({ pageNumber: 1, isInitialLoad: true });
    } else if (this.props.listingData) {
      this.setState({ listingData: this.props.listingData });
    }
  }

  componentDidUpdate(prevProps, _) {
    const {
      currentQuickFilterIndex,
      initialFilterLabels,
      initialFilterValue,
      isLoading,
      listingData,
      selectedRowKeys,
      resetSelection,
      handleSelectedRows = () => {}
    } = this.props;
    if (prevProps.currentQuickFilterIndex !== currentQuickFilterIndex) {
      this.onChangeQuickFilterTab(currentQuickFilterIndex);
    }
    if (prevProps.initialFilterLabels !== initialFilterLabels) {
      this.setState({ filterLabels: initialFilterLabels });
    }
    if (prevProps.initialFilterValue !== initialFilterValue) {
      this.setState({ isFiltersClicked: true });
      this.setState({ filterValues: initialFilterValue });
    }
    if (prevProps.isLoading !== isLoading) {
      this.setState({ isLoading: isLoading });
    }
    if (prevProps.resetSelection !== resetSelection) {
      this.setState({
        selectedRowKeys: [],
        selectedCards: [],
        isSelectButtonClicked: false
      });
      handleSelectedRows([]);
    }
    if (prevProps.listingData !== listingData) {
      this.setState({ listingData });
    }
    if (selectedRowKeys) {
      this.setState({ selectedRowKeys });
    }
  }

  handleTableChange = (pagination, filters, sorter, extra) => {
    if (pagination.pageSize) {
      this.setState({ pageSize: pagination.pageSize });
    }
    if (pagination.current) {
      this.setState({ pageNumber: pagination.current });
    }
    if (this.props.listFunction) {
      this.getData({
        pageNumber: pagination.current,
        pageLimit: pagination.pageSize,
        sortField: Array.isArray(sorter)
          ? sorter
          : sorter.order && (sorter.field || sorter.column?.field),
        sortOrder: sorter.order
      });
    }
  };

  cell = ({ children }) => {
    return <td className="br-table-row__cell body">{children[1]}</td>;
  };

  headerRow = ({ children }) => {
    const { selectedRowKeys } = this.state;
    const { title, isScrolling } = this.props;
    return selectedRowKeys.length && isScrolling ? (
      <div className="display-flex align-center">
        <tr> {children[0]}</tr>
        <tr> {title}</tr>
      </div>
    ) : (
      <tr>{children.map((child) => child)}</tr>
    );
  };

  components = {
    body: {
      cell: this.state.listingData.length && this.cell
    },
    header: {
      row: this.headerRow
    }
  };

  onChangeQuickFilterTab = (index) => {
    const { keepDataBetweenTabs = false, quickFilters } = this.props;

    const { filterValues } = this.state;
    this.props.handleQuickFilterChange &&
      this.props.handleQuickFilterChange(index);
    const defaultState = {
      activeTabKey: index,
      selectedRowKeys: [],
      selectedCards: [],
      isSelectButtonClicked: false
    };
    this.setState(
      keepDataBetweenTabs
        ? {
            filterValues: {
              ...filterValues,
              quickFiltersValue: quickFilters[index]?.value
            },
            ...defaultState
          }
        : {
            filterValues: { quickFiltersValue: quickFilters[index]?.value },
            selectedCards: [],
            isSelectButtonClicked: false,
            isFiltersClicked: false,
            filterLabels: [],
            ...defaultState
          },
      () =>
        this.getData({
          pageNumber: 1,
          isInitialLoad: true
        })
    );
  };

  handleClearSearch = () => {
    const { keepDataBetweenTabs = false, doAfterClearSearch = () => {} } =
      this.props;
    const { activeTabKey } = this.state;
    doAfterClearSearch();
    this.setState(
      {
        activeTabKey: keepDataBetweenTabs ? activeTabKey : 0,
        filterValues: {},
        filterLabels: {},
        searchInputState: {
          isSearchInputActive: false,
          isSearchInputActiveWithoutShadow: false,
          showClearCircle: false
        },
        isFiltersClicked: false
      },
      () => {
        this.getData({
          pageNumber: 1,
          refresh: true
        });
      }
    );
  };

  scrollToFilters = () => {
    this.filtersRef.current.scrollIntoView();
  };

  handleExportFile = async () => {
    const { selectedRowKeys, filterValues } = this.state;
    const { exportListFileFunction } = this.props;
    const payload = {
      ...(selectedRowKeys.length && { selectedRowKeys })
    };
    this.setState({ isLoading: true });
    try {
      await exportListFileFunction(payload, filterValues);
    } catch (error) {
      notify(error.message);
    }
    this.setState({ isLoading: false });
  };

  onSearch = (searchInputText) => {
    const { quickFilters, keepDataBetweenTabs, selectedSearchOption } =
      this.props;
    const { filterValues, searchInputState, activeTabKey } = this.state;
    this.setState(
      {
        searchInputState: {
          ...searchInputState,
          isSearchInputActive: false,
          isSearchInputActiveWithoutShadow: true
        },
        activeTabKey: keepDataBetweenTabs
          ? activeTabKey
          : quickFilters?.length && quickFilters[0].value,
        filterValues: {
          ...filterValues,
          searchInputText,
          selectedSearchOption
          // quickFiltersValue: null
        }
      },
      () => {
        this.getData({ pageNumber: 1 });
      }
    );
  };

  handleSearchInputActiveState = ({ target: { value } }) => {
    const { filterValues } = this.state;
    const { onSearchInputChange } = this.props;
    onSearchInputChange?.(value);
    this.setState({
      filterValues: { ...filterValues, searchInputText: value },
      searchInputState: {
        isSearchInputActive: !!value,
        isSearchInputActiveWithoutShadow: false,
        showClearCircle: !!value
      }
    });
  };

  inputSearchComponent = () => {
    const {
      searchPlaceholder = '',
      searchOptions,
      selectedSearchOption,
      setSelectedSearchOption
    } = this.props;
    const { isLoading, searchInputState, filterValues, isUserUsedSearch } =
      this.state;
    return (
      <>
        {!!searchOptions?.length && (
          <Select
            className="br-table__header__select-search-option"
            onChange={setSelectedSearchOption}
            value={selectedSearchOption}
            dropdownMatchSelectWidth={false}
          >
            {searchOptions.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        )}
        <Input.Search
          className={classnames('br-table__header__input-search', {
            'br-table__header__input-search--active':
              searchInputState.isSearchInputActive,
            'br-table__header__input-search--active__no-shadow':
              searchInputState.isSearchInputActiveWithoutShadow
          })}
          placeholder={searchPlaceholder}
          value={filterValues?.searchInputText}
          suffix={
            <Icon
              onClick={this.handleClearIcon}
              className="ant-icon-md"
              component={
                searchInputState.showClearCircle
                  ? ClearCircle
                  : !filterValues?.searchInputText?.length &&
                    !searchInputState.isSearchInputActiveWithoutShadow &&
                    Search
              }
            />
          }
          disabled={isLoading}
          onSearch={this.onSearch}
          onChange={this.handleSearchInputActiveState}
          onFocus={() => {
            this.setState({
              searchInputState: {
                ...searchInputState,
                isSearchInputActiveWithoutShadow: false,
                isSearchInputActive:
                  filterValues?.searchInputText?.length && !isUserUsedSearch
              }
            });
          }}
          onBlur={() =>
            this.setState({
              searchInputState: {
                ...searchInputState,
                isSearchInputActive: false,
                isSearchInputActiveWithoutShadow:
                  filterValues?.searchInputText?.length
              }
            })
          }
        />
      </>
    );
  };

  handleClearIcon = () => {
    const { isUserUsedSearch, filterValues } = this.state;
    this.setState(
      {
        searchInputState: {
          isSearchInputActive: false,
          isSearchInputActiveWithoutShadow: false,
          showClearCircle: false
        },
        filterValues: {
          ...filterValues,
          searchInputText: null
        }
      },
      () => isUserUsedSearch && this.getData({ pageNumber: 1 })
    );
  };

  handleFilters = () => {
    const { isFiltersClicked } = this.state;
    const { keepDataBetweenTabs } = this.props;
    this.setState(
      {
        isFiltersClicked: !isFiltersClicked,
        filterValues: {},
        selectedCards: [],
        filterLabels: [],
        activeTabKey: keepDataBetweenTabs ? this.state.activeTabKey : 0,
        isSelectButtonClicked: false
      },
      () => this.getData({ pageNumber: 1 })
    );
  };

  updateFilterLabels = (filtersLabels) => {
    this.setState(
      {
        filterLabels: filtersLabels,
        isInitialLoad: true
      },
      () => {
        this.getData({ pageNumber: 1 });
      }
    );
  };

  handleClearFilter = ({ key, value }) => {
    const { filterLabels, filterValues, activeTabKey } = this.state;
    const { keepDataBetweenTabs = false } = this.props;
    let getKey = '';
    let newFilter = { ...filterValues };
    Object.keys(filterValues).filter((item) => {
      value.map((val) => {
        if (`${val?.dateKey}Start` === item || `${val?.key}Start` === item) {
          delete newFilter[item];
        }
        if (`${val?.dateKey}End` === item || `${val?.key}End` === item) {
          delete newFilter[item];
        }
        if (val.key === item) {
          delete newFilter[item];
        }
      });
    });

    delete newFilter[getKey];
    const newFilterLables = {
      ...filterLabels
    };
    delete newFilterLables[key];
    this.setState(
      {
        filterLabels: newFilterLables,
        filterValues: newFilter
      },
      () => this.getData({ pageNumber: 1 })
    );
    if (!Object.keys(newFilterLables).length) {
      this.setState({
        activeTabKey: keepDataBetweenTabs ? activeTabKey : 0,
        isFiltersClicked: false,
        searchInputState: {
          isSearchInputActive: false,
          isSearchInputActiveWithoutShadow: false,
          showClearCircle: false
        }
      });
    }
  };

  handleFiltersTags = (value) => {
    const limitedTags = [];
    const extraTags = [];
    value?.map(({ label, dateLabel }, index) => {
      if (index < 4) {
        if (dateLabel) {
          limitedTags.push(`${label} ${dateLabel}`);
        } else {
          limitedTags.push(label);
        }
      } else {
        extraTags.push(label);
      }
    });
    return (
      <>
        {limitedTags.map(
          (tag, index) =>
            `${tag}${
              index === limitedTags.length - 2
                ? ', or '
                : index !== limitedTags.length - 1
                ? ', '
                : ''
            }`
        )}
        {value?.length > 4 && (
          <Tooltip
            key={value}
            overlayClassName="br-table-filters__extra-tags"
            title={extraTags.map((item, index) => (
              <div>
                {item}
                {index === extraTags.length - 2
                  ? ', or'
                  : index !== extraTags.length - 1
                  ? ','
                  : ''}
              </div>
            ))}
          >
            <span className="br-table-filters__extra-tags__label subheading">
              +{value.length - 4}
            </span>
          </Tooltip>
        )}
      </>
    );
  };

  cutsomizedPaginationIcons = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className="br-table-pagination-prev-icon">
          <Left />
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className="br-table-pagination-next-icon">
          <Right />
        </a>
      );
    }
    return originalElement;
  };

  render() {
    const {
      columns,
      className,
      pageSizeOptions,
      intl,
      quickFilters,
      title,
      emptyState,
      mobileScreenSizes,
      emptySearchContent,
      isScrolling,
      tableFilters,
      showSearch,
      MobileCardsRows,
      allowSelection = false,
      selectAllInitially,
      noFilterAppliedEmptyState,
      handleOnClickCard = () => {},
      handleSelectedRows,
      onRowClick,
      handlePrintSelectedRows,
      summary = false,
      tableActions,
      showExport = false,
      showSearchFilter = true,
      showFilter = true,
      showCustomFilterComponent,
      rowSelectionProps = {},
      sticky = false,
      preTableHeaderContent = null,
      exportListFileFunction,
      showUpperPart = false,
      externalDataSource = [],
      hideFilterButton = false,
      handleOnSelectRow,
      showPagination = true,
      isExportButtonDisabled,
      ...props
    } = this.props;
    const {
      listingData,
      isLoading,
      pageSize,
      count,
      pageNumber,
      activeTabKey,
      isFiltersClicked,
      selectedRowKeys,
      filterValues,
      filterLabels,
      selectedCards,
      isSelectButtonClicked
    } = this.state;

    const rowSelection = allowSelection
      ? {
          selectedRowKeys,
          columnWidth: '20',
          onChange: (selectedRowKeys, selectedRows) => {
            const {
              handleSelectedRows,
              processSelectedRows,
              handleSelectedRowsData
            } = this.props;
            const formattedKeys = processSelectedRows
              ? processSelectedRows(selectedRowKeys)
              : selectedRowKeys;
            this.setState({
              selectedRowKeys: formattedKeys
            });
            if (handleSelectedRows) {
              handleSelectedRows(formattedKeys, selectedRows);
            }
            if (handleSelectedRowsData) {
              handleSelectedRowsData(selectedRows);
            }
          },
          ...(handleOnSelectRow && {
            onSelect: handleOnSelectRow
          })
        }
      : null;

    return (
      <>
        <div
          key="_id"
          className={classnames('br-table-component', className, {
            'br-table-component__empty-state':
              !listingData.length && !isLoading && !externalDataSource?.length,
            'br-table__small-mobile': mobileScreenSizes?.isSmallMobileScreen,
            'br-table__medium-mobile': mobileScreenSizes?.isMediumMobileScreen,
            'br-table__fixed-header': isScrolling && selectedRowKeys.length,
            'br-table-component__no-selection': !allowSelection,
            'br-table-component__no-header':
              !title && !showFilter && !quickFilters?.length
          })}
        >
          {(!!quickFilters?.length ||
            showSearch ||
            showFilter ||
            showUpperPart ||
            title) && (
            <div className="br-table__quick-filter-tabs">
              {!!quickFilters?.length && (
                <Tabs
                  activeKey={`${activeTabKey}`}
                  tabBarGutter={0}
                  onChange={this.onChangeQuickFilterTab}
                >
                  {quickFilters?.map(
                    ({ label, isUserAuthorized = true }, index) =>
                      isUserAuthorized && (
                        <Tabs.TabPane tab={label} key={index} />
                      )
                  )}
                </Tabs>
              )}
              <div className="br-table__header" ref={this.filtersRef}>
                <div className="br-table__title heading">{title}</div>
                <div className="br-table__header__actions">
                  {showSearch &&
                    !mobileScreenSizes?.isSmallMobileScreen &&
                    !isFiltersClicked &&
                    this.inputSearchComponent()}
                  {(!showSearch || isFiltersClicked) &&
                    tableFilters &&
                    !mobileScreenSizes?.isSmallMobileScreen && (
                      <TableFilters
                        tableFiltersItems={tableFilters}
                        showSearch={showSearch}
                        updateFilterLabels={this.updateFilterLabels}
                        mobileScreenSizes={mobileScreenSizes}
                        filterData={filterValues}
                        setFilterData={(filterValues) =>
                          this.setState({ filterValues })
                        }
                        appliedFilterLabels={filterLabels}
                        setAppliedFilterLabels={(labels) =>
                          this.setState({ filterLabels: labels })
                        }
                        isLoading={isLoading}
                      />
                    )}
                  {showFilter && !hideFilterButton && (
                    <BRButton
                      prefixIcon={isFiltersClicked ? <Close /> : <Filters />}
                      disabled={isLoading}
                      onClick={this.handleFilters}
                      className={classnames('button-md', {
                        'br-table__header__actions__active-filters':
                          isFiltersClicked
                      })}
                      type="basic"
                      label={
                        !mobileScreenSizes?.isMediumMobileScreen &&
                        !isFiltersClicked &&
                        intl.formatMessage({ id: 'common.filters' })
                      }
                    />
                  )}
                  {exportListFileFunction && (
                    <BRButton
                      prefixIcon={<Download />}
                      disabled={isLoading || isExportButtonDisabled}
                      onClick={this.handleExportFile}
                      className="button-md"
                      type="basic"
                      label={
                        !mobileScreenSizes?.isMediumMobileScreen &&
                        !isFiltersClicked &&
                        intl.formatMessage({ id: 'common.export' })
                      }
                    />
                  )}
                  {tableActions && tableActions()}
                </div>
              </div>
              {showSearch &&
                mobileScreenSizes?.isSmallMobileScreen &&
                !isFiltersClicked &&
                this.inputSearchComponent()}
              {(!showSearch || isFiltersClicked) &&
                tableFilters &&
                mobileScreenSizes?.isSmallMobileScreen && (
                  <TableFilters
                    tableFiltersItems={tableFilters}
                    updateFilterLabels={this.updateFilterLabels}
                    mobileScreenSizes={mobileScreenSizes}
                    filterData={filterValues}
                    setFilterData={(filterValues) =>
                      this.setState({ filterValues })
                    }
                    appliedFilterLabels={filterLabels}
                    setAppliedFilterLabels={(labels) =>
                      this.setState({ filterLabels: labels })
                    }
                    isLoading={isLoading}
                  />
                )}
            </div>
          )}
          {!!filterLabels && !!Object.keys(filterLabels)?.length && (
            <div className="br-table-filters__all-tags">
              {Object.entries(filterLabels).map(([key, value]) => (
                <div className="display-flex" key={key}>
                  <div className="br-table-filters__tags">
                    <span className="br-table-filters__tags--title caption-lg">
                      {key}
                    </span>
                    <span className="br-table-filters__tags--content caption-lg">
                      {this.handleFiltersTags(value)}
                    </span>
                  </div>
                  <span
                    className="br-table-filters__tags--close-icon"
                    onClick={() => this.handleClearFilter({ key, value })}
                  >
                    <Icon className="ant-icon-xs" component={Close} />
                  </span>
                </div>
              ))}
            </div>
          )}
          {preTableHeaderContent ? preTableHeaderContent : null}
          {!listingData.length && !isLoading && !externalDataSource?.length ? (
            noFilterAppliedEmptyState &&
            !Object.values({
              ...filterValues,
              quickFiltersValue: undefined
            }).filter((n) => n).length ? (
              noFilterAppliedEmptyState()
            ) : emptyState ? (
              emptyState()
            ) : (
              <BREmptyState
                className="br-table-component__empty-state"
                title={emptySearchContent?.title}
                subTitle={emptySearchContent?.subtitle}
                actions={
                  !emptySearchContent?.hideAction && (
                    <Button type="primary" onClick={this.handleClearSearch}>
                      {intl.formatMessage({
                        id: `common.${
                          isFiltersClicked ? 'clear_filters' : 'clear_search'
                        }`
                      })}
                    </Button>
                  )
                }
                search={true}
              />
            )
          ) : mobileScreenSizes.isSmallMobileScreen && MobileCardsRows ? (
            <MobileCards
              dataSource={
                isLoading
                  ? []
                  : !!externalDataSource?.length
                  ? externalDataSource
                  : listingData
              }
              columns={MobileCardsRows}
              isLoading={isLoading}
              allowSelection={allowSelection}
              isScrolling={isScrolling}
              handleOnClickCard={handleOnClickCard}
              mobileScreenSizes={mobileScreenSizes}
              handleClearSearch={this.handleClearSearch}
              scrollToFilters={this.scrollToFilters}
              selectedCards={selectedCards}
              setSelectedCards={(selectedCards) =>
                this.setState({ selectedCards })
              }
              isSelectButtonClicked={isSelectButtonClicked}
              setIsSelectButtonClicked={(isSelectButtonClicked) =>
                this.setState({ isSelectButtonClicked })
              }
              filterLength={
                filterLabels ? Object.keys(filterLabels)?.length : 0
              }
              handleSelectedCards={
                handleSelectedRows ? handleSelectedRows : () => {}
              }
              handlePrintSelectedRows={
                handlePrintSelectedRows ? handlePrintSelectedRows : () => {}
              }
              pagination={{
                onChange: (pageNumber, pageSize) => {
                  this.setState({ pageNumber, pageSize });
                  this.getData({
                    pageNumber,
                    pageLimit: pageSize
                  });
                },
                total: count,
                current: pageNumber,
                pageSize: pageSize,
                locale: {
                  items_per_page: intl.formatMessage({
                    id: 'common.per_page'
                  })
                },
                showSizeChanger: true,
                pageSizeOptions: pageSizeOptions
              }}
            />
          ) : (
            <Table
              className="br-table"
              rowKey={(record) => (record?.key ? record.key : record?._id)}
              columns={columns}
              dataSource={
                isLoading
                  ? []
                  : !!externalDataSource?.length
                  ? externalDataSource
                  : listingData
              }
              loading={{
                spinning: isLoading,
                indicator: (
                  <LoadingWrapper
                    loading={isLoading}
                    indicator={
                      <div>
                        <LoadingOutlined spin />
                        <div className="br-table__loading body">
                          {intl.formatMessage({ id: 'common.loading' })}
                        </div>
                      </div>
                    }
                  />
                )
              }}
              rowSelection={rowSelection}
              components={this.components}
              scroll={{ x: 1040 }}
              onChange={this.handleTableChange}
              pagination={
                showPagination
                  ? {
                      position: ['bottomCenter'],
                      total: count,
                      current: pageNumber,
                      pageSize: pageSize,
                      locale: {
                        items_per_page: intl.formatMessage({
                          id: 'common.per_page'
                        })
                      },
                      showSizeChanger: !!pageSizeOptions?.length,
                      pageSizeOptions: pageSizeOptions,
                      itemRender: this.cutsomizedPaginationIcons
                    }
                  : false
              }
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    const selection = window.getSelection();
                    if (selection.toString().length !== 0) {
                      // selection
                      event.stopPropagation();
                    } else if (event.detail === 2) {
                      // double click
                      event.stopPropagation();
                    } else {
                      // normal single click
                      onRowClick && onRowClick(record);
                    }
                  }
                };
              }}
              showSorterTooltip={false}
              sticky={sticky}
              summary={() =>
                summary && (
                  <Table.Summary fixed={summary.fixed}>
                    <Table.Summary.Row>
                      {summary.cells.map(({ content, index, colSpan }, idx) => (
                        <Table.Summary.Cell
                          key={idx}
                          index={index || idx}
                          colSpan={colSpan || 2}
                        >
                          {content}
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                )
              }
              {...props}
            />
          )}
        </div>
      </>
    );
  }
}

export default injectIntl(withRouter(mediaHook(BRTable)));
