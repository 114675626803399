import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { Button, Form, Select, Divider, Tooltip } from 'antd';

import { SALARY_TYPES } from 'constants/stars';
import { NUMBER_TEXT } from 'constants/form';
import { getCurrency } from 'common/countries/countries-mapping';

import BRFormInputs from 'components/BRFormInputs/BRFormInputs';

import starSalary from 'assets/imgRevamp/starSalary.svg';

import './SalaryConfigsSection.less';

const SalaryConfigsSection = ({
  selectedSalaryType,
  onSalaryTypeChange,
  checkIfOptionShouldBeDisabled,
  handleAddNewSalaryType,
  disableAddSalaryTypeButton,
  salaryConfigration,
  handleRemoveSalaryType,
  formRef,
  isFMSelected,
  isLMSelected
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <p className="br-create-edit-star__title">
        {formatMessage({
          id: 'stars.create_edit_star.titles.salary'
        })}
      </p>
      {isLMSelected && (
        <div className="br-created-edit-star__salary-confirgarion">
          <div className="br-create-edit-star__salary-form display-flex align-center">
            <Form.Item
              className="br-form-row__half-width"
              label={formatMessage({
                id: 'stars.create_edit_star.form_labels.salary_type'
              })}
              name="salaryType"
              rules={[
                {
                  required: !selectedSalaryType?.length
                }
              ]}
            >
              <Select
                onChange={onSalaryTypeChange}
                placeholder={formatMessage({
                  id: `form.select_placeholder`
                })}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                autoComplete={`${Math.random()}`}
                allowClear
              >
                {SALARY_TYPES?.map(({ label, sublabel, value }) => (
                  <Select.Option key={label} value={value}>
                    {label}
                    <div className="br-create-edit-star__salary-sublabel">
                      {sublabel}
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Tooltip
              title={
                selectedSalaryType?.length === 2 &&
                formatMessage({
                  id: 'stars.create_edit_star.more_than_two_salaries'
                })
              }
              placement="topLeft"
              overlayClassName="br-create-edit-star__tooltip"
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <Button
                className="br-create-edit-star__salary-add-action"
                onClick={handleAddNewSalaryType}
                disabled={
                  selectedSalaryType?.length === 3 || disableAddSalaryTypeButton
                }
                type="primary"
              >
                {formatMessage({ id: 'stars.create_edit_star.add' })}
              </Button>
            </Tooltip>
          </div>
          <div className="br-create-edit-star__salary-section">
            {selectedSalaryType.length ? (
              selectedSalaryType.map((type) => (
                <div
                  key={type.value}
                  className="display-flex align-center justify-content-between"
                >
                  <span className="br-create-edit-star__salary-section__right-content">
                    <span className="br-create-edit-star__salary-section__title">
                      {type.value}
                    </span>
                    <span className="br-create-edit-star__salary-section__subtitle">
                      {salaryConfigration ? (
                        <>
                          {formatMessage(
                            {
                              id: `stars.create_edit_star.salary_type.${type.helpSentenceFor}.help_sentense`
                            },
                            {
                              currency: getCurrency().localized,
                              amount: salaryConfigration[type?.salaryConfigKey],
                              vehicleType: (
                                <span className="bold">
                                  {salaryConfigration?.vehicleType}
                                </span>
                              ),
                              zone: (
                                <span className="bold">
                                  {formRef.current.getFieldValue('zone')}
                                </span>
                              )
                            }
                          )}
                        </>
                      ) : (
                        <>
                          {formatMessage({
                            id: `stars.create_edit_star.salary_type.${type.helpSentenceFor}.title`
                          })}
                          {formatMessage({
                            id: `stars.create_edit_star.salary_type.default_help_sentense`
                          })}
                        </>
                      )}
                    </span>
                  </span>
                  <Button
                    type="danger"
                    onClick={() => handleRemoveSalaryType(type.value)}
                  >
                    {formatMessage({ id: 'stars.create_edit_star.remove' })}
                  </Button>
                </div>
              ))
            ) : (
              <div className="br-create-edit-star__empty-salary-section">
                <img alt="Star Wallet" src={starSalary} />
                <span className="br-create-edit-star__salary-section__hint">
                  {formatMessage({
                    id: 'stars.create_edit_star.how_to_compensate_star'
                  })}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {isFMSelected && !isLMSelected && (
        <div className="br-star-salary__fm-config-section">
          <BRFormInputs
            formRef={formRef}
            placeholder="0.00"
            name={['fm', 'fixedPerDay']}
            type={NUMBER_TEXT}
            acceptFractions
            prefix={getCurrency().localized}
            label={formatMessage({
              id: 'stars.create_edit_star.form_labels.fixed_per_day'
            })}
          />
          <BRFormInputs
            formRef={formRef}
            placeholder="0.00"
            name={['fm', 'variable']}
            type={NUMBER_TEXT}
            acceptFractions
            prefix={getCurrency().localized}
            label={formatMessage({
              id: 'stars.create_edit_star.form_labels.variable_per_stop'
            })}
          />
        </div>
      )}
      <Divider />
    </div>
  );
};

export default SalaryConfigsSection;
