import { Card, Form, List } from 'antd';

import { useReceiveFromBusinessContext } from 'customHooks';

import BRButton from 'components/BRButton/BRButton';
import ScannedPackagesHeader from './components/ScannedPackagesHeader/ScannedPackagesHeader';
import EmptyDataLabel from './components/EmptyDataLabel/EmptyDataLabel';

import { ReactComponent as TrashIcon } from 'assets/imgRevamp/trash.svg';

import './ScannedPackages.less';

const ScannedPackages = () => {
  const { packages, removePackage, form } = useReceiveFromBusinessContext();
  const handleRemovePackage = (id) => {
    removePackage(id);
    form.setFieldsValue({
      deliveries: packages.filter((item) => item._id !== id)
    });
  };
  return (
    <Card
      title={<ScannedPackagesHeader scannedPackagesCount={packages?.length} />}
      className="br-hubs-receive-from-business__pickup-scanning__scanned-packages"
    >
      <Form.Item name="deliveries" initialValue={packages}>
        <List
          dataSource={packages}
          renderItem={(item) => (
            <List.Item>
              {item.trackingNumber}{' '}
              <BRButton
                onClick={() => handleRemovePackage(item._id)}
                label={<TrashIcon />}
              />
            </List.Item>
          )}
          locale={{
            emptyText: <EmptyDataLabel />
          }}
        />
      </Form.Item>
    </Card>
  );
};

export default ScannedPackages;
