import { updateFulfillmentActivation } from 'services/business';

import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import BusinessSwitch from '../BusinessSwitch';
import FulfillmentSwitchModal from './FulfillmentSwitchModal/FulfillmentSwitchModal';

const BusinessFulfillmentSwitch = ({ businessData, submitBusinessUpdate }) => {
  const { isBostaFulfillment = false } = businessData;

  const disableSwitch = !isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
    ACL_MATRIX.BUSINESS_CONFIGURE_FULFILLMENT
  ]);

  const confirmationModal = {
    modal: FulfillmentSwitchModal,
    modalProps: ({ value, ...options }) => ({
      businessData,
      onSuccess: () => {
        submitBusinessUpdate(
          {
            isActive: !isBostaFulfillment
          },
          {
            callback: updateFulfillmentActivation,
            ...options
          }
        );
      }
    })
  };

  return (
    <BusinessSwitch
      initialChecked={isBostaFulfillment}
      disabled={disableSwitch}
      confirmationModal={confirmationModal}
    />
  );
};

export default BusinessFulfillmentSwitch;
