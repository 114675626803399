import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';

import { checkOrderExistance } from 'services/hubs';

import { notify } from 'components/Notify/Notify';

const validateSealNumber = async ({
  value,
  selectedScanningType,
  resetInputs,
  scanType,
  cb,
  onComplete
}) => {
  const re = /^[0-9\b]+$/;
  if (!re.test(value)) {
    notify('The Seal number you entered should be number only!');
  } else {
    try {
      const item = await checkOrderExistance({ sealCode: value });
      if (item) {
        cb?.({ item, selectedScanningType, scanType });
      } else {
        notify('The seal number you entered is not valid!', 'error', true);
      }
      resetInputs(scanType === 'Selected');
    } catch (error) {
      notify('Security seal number doesn’t exist', 'error', true);
    }
  }
  onComplete?.();
};

const debouncedValidateSealNumber = debounce(validateSealNumber, 500);

const useSealNumberValidation = (packages, cb, deps) => {
  const [loading, setLoading] = useState(false);

  const handleSealNumberChange = useCallback(
    async (value, selectedScanningType, resetInputs, scanType) => {
      if (packages.length === 300) {
        notify(
          `You have reached the maximum number of receiving package per time.`
        );
      }
      setLoading(true);
      await debouncedValidateSealNumber({
        value: value.trim(),
        selectedScanningType,
        resetInputs,
        scanType,
        cb,
        onComplete: () => {
          setLoading(false);
        }
      });
    },
    [cb, packages.length, ...(deps ?? [])]
  );

  return {
    handleSealNumberChange,
    loadingSealNumberValidation: loading
  };
}

export default useSealNumberValidation;