import { Modal, Form, Select, Input } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { formatPolygonCoordinates } from 'utils/bosta-coding';
import { createDistrictAndStreetCode } from 'services/zoning';
import { POLYGON } from 'constants/dynamic-routing';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import './CreateNewDistrictModal.less';

const CreateNewDistrictModal = ({
  close,
  zones,
  hubId,
  polygonRef,
  onSuccess,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const handleOnFinish = async (values) => {
    const { name, nameAr, zoneId, l2SectorCode, l2DistrictCode } = values;

    setIsLoading(true);

    try {
      const geometry = {
        coordinates: [formatPolygonCoordinates(polygonRef)],
        type: POLYGON
      };
      const district = {
        name,
        nameAr,
        zoneId,
        hubId,
        geometry
      };

      const streetCode = {
        name,
        nameAr,
        l2SectorCode,
        l2DistrictCode,
        geometry
      };

      const payload = {
        district,
        streetCode
      };

      await createDistrictAndStreetCode(payload);
      notify(
        intl.formatMessage({
          id: 'bosta_coding.district_mapping.create_district_success_msg'
        }),
        'success'
      );
      close();
      onSuccess();
    } catch (error) {
      notify(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'bosta_coding.district_mapping.create_new_district'
      })}
      footer={
        <>
          <BRButton
            onClick={close}
            label={intl.formatMessage({ id: 'common.cancel' })}
            disabled={isLoading}
          />
          <BRButton
            type="primary"
            label={intl.formatMessage({ id: 'common.create' })}
            form="createNewDistrictForm"
            htmlType="submit"
            loading={isLoading}
          />
        </>
      }
      wrapClassName="br-create-new-district__modal"
      {...props}
    >
      <Form name="createNewDistrictForm" onFinish={handleOnFinish}>
        <Form.Item
          name="zoneId"
          label={intl.formatMessage({
            id: 'bosta_coding.district_mapping.labels.zone'
          })}
          rules={[{ required: true }]}
        >
          <Select
            options={zones}
            fieldNames={{ label: 'name', value: '_id' }}
            showSearch
            showArrow
            optionFilterProp="name"
            placeholder={intl.formatMessage({
              id: 'common.select'
            })}
          />
        </Form.Item>
        <div className="br-create-new-district__modal-form-row">
          <Form.Item
            name="name"
            label={intl.formatMessage({
              id: 'bosta_coding.district_mapping.labels.en_district_name'
            })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'bosta_coding.district_mapping.placeholder.enter_name'
              })}
            />
          </Form.Item>
          <Form.Item
            name="nameAr"
            label={intl.formatMessage({
              id: 'bosta_coding.district_mapping.labels.ar_district_Name'
            })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'bosta_coding.district_mapping.placeholder.enter_name'
              })}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="l2SectorCode"
          label={intl.formatMessage({
            id: 'bosta_coding.district_mapping.labels.sector_code'
          })}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'bosta_coding.district_mapping.placeholder.enter_code'
            })}
          />
        </Form.Item>
        <Form.Item
          name="l2DistrictCode"
          label={intl.formatMessage({
            id: 'bosta_coding.district_mapping.labels.district_code'
          })}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'bosta_coding.district_mapping.placeholder.enter_code'
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateNewDistrictModal;
