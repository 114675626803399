import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { downloadLegalDocuments } from 'services/business';
import { downloadAsZip } from 'utils/download';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import './DownloadDocumentButton.less';

const DownloadDocumentButton = ({
  documentType,
  businessId,
  canBeDownloaded = false
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const downloadHandler = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        businessId
      };

      if (documentType === 'payment') {
        payload.payload = {
          type: documentType
        };
      }
      const data = await downloadLegalDocuments(payload);

      downloadAsZip(data, `${businessId}-${documentType}-Documents`);
    } catch (error) {
      notify(error.message);
    } finally {
      setLoading(false);
    }
  }, [businessId, documentType]);

  if (!canBeDownloaded) {
    return (
      <span className="br-business-details__no-documents-upload">
        {intl.formatMessage({
          id: 'business_details.business_info.no_document_upload'
        })}
      </span>
    );
  }

  return (
    <BRButton
      type="link-color"
      onClick={downloadHandler}
      label={intl.formatMessage({
        id: 'business_details.business_info.download_document'
      })}
      loading={loading}
      disabled={loading}
    />
  );
};

export default DownloadDocumentButton;
