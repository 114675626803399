import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { FlyersCells } from 'constants/business-details';
import { addExtraFlyersQuota } from 'services/business';
import { openModal } from 'utils/modal';
import { isUserAuthorized } from 'utils/helpers';

import BRButton from 'components/BRButton/BRButton';
import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import { notify } from 'components/Notify/Notify';
import AddBostaShopQuotaModal from 'components/Businesses/AddBostaShopQuotaModal/AddBostaShopQuotaModal';

const FlyersInfo = ({ flyersInfo, refreshData }) => {
  const intl = useIntl();

  const { availableNoOfFlyers, businessId, businessName } = flyersInfo;

  const handleAddBusinessFlyersQuota = async (payload) => {
    try {
      await addExtraFlyersQuota(businessId, payload);

      notify(
        intl.formatMessage(
          {
            id: 'businesses.details.flyersInfo.quota_updated'
          },
          {
            businessName
          }
        ),
        'success'
      );

      refreshData?.();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleEditOnClick = () => {
    openModal(AddBostaShopQuotaModal, {
      onFinish: (payload) => handleAddBusinessFlyersQuota(payload),
      initialAmount: availableNoOfFlyers
    });
  };

  const flyersCells = FlyersCells(
    <BRButton
      onClick={handleEditOnClick}
      label={intl.formatMessage({ id: 'common.edit' })}
      type="outline"
      disabled={
        !isUserAuthorized(aclMatrix.BUSINESSES_DETAILS, [
          ACL_MATRIX.BUSINESS_ADD_FLYERS_QUOTE
        ])
      }
    />
  );

  return (
    <BRInformationTable
      cells={flyersCells}
      dataSource={flyersInfo}
      totalCol={2}
    />
  );
};

export default FlyersInfo;
