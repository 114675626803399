import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';

import {
  PricingInfoCells,
  WalletInfoCells,
  BankInfoCells,
  InternationalCells,
  OperationsCells,
  FulfillmentCells
} from 'constants/business-details';
import {
  fetchBusinessDetailsData,
  formatBusinessDetails
} from 'utils/business-details';
import { getBusiness } from 'services/business';
import { isInternationalShippingAvailable } from 'common/countries/countries-mapping';

import BRHeader from 'components/BRHeader/BRHeader';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import Container from 'components/Container';
import { notify } from 'components/Notify/Notify';
import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import BusinessHeader from './BusinessHeader/BusinessHeader';
import ManageBusiness from './ManageBusiness/ManageBusiness';
import BusinessInfo from './BusinessInfo/BusinessInfo';
import FlyersInfo from './FlyersInfo/FlyersInfo';
import PromotionsInfo from './PromotionsInfo/PromotionsInfo';
import DefaultBusinessLocation from './DefaultBusinessLocation/DefaultBusinessLocation';
import TeamMembers from './TeamMembers/TeamMembers';
import LogsTimeline from './LogsTimeline/LogsTimeline';
import SllrInfo from './SllrInfo/SllrInfo';

import './NewBusinessDetails.less';

const NewBusinessDetails = () => {
  const intl = useIntl();
  const { id: businessId } = useParams();
  const [fetchedData, setFetchedData] = useState({
    businessData: {},
    fulfillmentInfo: [],
  });
  const [loading, setLoading] = useState(false);

  const { businessData, fulfillmentData } = fetchedData;

  const {
    businessInfo,
    pricingInfo,
    walletInfo,
    flyersInfo,
    bankInfo,
    secondBankInfo,
    internationalWalletInfo,
    operationsSettingsInfo,
    fulfillmentInfo,
    defaultBusinessLocationInfo,
    logsTimelineInfo,
    sllrInfo
  } = useMemo(() => formatBusinessDetails(businessData), [businessData]);

  const fetchData = useCallback(
    async (cancelToken = undefined) => {
      setLoading(true);
      try {
        const data = await fetchBusinessDetailsData(businessId, cancelToken);
        setFetchedData(data);
      } catch (e) {
        if (e.status !== -1) {
          notify(e.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [businessId]
  );

  const refreshBusinessData = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getBusiness(businessId);
      setFetchedData(({ businessData: { basicSllrData }, ...curr }) => ({
        ...curr,
        businessData: {
          basicSllrData,
          ...data
        }
      }));
    } catch (e) {
      if (e.status !== -1) {
        notify(e.message);
      }
    } finally {
      setLoading(false);
    }
  }, [businessId]);

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    fetchData(cancelSource.token);
    return () => cancelSource.cancel();
  }, [fetchData]);

  return (
    <LoadingWrapper loading={loading}>
      <Container
        header={
          <BRHeader
            title={intl.formatMessage({ id: 'businesses.details.title' })}
            showBackArrow
          />
        }
        content={
          <article className="br-business-details">
            <BusinessHeader businessData={businessData} loading={loading} />

            <div className="br-business-details__management-wrapper">
              <ManageBusiness
                businessData={businessData}
                refreshData={refreshBusinessData}
              />

              <div>
                <BusinessInfo businessInfo={businessInfo} />

                {businessData.tier && (
                  <BRInformationTable
                    cells={PricingInfoCells()}
                    dataSource={pricingInfo}
                    totalCol={3}
                  />
                )}

                {businessData.paymentInfo && (
                  <BRInformationTable
                    cells={WalletInfoCells()}
                    dataSource={walletInfo}
                    totalCol={3}
                  />
                )}

                <FlyersInfo
                  flyersInfo={flyersInfo}
                  refreshData={refreshBusinessData}
                />

                {businessData.bankInfo && (
                  <BRInformationTable
                    cells={BankInfoCells()}
                    dataSource={bankInfo}
                    totalCol={4}
                  />
                )}

                {businessData.secondBankInfo && (
                  <BRInformationTable
                    cells={BankInfoCells(
                      'business_details.business_bank_info.international'
                    )}
                    dataSource={secondBankInfo}
                    totalCol={4}
                  />
                )}

                {isInternationalShippingAvailable() && (
                  <BRInformationTable
                    cells={InternationalCells()}
                    dataSource={internationalWalletInfo}
                    totalCol={2}
                  />
                )}

                <BRInformationTable
                  cells={OperationsCells()}
                  dataSource={operationsSettingsInfo}
                  totalCol={3}
                />

                {businessData.fulfillmentPricingTierId && (
                  <BRInformationTable
                    cells={FulfillmentCells()}
                    dataSource={fulfillmentInfo(fulfillmentData)}
                    totalCol={1}
                  />
                )}

                <PromotionsInfo businessId={businessId} />

                {businessData.defaultPickupLocation && (
                  <DefaultBusinessLocation
                    defaultBusinessLocationInfo={defaultBusinessLocationInfo}
                    refreshData={refreshBusinessData}
                  />
                )}
              </div>
            </div>

            <TeamMembers
              businessId={businessId}
              refreshData={refreshBusinessData}
            />

            <LogsTimeline logsTimelineInfo={logsTimelineInfo} />

            <SllrInfo sllrInfo={sllrInfo} />
          </article>
        }
      />
    </LoadingWrapper>
  );
};
export default NewBusinessDetails;
