import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Form, Select, Input } from 'antd';
import dayjs from 'dayjs';

import { HubsContext } from 'contexts/hub.context';
import { WRONG_SCAN_HUB_FILTERS_NAMES } from 'constants/hubs';

import SearchDateRangePicker from 'containers/Deliveries/DeliveriesSearch/components/SearchDateRangePicker/SearchDateRangePicker';
import BRButton from 'components/BRButton/BRButton';

import './WrongScanFilters.less';

const WrongScanFilters = ({
  form,
  handleClearFilters,
  handleSubmitFilters
}) => {
  const { allHubs } = useContext(HubsContext);
  const intl = useIntl();

  const onFinish = (values) => {
    if (values.date) {
      values.startDate = dayjs(values.date[0]).startOf('day').format();
      values.endDate = dayjs(values.date[1]).endOf('day').format();
      delete values.date;
    }

    values.trackingNumbers = values.trackingNumbers?.trim() || undefined;
    if (values.trackingNumbers) {
        values.trackingNumbers = [values.trackingNumbers];
     }

    handleSubmitFilters(values);
  };

  const handleOnClickFiltersClick = () => {
    handleClearFilters({ refreshTable: true });
  };

  return (
    <div className="br-wrong-scan-filters__container">
      <div className="br-wrong-scan-filters__header">
        <div className="br-wrong-scan-filters__title display-xs">
          {intl.formatMessage({ id: 'common.search' })}
        </div>
      </div>
      <div className="br-wrong-scan-filters__content">
        <Form onFinish={onFinish} form={form}>
          <div className="br-form-row">
            <SearchDateRangePicker
              name="date"
              label={intl.formatMessage({
                id: 'hubs.wrong_scan.filter_labels.scanned_at'
              })}
            />

            <Form.Item
              name="trackingNumbers"
              label={intl.formatMessage({
                id: 'hubs.wrong_scan.filter_labels.tracking_number'
              })}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'hubs.wrong_scan.filter_labels.enter_tracking_number'
                })}
              />
            </Form.Item>

            {WRONG_SCAN_HUB_FILTERS_NAMES.map((filter) => (
              <Form.Item
                key={filter}
                name={filter}
                label={intl.formatMessage({
                  id: `hubs.wrong_scan.filter_labels.${filter}`
                })}
              >
                <Select
                  options={allHubs}
                  fieldNames={{ label: 'name', value: '_id' }}
                  showSearch
                  allowClear
                  optionFilterProp="name"
                  placeholder={intl.formatMessage({ id: 'common.select' })}
                />
              </Form.Item>
            ))}
          </div>
          <div className="br-wrong-scan-filters__actions">
            <BRButton
              label={intl.formatMessage({ id: 'common.clear_filters' })}
              onClick={handleOnClickFiltersClick}
            />
            <BRButton
              type="primary"
              label={intl.formatMessage({ id: 'common.apply_filters' })}
              htmlType="submit"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default WrongScanFilters;
