import { useIntl } from 'react-intl';

const FilterHeader = ({}) => {
  const intl = useIntl();

  return (
    <div className="br-filters-search__header">
      <h3 className="br-filters-search__header-title font-18 display-xs">
        {intl.formatMessage({
          id: 'common.filter'
        })}
      </h3>
    </div>
  );
};

export default FilterHeader;
