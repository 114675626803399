import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { get as getValue, omitBy } from 'lodash';
import { Dropdown, Menu, Table, Tag, Tooltip, Divider } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import dayjs from 'dayjs';
import Select from 'react-select';
import { Link } from 'react-router-dom';

import {
  exportXlsx,
  fetchDeliveriesCount,
  updateDelivery,
  updateManyDeliveries
} from 'services/deliveries';
import { receivePackagesAtHub } from 'services/hubs';
import { openModal } from 'utils/modal';
import {
  markAsLostOrDamaged,
  restartDeliveredDelivery,
  redispatchRTODelivery
} from 'actions/DeliveriesActions';

import { isDSPVendor, isUserAuthorized } from 'utils/helpers';
import { showBill } from 'utils';
import {
  UNDERCOVERED_ZONE,
  ALLOWED_STATES_TO_RESET,
  MAP_DELIVERY_TYPES,
  ASSIGN_MANY_TO_HUB_TYPE,
  ACTION_TYPES,
  DELIVERY_UPDATE_TYPE,
  deliveriesStates
} from 'constants/Deliveries';
import { notify } from 'components/Notify/Notify';
import Calendar from '../../components/Calendar';
import ArchiveOrder from './ArchiveOrder/ArchiveOrder';
import ConfirmationModalComponent from 'components/BRModals/ConfirmationModal/ConfirmationModal';
import { fmt } from 'components/IntlWrapper/IntlWrapper';

import {
  addDeliveriesToRoute,
  addDeliveryComment,
  cancelDelivery,
  clearAirWayBill,
  getAllActiveRoutes,
  getAllCities,
  getAllDeliveries,
  getAllDistricts,
  getAllHubs,
  getAllStars,
  getAllZones,
  printBill,
  rerequestCancelledDelivery,
  returnToBusiness,
  terminateDelivery,
  resetDeliveredDelivery,
  resetDeliveredDeliveries
} from '../../actions';
import { FORMAT_DATE_TO_UTC, isDefaultBostaCourier } from 'utils/helpers';
import { DATE_PAYLOAD, formatType } from 'utils/deliveries';
import { mediaHook } from 'customHooks';

import { deliveriesColumnsMapped, tablePageSize } from './constants';
import { COUNTRY_CURRENCY } from 'constants/helper';
import { ORDER_TYPES_VALUE } from 'constants/shipment-details';
import {
  ALL_ORDER_FINAL_STATES,
  ASSIGN_TO_HUB_UPDATE_TYPE,
  BAD_ADDRESS,
  CONSIGNEE_TRACKING_URL,
  DELIVERY_CHILD_STATES,
  DELIVERY_STATES_CODES,
  FINAL_STATES_CODES,
  MANUAL_ASSIGN_HUB_ACCESS,
  MARKETPLACE,
  PRINTING_OPTIONS,
  PUDO_PROVIDER
} from 'constants/shipments';
import { printMassAirwayBills, updateOrder } from 'services/shipments';
import { ACL_MATRIX, PAGES_ACCESS } from 'common/permissions';
import { INTERNATIONAL_ORDERS_RETURN_TYPES } from 'constants/international-orders';

import styles from './styles.module.scss';

import Container from '../../components/Container';
import BRHeader from 'components/BRHeader/BRHeader';
import AddCompensationFormModal from 'components/AddCompensationFormModal/AddCompensationFormModal';
import MobileCards from 'components/MobileCards/MobileCards';
import AssignHubModal from 'containers/Deliveries/AssignHubModal/AssignHubModal';
import DeliveriesSearch from './DeliveriesSearch/DeliveriesSearch';
import CreateTicketModal from 'components/SupportTickets/components/CreateTicketModal/CreateTicketModal';
import Banner from 'components/Banner/Banner';
import InternationalOrdersTag from 'components/InternationalOrders/components/InternationalOrdersTag/InternationalOrdersTag';
import BRButton from 'components/BRButton/BRButton';
import BRDropdownModal from 'components/BRDropdownModal/BRDropdownModal';
import LostOrDamagedModal from './DeliveriesSearch/components/LostOrDamagedModal/LostOrDamagedModal';

import deliveryStateFormatter from './common/DeliveryStateFormatter';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';

import { ReactComponent as SllrOrder } from 'assets/bosta-icons/Sllr-Order.svg';
import { ReactComponent as ClosedEye } from 'assets/bosta-icons/Eye-1.svg';
import { ReactComponent as ChevronLeft } from 'assets/bosta-icons/Chevron-left.svg';
import { intl } from 'IntlWrapper/IntlWrapper';

import './styles.less';

// status list to be used in the status filter lookup
// const statusList = Object.keys(shipmentsStatusDict).map(key => ({
//   key: shipmentsStatusDict[key].join(),
//   text: key
// }));

/**
 * format options used to be bound in the search form lookups
 * @param keyFieldName
 * @param labelFieldName
 * @param data
 * @returns {*}
 */
const formatOptions = (keyFieldName, labelFieldName, data) => {
  if (keyFieldName && labelFieldName) {
    const labelFields = labelFieldName.split(',');
    return data?.map((item) => {
      const labelValue = labelFields.reduce((acc, value) => {
        if (!value) return acc;
        return `${acc} ${getValue(item, value, '')}`;
      }, '');

      return { value: item[keyFieldName], label: labelValue };
    });
  }
  return data?.map((item) => ({ value: item, label: item }));
};

const handleAssignSingleDeliveryToHub = async ({
  intl,
  selectedHubId,
  deliveryId
}) => {
  try {
    const payload = {
      warehouseId: selectedHubId,
      updateType: ASSIGN_TO_HUB_UPDATE_TYPE
    };

    await updateOrder({
      id: deliveryId,
      payload
    });

    notify(
      intl.formatMessage({ id: 'shipments.actions.assign_hub_success' }),
      'success'
    );
  } catch (error) {
    notify(error.message);
  }
};

const handleRejectReturnDelivery = async (id) => {
  try {
    const payload = {
      updateType: ACTION_TYPES.REJECT_RETURN
    };
    await updateDelivery(id, payload);
    notify(
      intl.formatMessage({ id: 'shipments.actions.reject_returns_success' }),
      'success'
    );
  } catch (error) {
    notify(error.massage);
  }
};

const handleCancelDeliveriesAsBadAddress = async ({ deliveriesIds }) => {
  try {
    const payload = {
      updateType: 'cancelMany',
      deliveries: deliveriesIds.map((deliveryId) => ({ _id: deliveryId })),
      reason: BAD_ADDRESS
    };

    await updateManyDeliveries(payload);
    notify(
      fmt({
        id: 'shipments.actions.bad_address_confirmation_success'
      }),
      'success'
    );
  } catch (error) {
    notify(error.message);
  }
};

const handleMarkDeliveryAsBadAddressClick = ({ deliveriesIds }) => {
  openModal(ConfirmationModalComponent, {
    title: fmt({
      id: 'shipments.actions.bad_address_confirmation_title'
    }),
    content: fmt({
      id: 'shipments.actions.bad_address_confirmation_content'
    }),
    confirmButtonLabel: fmt({ id: 'common.confirm' }),
    confirmButtonType: 'primary',
    modalAction: () => handleCancelDeliveriesAsBadAddress({ deliveriesIds })
  });
};

const handleAssignMultiDeliveriesToHub = async ({
  intl,
  selectedHubId,
  deliveryIds,
  deliveries
}) => {
  const finalStateTrackingNumbers = [];
  const restDeliveries = [];
  const pudoOrders = [];
  deliveryIds.forEach((id) => {
    const { _id, trackingNumber, state, integrationInfo } =
      deliveries.find((delivery) => id === delivery?._id) || {};
    if (FINAL_STATES_CODES.includes(state?.code)) {
      finalStateTrackingNumbers.push(trackingNumber);
    } else if (integrationInfo?.provider === PUDO_PROVIDER) {
      pudoOrders.push(trackingNumber);
    } else {
      restDeliveries.push({ _id });
    }
  });

  const payload = {
    updateType: ASSIGN_MANY_TO_HUB_TYPE,
    deliveries: restDeliveries,
    warehouseId: selectedHubId
  };

  const failureMsg = intl.formatMessage(
    { id: 'shipments.actions.assign_many_to_hub_failed' },
    {
      trackingNumbers: finalStateTrackingNumbers.join(', ')
    }
  );

  if (restDeliveries.length) {
    try {
      await receivePackagesAtHub(payload);
      const msg = `${intl.formatMessage({
        id: 'shipments.actions.assign_hub_success'
      })}${finalStateTrackingNumbers.length ? ` ${failureMsg}` : ''}`;
      notify(msg, 'success');
    } catch (e) {
      notify(e.message);
    }
  } else {
    if (finalStateTrackingNumbers?.length && pudoOrders?.length) {
      notify(
        intl.formatMessage(
          { id: 'shipments.actions.combined_assign_many_failed_msg' },
          {
            trackingNumbers: finalStateTrackingNumbers.join(', '),
            pudoTrackingNumbers: pudoOrders.join(', ')
          }
        )
      );
    } else if (finalStateTrackingNumbers?.length) {
      notify(failureMsg);
    } else {
      notify(
        intl.formatMessage({
          id: 'shipments.actions.assign_many_to_hub_failed_pudo'
        })
      );
    }
  }
};

const handleTrackOrderClick = ({ trackingNumber }) => {
  window.open(`${CONSIGNEE_TRACKING_URL}${trackingNumber}`, '_blank');
};

/**
 * actions menu to be used in the table's action column
 * @param item
 * @param options
 * @returns {*}
 */

const actionsMenu = (item, options, hubs, stars) => {
  const { intl } = options;
  return (
    <Menu>
      {/* <Menu.Item onClick={() => {
      options.history.push(`/deliveries/${item._id}/details`);
    }}>Show</Menu.Item> */}
      <Menu.Item
        onClick={() => {
          options.printAirWayBill({
            ids: item._id,
            trackingNumber: item.trackingNumber
          });
        }}
        disabled={ALL_ORDER_FINAL_STATES.includes(item?.state?.code)}
      >
        {intl.formatMessage({ id: 'shipments.actions.print_awb' })}
      </Menu.Item>
      {(isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
        !item.isConfirmedDelivery &&
        ALLOWED_STATES_TO_RESET.includes(item?.state?.code)) ||
      (!isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
        item?.state?.code === 102) ? (
        <Menu.Item
          onClick={() => {
            options.setOpenResetModal(true);
            options.setProposedResetDelivery(item);
          }}
        >
          {intl.formatMessage({ id: 'shipments.actions.reset' })}
        </Menu.Item>
      ) : null}
      {/* <Menu.Item
      onClick={() => {
        options.setProposedCancelledDelivery(item);
        options.setOpenCancelModal(true);
      }}
      disabled={
        item.state.value === 'Canceled' ||
        item.state.value === 'Pickup requested' ||
        item.type === 'CASH_COLLECTION'
      }
    >
      Cancel
    </Menu.Item> */}
      {isUserAuthorized(MANUAL_ASSIGN_HUB_ACCESS, [
        ACL_MATRIX.DELIVERY_ASSIGN_TO_HUB
      ]) &&
        item?.integrationInfo?.provider !== PUDO_PROVIDER && (
          <Menu.Item
            disabled={FINAL_STATES_CODES.includes(item?.state?.code)}
            onClick={() =>
              openModal(AssignHubModal, {
                fetchedHubs: hubs,
                onConfirm: (selectedHubId) =>
                  handleAssignSingleDeliveryToHub({
                    intl,
                    deliveryId: item?._id,
                    selectedHubId
                  })
              })
            }
          >
            {intl.formatMessage({ id: 'shipments.actions.assign_hub' })}
          </Menu.Item>
        )}
      {/* <Menu.Item
        //enabling the reject return button in case the delivery is recieved at warehouse and waiting for a reject return action
        disabled={
          !(
            item?.state?.code === DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE &&
            item.state.childState ===
              DELIVERY_CHILD_STATES.WAITING_FOR_REJECTED_RETURN_ACTION
          )
        }
        onClick={() => {
          //opening a modal to confirm with the admin that he is about to reject a retrun delivery action
          openModal(ConfirmationModalComponent, {
            title: intl.formatMessage({
              id: 'shipments.actions.confirm_reject_retrun_title'
            }),
            content: intl.formatMessage({
              id: 'shipments.actions.confirm_reject_return'
            }),
            confirmButtonLabel: intl.formatMessage({
              id: 'common.confirm'
            }),
            modalAction: () => handleRejectReturnDelivery(item?._id)
          });
        }}
      >
        {intl.formatMessage({ id: 'shipments.actions.reject_returns' })}
      </Menu.Item> */}
      <Menu.Item
        onClick={() => {
          handleTrackOrderClick(item);
        }}
      >
        {intl.formatMessage({ id: 'shipments.actions.track_order' })}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          options.setProposedCommentedDelivery(item);
          options.setOpenCommentModal(true);
        }}
      >
        {intl.formatMessage({ id: 'shipments.actions.add_comment' })}
      </Menu.Item>
      {isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ? (
        <Menu.Item
          onClick={() => {
            options.setProposedRerequestedDelivery(item);
            options.setOpenRerequestModal(true);
          }}
          disabled={item?.state?.value !== 'Canceled'}
        >
          {intl.formatMessage({ id: 'shipments.actions.re_request' })}
        </Menu.Item>
      ) : null}
      {isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ? (
        <Menu.Item
          onClick={() => {
            options.setProposedRedispatchDelivery(item);
            options.setOpenRedispatchModal(true);
          }}
          disabled={item?.type !== 'RTO'}
        >
          {intl.formatMessage({ id: 'shipments.actions.re_dispatch' })}
        </Menu.Item>
      ) : null}
      <Divider className={'action-divider'} />
      {(isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ||
        isDSPVendor()) && (
        <Menu.Item
          onClick={() => options.cancelDeliveryAsUncoveredZone([item._id])}
        >
          {intl.formatMessage({ id: 'shipments.actions.uncovered_zone' })}
        </Menu.Item>
      )}
      <Menu.Item
        disabled={
          !isUserAuthorized(aclMatrix.DELIVERIES_EDIT, [
            ACL_MATRIX.DELIVERY_MARK_AS_RECEIVED_BY_MISTAKE
          ])
        }
        onClick={() =>
          handleMarkDeliveryAsBadAddressClick({
            deliveriesIds: [item._id]
          })
        }
      >
        {intl.formatMessage({ id: 'shipments.actions.bad_address' })}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          openModal(ArchiveOrder, {
            id: item?._id,
            title: intl.formatMessage({
              id: 'shipments.actions.confirm_archive_body'
            })
          })
        }
        disabled={
          item?.state?.value === 'Archived' ||
          item?.type !== MAP_DELIVERY_TYPES.RTO
        }
      >
        {intl.formatMessage({ id: 'shipments.actions.archive_order' })}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          openModal(CreateTicketModal, { delivery: item });
        }}
      >
        {intl.formatMessage({ id: 'support_tickets.title' })}
      </Menu.Item>
      <Divider className={'action-divider'} />
      <Menu.Item
        className={'destructive'}
        onClick={() => {
          options.handleAddCompensationClick(item);
        }}
      >
        {intl.formatMessage({ id: 'shipments.actions.request_compensation' })}
      </Menu.Item>
      {isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ? (
        <Menu.Item
          className={'destructive'}
          onClick={() => {
            if (item.type !== 'CASH_COLLECTION') {
              openModal(LostOrDamagedModal, {
                delivery: item,
                allHubs: hubs,
                stars: stars,
                formatOptions,
                confirmEndPoint: markAsLostOrDamaged
              });
            } else {
              notify(
                'You can not mark delivery of type "Cash Collection" as lost or damaged!'
              );
            }
          }}
        >
          {intl.formatMessage({
            id: 'shipments.actions.lost_or_damaged'
          })}
        </Menu.Item>
      ) : null}
      {isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ? (
        <Menu.Item
          className={'destructive'}
          onClick={() => {
            options.setProposedTerminateDelivery(item);
            options.setOpenTerminateModal(true);
          }}
        >
          {intl.formatMessage({ id: 'shipments.actions.terminate' })}
        </Menu.Item>
      ) : null}{' '}
    </Menu>
  );
};

/**
 * format address string
 * @param addressItems
 * @returns {*}
 */
const formatAddress = (addressItems) =>
  addressItems.reduce((acc, value) => {
    if (!acc) {
      return value;
    }
    if (value) {
      return `${acc}, ${value}`;
    }
    return acc;
  }, '');

/**
 * format data to bound in the table
 * @param data
 * @param options
 * @returns {*}
 */
const formatData = (data, options, hubs, stars) => {
  const { DELIVER, EXCHANGE } = ORDER_TYPES_VALUE;
  const { intl } = options;
  return data.map((item) => {
    if (item.type === 'INTERNATIONAL') item.type = 'SEND';
    let receiverDetails;
    if (item.type === 'RTO' || item.type === 'CUSTOMER_RETURN_PICKUP') {
      receiverDetails = item.receiver
        ? `${item.receiver?.name || ''} ${item.receiver?.phone || ''}`
        : '-';
    } else {
      receiverDetails = item.receiver
        ? `${item.receiver.firstName || ''} ${item.receiver.lastName || ''} ${
            item.receiver.phone || ''
          }`
        : '-';
    }
    let business;
    if (!isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER])) {
      business = `${getValue(item, 'sender._id', '')}`;
    } else {
      business = `${getValue(item, 'sender.name', '')} ${getValue(
        item,
        'sender.phone',
        ''
      )}`;
    }
    let pickupAddress;
    if (
      !isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
      (item.type === DELIVER || item.type.includes(EXCHANGE))
    ) {
      pickupAddress = '-';
    } else {
      pickupAddress = formatAddress([
        getValue(item, 'pickupAddress.buildingNumber', ''),
        getValue(item, 'pickupAddress.firstLine', ''),
        getValue(item, 'pickupAddress.secondLine', ''),
        getValue(item, 'pickupAddress.district.name', ''),
        getValue(item, 'pickupAddress.zone.name', ''),
        getValue(item, 'pickupAddress.city.name', '')
      ]);
    }

    const timeDiff = dayjs
      .duration(
        dayjs(new Date(item.sla?.e2eSla?.e2eSlaTimestamp)).diff(
          dayjs(new Date())
        )
      )
      .days();
    const nearToSLAPeriod =
      item?.state?.value !== 'Delivered' && timeDiff >= -2 && timeDiff <= 0;
    return {
      ...item,
      key: item._id,
      id: item._id,
      business,
      trackingNumber: (
        <div className="deliveries-tracking-number__container">
          <Link
            to={`/deliveries/${item._id}/details`}
            style={{ textDecoration: 'underline' }}
            target="_blank"
          >
            {item.trackingNumber}
          </Link>
          {item.isFulfillmentOrder && (
            <div className="fulfillment-orders__flag">
              {intl.formatMessage({
                id: 'fulfillment.fulfillment_flag'
              })}
            </div>
          )}
          {item?.integrationInfo?.provider === PUDO_PROVIDER && (
            <div className="fulfillment-orders__flag">PUDO</div>
          )}
          {item?.creationSrc === MARKETPLACE && (
            <Tooltip
              title={intl.formatMessage({
                id: 'shipments.order_created_from_sllr'
              })}
              getPopupContainer={(trigger) =>
                trigger.parentElement.parentElement.parentElement.parentElement
              }
              placement="top"
              autoAdjustOverflow={true}
              br-orders_sllr-order-icon={['hover', 'click']}
              overlayClassName="br-orders_sllr-order-tooltip"
            >
              <SllrOrder className="br-orders_sllr-order-icon" />
            </Tooltip>
          )}
          {item?.internationalTrackingNumber && (
            <div className="br-orders__international-order-icon">
              <InternationalOrdersTag />
            </div>
          )}
        </div>
      ),
      createdAt: dayjs(new Date(item.createdAt)).tz().format('LLL'),
      confirmedDeliveryAt: item.confirmedDeliveryAt
        ? dayjs(new Date(item.confirmedDeliveryAt)).tz().format('LLL')
        : 'N/A',
      pickedUpAt: item.collectedFromBusiness
        ? dayjs(new Date(item.collectedFromBusiness)).tz().format('LLL')
        : 'N/A',
      updatedAt: item.updatedAt
        ? dayjs(new Date(item.updatedAt)).tz().format('LLL')
        : 'N/A',
      e2eSLADate: item.sla?.e2eSla?.e2eSlaTimestamp ? (
        <span
          className={`p-1 ${
            item.sla?.e2eSla?.isExceededE2ESla
              ? styles.exceedSLA
              : nearToSLAPeriod
              ? styles.nearToSLA
              : ''
          }`}
        >
          {dayjs(new Date(item.sla?.e2eSla?.e2eSlaTimestamp)).tz().format('LL')}
        </span>
      ) : (
        'N/A'
      ),
      orderSLADate: item.sla?.orderSla?.orderSlaTimestamp ? (
        <span
          className={`p-1 ${
            item.sla?.orderSla?.isExceededOrderSla ? styles.exceedSLA : ''
          }`}
        >
          {dayjs(new Date(item.sla?.orderSla?.orderSlaTimestamp))
            .tz()
            .format('LL')}
        </span>
      ) : (
        'N/A'
      ),
      scheduledAt: item.scheduledAt
        ? dayjs(new Date(item.scheduledAt)).tz().format('LL')
        : 'N/A',
      merchantName: item?.merchantName || 'N/A',
      state: (
        <>
          {item.state ? (
            <Tag className={deliveryStateFormatter.getStateColor(item)}>
              {deliveryStateFormatter.getStateName(item)}
            </Tag>
          ) : (
            'N/A'
          )}
          <p>Attempts: {item.attemptsCount || 0}</p>
        </>
      ),
      attemptsVal: item.attemptsCount || 0,
      stateVal: item.state ? (
        <Tag className={deliveryStateFormatter.getStateColor(item)}>
          {deliveryStateFormatter.getStateName(item)}
        </Tag>
      ) : (
        'N/A'
      ),
      warehouseTransit: item.warehouseTransit
        ? `In Transit to: ${item.warehouseTransit?.destinationWarehouse?.name}`
        : 'N/A',
      // item.warehouseTransit !== null ? item.warehouseTransit : false,
      hub: item.state?.receivedAtWarehouse?.warehouse?.name || 'N/A',
      locationInHub: item.state?.receivedAtWarehouse?.location || 'N/A',
      assignedHub: item.assignedHub?.name || 'N/A',
      type: formatType(item),
      linkedDelivery: getValue(item, 'linkedDelivery.trackingNumber', '--'),
      receiverDetails,
      pickupAddress,
      dropOffAddress: formatAddress([
        getValue(item, 'dropOffAddress.buildingNumber', ''),
        getValue(item, 'dropOffAddress.firstLine', ''),
        getValue(item, 'dropOffAddress.secondLine', ''),
        getValue(item, 'dropOffAddress.district.name', ''),
        getValue(item, 'dropOffAddress.zone.name', ''),
        getValue(item, 'dropOffAddress.city.name', '')
      ]),
      cancelledAt: item.cancelledAt
        ? dayjs(new Date(item.cancelledAt)).tz().format('LLL')
        : 'N/A',
      cod:
        item.cod && item.cod.amount
          ? `${intl.formatMessage(
              {
                id: COUNTRY_CURRENCY
              },
              { cod: item.cod.amount }
            )} `
          : 'N/A',
      starInfo: item.star ? `${item.star.name}, ${item.star.phone}` : 'N/A',
      // holderInfo: item.holder ? `${item.holder.name}, ${item.holder.phone}` : "",
      priceBeforeVat: item?.pricing
        ? ` ${intl.formatMessage(
            {
              id: COUNTRY_CURRENCY
            },
            { cod: item?.pricing?.priceBeforeVat }
          )}`
        : 'N/A',
      priceAfterVat: item?.pricing
        ? ` ${intl.formatMessage(
            {
              id: COUNTRY_CURRENCY
            },
            { cod: item?.pricing?.priceAfterVat }
          )}`
        : 'N/A',
      businessRefNumber: item.businessReference || 'N/A',
      notes: item.notes ? item.notes : 'N/A',
      comments: item.comments ? item.comments : 'N/A',
      action: (
        <Dropdown
          overlay={actionsMenu(item, options, hubs, stars)}
          trigger={['click']}
        >
          <MoreOutlined />
        </Dropdown>
      )
    };
  });
};

/**
 * Date range picker used in search form
 * @param name
 * @param label
 * @param handleChange
 * @param value
 * @returns {*}
 * @constructor
 */
const DateRangePicker = ({ name, label, handleChange, value }) => {
  const handleDateRangeChange = (dates, dateStrings) => {
    if (dates && dates.length > 0) handleChange(`${name}`, dateStrings, true);
    else {
      handleChange(`${name}`, [null, null], true);
    }
  };

  return (
    <>
      <Form.Group as={Col} sm={3} controlId={`${name}-control`}>
        <Form.Label className={styles.formLabel}>{label}</Form.Label>
        <Calendar
          handleChange={handleDateRangeChange}
          placeholder={['', '']}
          value={value[0] !== null ? [dayjs(value[0]), dayjs(value[1])] : null}
          size="medium"
        />
      </Form.Group>
    </>
  );
};

/**
 * Cancel delivery reason and confirmation modal
 * @param show
 * @param cancel
 * @param confirm
 * @returns {*}
 * @constructor
 */
const CancelDeliveryReasonModal = ({ show, cancel, confirm }) => {
  const [reason, setReason] = useState('');
  return (
    <>
      <Modal show={show} onHide={cancel} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Delivery Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please add reason for why to cancel this delivery</p>
          <input
            type="text"
            className="form-control"
            required
            onChange={(evt) => setReason(evt.currentTarget.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="secondaryButton mt-0"
            onClick={cancel}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="primaryButton mt-0"
            disabled={reason === ''}
            onClick={() => {
              confirm(reason);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

/**
 * Lost or Damaged change state modal with related data (users or hubs)
 * @param show
 * @param cancel
 * @param confirm
 * @param options
 * @returns {*}
 * @constructor
 */
const LostOrDamagedDeliveryModal = ({
  show,
  cancel,
  confirm,
  options = { stars: [], hubs: [] }
}) => {
  const [type, setType] = useState(null);
  const [takenBy, setTakenBy] = useState(null);
  const [data, setData] = useState(null);

  return (
    <>
      <Modal show={show} onHide={cancel} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Lost Or Damaged</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Choose Type</p>
          <Select
            className="mb-2"
            value={type}
            required
            onChange={(evt) => {
              setType(evt);
              setData(null);
            }}
            placeholder="Select a type"
            options={formatOptions(null, null, ['Lost', 'Damaged'])}
          />
          <Select
            className="mb-2"
            value={takenBy}
            required
            onChange={(evt) => {
              setTakenBy(evt);
              setData(null);
            }}
            placeholder="Action taken by"
            options={formatOptions(null, null, ['Hub', 'Star'])}
          />
          {takenBy && (
            <Select
              value={data}
              required
              onChange={(evt) => {
                setData(evt);
              }}
              placeholder={`Select ${takenBy.value}`}
              options={formatOptions(
                '_id',
                takenBy?.value === 'Star'
                  ? 'profile.firstName,profile.lastName'
                  : 'name',
                takenBy?.value === 'Star' ? options.stars : options.hubs
              )}
              isClearable
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="secondaryButton mt-0"
            onClick={() => {
              cancel();
              setType(null);
              setTakenBy(null);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="primaryButton mt-0"
            disabled={!type || !data}
            onClick={() => {
              confirm(type.value, {
                starId: takenBy.value === 'Star' ? data.value : undefined,
                warehouseId: takenBy.value !== 'Star' ? data.value : undefined
              });
              setType(null);
              setTakenBy(null);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

/**
 * general purpose confirmation modal
 * @param show
 * @param cancel
 * @param confirm
 * @param title
 * @param body
 * @param disableConfirm
 * @param confirmText
 * @returns {*}
 * @constructor
 */
const ConfirmationModal = ({
  show,
  cancel,
  confirm,
  title,
  body,
  disableConfirm,
  confirmText = 'Confirm'
}) => (
  <>
    <Modal show={show} onHide={cancel} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="secondaryButton mt-0"
          onClick={cancel}
        >
          Close
        </Button>
        <Button
          variant="primary"
          className="primaryButton mt-0"
          onClick={() => {
            confirm();
          }}
          disabled={
            typeof disableConfirm !== 'undefined' ? !disableConfirm : false
          }
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
);

/**
 * add comment and confirmation modal
 * @param show
 * @param cancel
 * @param confirm
 * @returns {*}
 * @constructor
 */
const AddCommentToDeliveryModal = ({ show, cancel, confirm }) => {
  const [comment, setComment] = useState('');
  return (
    <>
      <Modal show={show} onHide={cancel} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please add comment to this delivery</p>
          <input
            type="text"
            className="form-control"
            required
            onChange={(evt) => setComment(evt.currentTarget.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="secondaryButton mt-0"
            onClick={cancel}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="primaryButton mt-0"
            disabled={comment === ''}
            onClick={() => {
              confirm(comment);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Deliveries = (props) => {
  const {
    fetchHubs,
    fetchDeliveries,
    deliveries,
    loading,
    history,
    printAirWayBill,
    clearBill,
    airWayBill,
    cancelDeliveryWithReason,
    addCommentToDelivery,
    rerequestDelivery,
    fetchStars,
    // fetchActiveRoutes,
    // activeRoutes,
    // returnDeliveriesToBusiness,
    // addToRoute,
    terminateDeliveries,
    resetDelivery,
    stars,
    hubs,
    intl,
    mobileScreenSizes
  } = props;
  const [formValues, setFormValues] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [selectedInternationalIds, setSelectedInternationalIds] = useState([]);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openCancelMultiModal, setOpenCancelMultiModal] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [openExceptionModal, setOpenExceptionModal] = useState(false);
  const [openLostModal, setOpenLostModal] = useState(false);
  const [openRerequestModal, setOpenRerequestModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [openRestartModal, setOpenRestartModal] = useState(false);
  const [openTerminateModal, setOpenTerminateModal] = useState(false);
  const [openRedispatchModal, setOpenRedispatchModal] = useState(false);
  // const [openAssignRouteModal, setOpenAssignRouteModal] = useState(false);
  const [proposedCancelledDelivery, setProposedCancelledDelivery] =
    useState(null);
  const [proposedResetDelivery, setProposedResetDelivery] = useState(null);
  const [proposedRestartDelivery, setProposedRestartDelivery] = useState(null);
  const [proposedRedispatchDelivery, setProposedRedispatchDelivery] =
    useState(null);
  const [proposedTerminateDelivery, setProposedTerminateDelivery] =
    useState(null);
  const [proposedCommentedDelivery, setProposedCommentedDelivery] =
    useState(null);
  const [proposedExceptionDelivery, setProposedExceptionDelivery] =
    useState(null);
  const [proposedLostDelivery, setProposedLostDelivery] = useState(null);
  const [proposedRerequestedDelivery, setProposedRerequestedDelivery] =
    useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  // const [assignedRoute, setAssignedRoute] = useState(null);
  const [visible, setVisible] = useState(false);
  const [deliveriesCount, setDeliveriesCount] = useState(0);
  const [isDeliveriesCountLoading, setIsDeliveriesCountLoading] =
    useState(false);
  const [showDeliveriesCount, setShowDeliveriesCount] = useState(false);

  const { isMediumMobileScreen } = mobileScreenSizes;

  const getCurrentFilter = (values) => {
    const searchValues = values || formValues;
    return omitBy({ ...searchValues /* state */ }, (value) => {
      if (
        (typeof value === 'string' && value === '') ||
        value === null ||
        value === undefined
      ) {
        return true;
      }
      return Array.isArray(value) && value.length === 0;
    });
  };

  const formatCurrentFilterBeforeSearch = (
    selectedFieldsFilter,
    values = null
  ) => {
    // Handle Id
    if (selectedFieldsFilter.id) {
      if (selectedFieldsFilter.businessId) {
        selectedFieldsFilter.businessId.push(selectedFieldsFilter.id);
      } else {
        selectedFieldsFilter.businessId = [selectedFieldsFilter.id];
      }
      delete selectedFieldsFilter.id;
    } else if (selectedFieldsFilter.businessId) {
      selectedFieldsFilter.businessId = selectedFieldsFilter.businessId.filter(
        (item) => item !== formValues.id
      );

      if (values) {
        values.businessId = values.businessId.filter(
          (item) => item !== formValues.id
        );
      }
    }

    // handle state codes
    if (selectedFieldsFilter.stateCodes?.length) {
      const newStateCodes = [];
      selectedFieldsFilter.stateCodes.map((state) => {
        if (typeof state === 'string') {
          newStateCodes.push(state);
        } else {
          newStateCodes.push(...state);
        }
      });
      selectedFieldsFilter.stateCodes = newStateCodes;
    }

    for (const key in selectedFieldsFilter) {
      if (
        dayjs(selectedFieldsFilter[key]).isValid() &&
        DATE_PAYLOAD.includes(key)
      ) {
        selectedFieldsFilter[key] = FORMAT_DATE_TO_UTC(
          selectedFieldsFilter[key]
        );
      }
    }
    return selectedFieldsFilter;
  };

  const refreshTable = async () => {
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    try {
      await fetchDeliveries({
        ...selectedFieldsFilter,
        ...deliveries.pagination
      });
    } catch (error) {
      notify(error.message);
    }
  };

  const cancelDeliveryAsUncoveredZone = async (ids) => {
    const deliveries = ids?.map((_id) => {
      return { _id };
    });
    try {
      await cancelDeliveryWithReason({
        deliveries,
        reason: UNDERCOVERED_ZONE
      });
      await refreshTable();
    } catch (e) {
      const {
        response: { data: message }
      } = e;
      notify(message.message);
      return null;
    }
  };
  const resetSearchForm = () => {
    setFormValues({});
    // setType(null);
    fetchDeliveries({
      ...deliveries.pagination
    });
    setShowDeliveriesCount(false);
  };

  const handleResetSelection = () => {
    setSelectedRowsIds([]);
    setSelectedInternationalIds([]);
  };

  const handleSearchFormSubmit = (values) => {
    const {
      trackingNumbers,
      barcodeRef,
      internationalTrackingNumbers,
      stateCodes,
      ...restValues
    } = values;
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter({
        trackingNumbers: trackingNumbers?.trim().split(/[, ]+/).join(','),
        barcodeRef: barcodeRef?.trim().split(/[, ]+/).join(','),
        internationalTrackingNumbers: internationalTrackingNumbers
          ?.trim()
          .split(/[, ]+/)
          .join(','),
        stateCodes: stateCodes,
        ...restValues
      }),
      values
    );

    setFormValues(selectedFieldsFilter);

    if (deliveries && deliveries.pagination) {
      deliveries.pagination.pageNumber = 1;
    }

    handleResetSelection();
    setShowDeliveriesCount(false);
    fetchDeliveries({
      ...selectedFieldsFilter,
      ...deliveries.pagination
    });
    /**
     * handling submit
     */
  };

  const confirmCancelDelivery = (reason, ids) => {
    cancelDeliveryWithReason({ ids, reason });
    handleResetSelection();

    refreshTable();
  };

  const confirmAddCommentToDelivery = (comment) => {
    const { _id } = proposedCommentedDelivery;
    addCommentToDelivery({ id: _id, comment });
    setOpenCommentModal(false);
    handleResetSelection();
    setProposedCommentedDelivery(null);

    refreshTable();
  };

  const confirmRerequestDelivery = () => {
    const { _id } = proposedRerequestedDelivery;
    rerequestDelivery({ id: _id });
    setOpenRerequestModal(false);
    handleResetSelection();
    setProposedRerequestedDelivery(null);

    refreshTable();
  };

  const confirmResetDelivery = () => {
    const { _id } = proposedResetDelivery;
    resetDelivery({ id: _id });
    setOpenResetModal(false);
    handleResetSelection();
    setProposedResetDelivery(null);

    refreshTable();
  };

  const confirmRestartDelivery = async () => {
    const { _id } = proposedRestartDelivery;
    await restartDeliveredDelivery({ id: _id });
    setOpenRestartModal(false);
    setProposedRestartDelivery(null);

    refreshTable();
  };

  const confirmReDispatchDelivery = async () => {
    const { _id } = proposedRedispatchDelivery;
    await redispatchRTODelivery({ id: _id });
    setOpenRedispatchModal(false);
    setProposedRedispatchDelivery(null);

    refreshTable();
  };
  // const confirmAssignToRoutes = () => {
  //   const { value } = assignedRoute;
  //   addToRoute({ routeId: value, ids: selectedRowsIds });
  //   setOpenAssignRouteModal(false);
  //   setSelectedRowsIds([]);
  //   const selectedFieldsFilter = getCurrentFilter();
  //   fetchDeliveries({ ...selectedFieldsFilter, ...deliveries.pagination });
  // };
  const confirmTerminate = async () => {
    if (selectedRowsIds.length > 0)
      await terminateDeliveries({ ids: selectedRowsIds });
    else if (proposedTerminateDelivery) {
      await terminateDeliveries({ ids: [proposedTerminateDelivery._id] });
    } else {
      return;
    }
    setOpenTerminateModal(false);
    handleResetSelection();

    refreshTable();
  };
  const confirmLostOrDamaged = async (type, data) => {
    try {
      const { _id } = proposedLostDelivery;
      await markAsLostOrDamaged({
        deliveryId: _id,
        type,
        ...data
      });
      notify(`Delivery marked as "${type}" successfully.`, 'success');
    } catch (e) {
      notify(e.response?.data?.message || e.message);
    }
  };

  const handleReturnToBusiness = async ({ deliveries, returnType }) => {
    try {
      const payload = {
        updateType: DELIVERY_UPDATE_TYPE.RETURN,
        returnType,
        deliveries
      };
      await updateManyDeliveries(payload);
      handleResetSelection();
      refreshTable();
      notify(
        fmt({
          id: 'shipments.actions.changed_to_rto_successfully'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const handleReturnToBusinessClick = () => {
    const domesticDeliveries = selectedRowsIds
      .filter((deliveryId) => !selectedInternationalIds.includes(deliveryId))
      .map((deliveryId) => ({
        _id: deliveryId
      }));

    if (selectedInternationalIds.length) {
      const internationalDeliveries = selectedRowsIds
        .filter((deliveryId) => selectedInternationalIds.includes(deliveryId))
        .map((deliveryId) => ({
          _id: deliveryId
        }));

      openModal(BRDropdownModal, {
        title: 'Confirm changing selected deliveries to RTO',
        dropdownData: INTERNATIONAL_ORDERS_RETURN_TYPES,
        dropdownPlaceholder: 'Return Type',
        confirmBtnText: intl.formatMessage({ id: 'common.confirm' }),
        onConfirm: (returnType) => {
          if (domesticDeliveries.length) {
            handleReturnToBusiness({ deliveries: domesticDeliveries });
          }
          handleReturnToBusiness({
            deliveries: internationalDeliveries,
            returnType
          });
        }
      });
    } else {
      openModal(ConfirmationModalComponent, {
        title: 'Confirm changing selected deliveries to RTO',
        content: 'Please confirm changing selected deliveries to RTO',
        confirmButtonLabel: intl.formatMessage({ id: 'common.confirm' }),
        confirmButtonType: 'primary',
        modalAction: () =>
          handleReturnToBusiness({ deliveries: domesticDeliveries })
      });
    }
  };

  const rowSelection = {
    /**
     * on checkbox state change for each row
     */
    selectedRowKeys: selectedRowsIds,
    onChange: (selectedRowKeys, record) => {
      const selectedInternationalDeliveries = record.filter(
        (delivery) =>
          delivery.internationalTrackingNumber &&
          selectedRowKeys.includes(delivery._id)
      );

      if (selectedInternationalDeliveries.length) {
        setSelectedInternationalIds(
          selectedInternationalDeliveries.map((delivery) => delivery._id)
        );
      } else {
        setSelectedInternationalIds([]);
      }

      setSelectedRowsIds([...selectedRowKeys]);
    }
    /**
     * get check box related data row item, this hook can facilitate disabling rows checking
     */
    /* getCheckboxProps: record => {
      return {
      };
    } */
  };
  const handleTableChange = (pagingOptions) => {
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    fetchDeliveries({
      ...deliveries.pagination,
      pageNumber: pagingOptions.current,
      pageLimit: pagingOptions.pageSize,
      ...selectedFieldsFilter
    });
  };

  const rowsSelectionRequired = (fn) => () => {
    const isSelected = selectedRowsIds.length > 0;
    if (!isSelected) {
      notify('No rows selected.');
      return;
    }
    fn();
  };

  /*  const filterRequired = fn => () => {
  const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    if (Object.entries(selectedFieldsFilter).length === 0) {
      notify("You should at least use one filter to export data.", "error");
      return;
    }
    fn(selectedFieldsFilter);
  }; */
  const handleDownloadXlsx = async (data) => {
    try {
      const res = await exportXlsx(data);
      notify(res.msg, 'success');
    } catch (e) {
      notify(e.message);
    } finally {
      setDownloadLoading(false);
    }
  };

  const handleExportXlsx = async () => {
    setDownloadLoading(true);
    if (selectedRowsIds.length === 0) {
      const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
        getCurrentFilter()
      );

      if (selectedFieldsFilter?.trackingNumbers) {
        const trackingNumbers = selectedFieldsFilter.trackingNumbers
          ?.trim()
          .split(/[, ]+/)
          .join(',');
        const data = {
          ...selectedFieldsFilter,
          trackingNumbers
        };
        return handleDownloadXlsx(data);
      }

      handleDownloadXlsx({ ...selectedFieldsFilter });
    } else {
      const trackingNumbers = deliveries.data
        .filter((el) => selectedRowsIds.includes(el._id))
        .map((el) => el.trackingNumber)
        .toString();
      handleDownloadXlsx({ trackingNumbers });
    }
  };

  // TEMP
  const handlePrintCombinedAWB = async ({
    requestedAwbType,
    isCombinedAWB
  }) => {
    handleVisibleChange(false);
    setDownloadLoading(true);
    try {
      const payload = {
        ids: selectedRowsIds.join(','),
        isCombinedAWB,
        requestedAwbType
      };

      const { data, message } = await printMassAirwayBills(payload);

      if (data) {
        showBill(data);
      } else {
        notify(message, 'success');
      }
    } catch (error) {
      notify(error.message);
    }
    setDownloadLoading(false);
  };

  const handleMenuClick = (e) => {
    if (e.key === 'reset') {
      setVisible(false);
    }
  };
  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const MobileCardsRows = [
    {
      dataIndex: 'trackingNumber',
      render: (trackingNumber, { stateVal }) => {
        return (
          <div className="br-table-mobile-card__row">
            <span className="br-table-mobile-card__trackingNumber">
              {trackingNumber}
            </span>
            <span className="br-table-mobile-card__order-type">{stateVal}</span>
          </div>
        );
      }
    },
    {
      dataIndex: 'business',
      render: (business) => {
        return (
          <div className="br-table-mobile-card__row">
            <span className="br-table-mobile-card__business display-flex">
              <span className="br-table-mobile-card__title">BusinessName:</span>
              {business}
            </span>
          </div>
        );
      }
    },
    {
      dataIndex: 'type',
      render: (type, { attemptsVal }) => {
        return (
          <div className="br-table-mobile-card__row">
            <span className="br-table-mobile-card__order-type">
              <span className="br-table-mobile-card__title">Type</span>
              {type}
            </span>
            <span>
              <span className="br-table-mobile-card__title">Attempts: </span>
              {attemptsVal}
            </span>
          </div>
        );
      }
    },
    {
      dataIndex: 'receiverDetails',
      render: (receiverDetails) => {
        return (
          <div className="br-table-mobile-card__row">
            <span className="br-table-mobile-card__business display-flex">
              <span className="br-table-mobile-card__title">
                Receiver Details:
              </span>
              {receiverDetails}
            </span>
          </div>
        );
      }
    },
    {
      dataIndex: 'pickupAddress',
      render: (pickupAddress) => {
        return (
          <div className="br-table-mobile-card__row">
            <span className="br-table-mobile-card__title">Pickup Address</span>
            {pickupAddress}
          </div>
        );
      }
    },
    {
      dataIndex: 'dropOffAddress',
      render: (dropOffAddress) => {
        return (
          <div className="br-table-mobile-card__row">
            <span className="br-table-mobile-card__title">Dropoff Address</span>
            {dropOffAddress}
          </div>
        );
      }
    },
    {
      dataIndex: 'cod',
      render: (cod, { action }) => {
        return (
          <div className="br-table-mobile-card__row">
            <span>
              <span className="br-table-mobile-card__title">COD</span>
              {cod}
            </span>
            <span className="br-table-mobile-card__action">{action}</span>
          </div>
        );
      }
    }
  ];

  const handleCardSelection = (selectedCards) => {
    setSelectedRowsIds(selectedCards);
  };

  const ActionsMenu = ({ options }) => {
    return (
      <Menu
        onClick={handleMenuClick}
        className="br-deliveries__bulk-actions__menu"
      >
        <Menu.Item disabled={downloadLoading} onClick={handleExportXlsx}>
          Export Xlsx
        </Menu.Item>
        <Menu.Item
          disabled={loading}
          onClick={rowsSelectionRequired(() => {
            options.printAirWayBill({ ids: selectedRowsIds });
          })}
        >
          Print airway bill
        </Menu.Item>
        {(isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ||
          isDSPVendor()) && (
          <Menu.Item
            onClick={rowsSelectionRequired(() => {
              cancelDeliveryAsUncoveredZone(selectedRowsIds);
            })}
          >
            Uncovered Zone
          </Menu.Item>
        )}
        <Menu.Item
          disabled={
            !isUserAuthorized(aclMatrix.DELIVERIES_EDIT, [
              ACL_MATRIX.DELIVERY_MARK_AS_RECEIVED_BY_MISTAKE
            ])
          }
          onClick={() =>
            handleMarkDeliveryAsBadAddressClick({
              deliveriesIds: selectedRowsIds
            })
          }
        >
          {intl.formatMessage({ id: 'shipments.actions.bad_address' })}
        </Menu.Item>
        {isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ? (
          <Menu.Item
            onClick={rowsSelectionRequired(() => {
              handleReturnToBusinessClick();
            })}
          >
            Return to business
          </Menu.Item>
        ) : null}
        {isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ? (
          <Menu.Item
            onClick={rowsSelectionRequired(() => {
              openModal(ConfirmationModalComponent, {
                title: intl.formatMessage({
                  id: 'shipments.actions.confirm_reset_title'
                }),
                content: intl.formatMessage({
                  id: 'shipments.actions.confirm_reset_content'
                }),
                confirmButtonLabel: intl.formatMessage({
                  id: 'common.confirm'
                }),
                modalAction: () => options.resetDeliveries(selectedRowsIds)
              });
            })}
            key="reset"
          >
            Reset
          </Menu.Item>
        ) : null}
        {/* <Menu.Item
          onClick={rowsSelectionRequired(() => {
            setOpenCancelMultiModal(true);
          })}
        >
          Cancel
        </Menu.Item> */}
        {isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) ? (
          <Menu.Item
            onClick={rowsSelectionRequired(() => {
              setOpenTerminateModal(true);
            })}
          >
            Terminate
          </Menu.Item>
        ) : null}
        {isUserAuthorized(MANUAL_ASSIGN_HUB_ACCESS, [
          ACL_MATRIX.DELIVERY_ASSIGN_TO_HUB
        ]) && (
          <Menu.Item
            disabled={loading}
            onClick={rowsSelectionRequired(() =>
              openModal(AssignHubModal, {
                fetchedHubs: hubs,
                onConfirm: (selectedHubId) =>
                  handleAssignMultiDeliveriesToHub({
                    intl,
                    deliveryIds: selectedRowsIds,
                    selectedHubId,
                    deliveries: deliveries?.data
                  })
              })
            )}
          >
            {intl.formatMessage({ id: 'shipments.actions.assign_hub' })}
          </Menu.Item>
        )}
        <Menu.SubMenu
          title="Print Options"
          disabled={downloadLoading}
          icon={<ChevronLeft />}
          popupClassName="br-deliveries__print-options__menu"
        >
          {PRINTING_OPTIONS.map(({ label, value, isCombinedAWB, icon }) => (
            <Menu.Item
              key={value}
              onClick={rowsSelectionRequired(() =>
                handlePrintCombinedAWB({
                  requestedAwbType: value,
                  isCombinedAWB
                })
              )}
            >
              {icon}
              {label}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      </Menu>
    );
  };

  if (airWayBill) {
    showBill(airWayBill.data);
    clearBill();
  }

  useEffect(() => {
    fetchDeliveries({
      pageLimit: deliveries?.pagination?.pageLimit || tablePageSize,
      pageNumber: deliveries?.pagination?.pageNumber || 1
    });
  }, [fetchDeliveries]);
  useEffect(() => {
    fetchHubs();
  }, [fetchHubs]);
  useEffect(() => {
    fetchStars();
  }, [fetchStars]);
  /* useEffect(() => {
      fetchActiveRoutes();
    }, [fetchActiveRoutes]); */

  const handleAddCompensationClick = (delivery) => {
    openModal(AddCompensationFormModal, {
      delivery
    });
  };

  const handleGoToOFDSearch = () => {
    history.push('/hubs/monitor-hub-operations/ofd-search');
  };

  const getDeliveriesCount = async () => {
    setIsDeliveriesCountLoading(true);
    const selectedFieldsFilter = formatCurrentFilterBeforeSearch(
      getCurrentFilter()
    );
    try {
      const { deliveriesCount } = await fetchDeliveriesCount({
        ...selectedFieldsFilter
      });
      setDeliveriesCount(deliveriesCount);
      setShowDeliveriesCount(true);
    } catch (error) {
      notify(error.message);
    }

    setIsDeliveriesCountLoading(false);
  };

  return (
    <Container
      className="br-deliveries__container"
      header={
        <BRHeader
          title="Deliveries"
          handleGoBackUrl={() => history.push(`/`)}
        />
      }
      content={
        <div>
          {isUserAuthorized(
            aclMatrix.DISPATCHING,
            PAGES_ACCESS.HUBS_OPERATIONS_MONITORING
          ) && (
            <div className="br-deliveries-search__ofd-banner">
              <Banner
                title={intl.formatMessage({
                  id: 'monitor_hub_operations.ofd_search.tab_title'
                })}
                subText={intl.formatMessage({
                  id: 'monitor_hub_operations.ofd_search.ofd_banner.subtitle'
                })}
                buttonText={intl.formatMessage({
                  id: 'monitor_hub_operations.ofd_search.ofd_banner.action_button'
                })}
                buttonProps={{
                  onClick: handleGoToOFDSearch
                }}
              />
            </div>
          )}
          <DeliveriesSearch
            handleFormSubmit={handleSearchFormSubmit}
            handleResetForm={resetSearchForm}
            isLoading={loading}
            formatOptions={formatOptions}
            formatCurrentFilterBeforeSearch={formatCurrentFilterBeforeSearch}
            {...props}
          />
          <div className={`my-4 ${styles.actionsMenu}`}>
            Delivery Actions
            <Dropdown
              overlay={<ActionsMenu options={{ ...props }} />}
              trigger={['click']}
              onVisibleChange={handleVisibleChange}
              visible={visible}
            >
              <MoreOutlined />
            </Dropdown>
          </div>
          {/* <div className={styles.filtersSection}>
            <FilterStatus
              className={styles.filterBar}
              statusList={statusList}
              selected={state}
              onChange={(item) => {
                setState(item);
                fetchDeliveries(item ? { ...deliveries.pagination, state: item } : { ...deliveries.pagination });
              }}
            />
          </div> */}
          <div className="mx-1 mb-4">
            {deliveries.data.length > 0 && (
              <div className="br-deliveries__table-title">
                <b>Total Deliveries:</b>
                <span className="ml-2">
                  {showDeliveriesCount ? (
                    deliveriesCount
                  ) : (
                    <BRButton
                      label={intl.formatMessage({
                        id: 'pickups.pickup_request.show_count'
                      })}
                      loading={isDeliveriesCountLoading}
                      prefixIcon={<ClosedEye />}
                      onClick={getDeliveriesCount}
                    />
                  )}
                </span>
              </div>
            )}
            {isMediumMobileScreen ? (
              <MobileCards
                columns={MobileCardsRows}
                dataSource={formatData(
                  deliveries.data,
                  {
                    intl,
                    history,
                    printAirWayBill,
                    setProposedCancelledDelivery,
                    setProposedResetDelivery,
                    setProposedRestartDelivery,
                    setOpenCancelModal,
                    setProposedCommentedDelivery,
                    setOpenCommentModal,
                    setOpenExceptionModal,
                    setProposedExceptionDelivery,
                    setOpenRerequestModal,
                    setOpenResetModal,
                    setOpenRestartModal,
                    setProposedRerequestedDelivery,
                    setProposedTerminateDelivery,
                    setOpenTerminateModal,
                    setProposedLostDelivery,
                    setOpenLostModal,
                    setOpenRedispatchModal,
                    setProposedRedispatchDelivery,
                    cancelDeliveryAsUncoveredZone,
                    handleAddCompensationClick
                  },
                  hubs,
                  stars
                )}
                pagination={{
                  onChange: (page) =>
                    handleTableChange({
                      current: page,
                      pageSize:
                        deliveries?.pagination?.pageLimit || tablePageSize
                    }),
                  current: deliveries?.pagination?.pageNumber || 1,
                  total: deliveries?.count,
                  pageSize: deliveries?.pagination?.pageLimit || tablePageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '50', '100', '200']
                }}
                selectedCards={selectedRowsIds}
                setSelectedCards={handleCardSelection}
                isLoading={loading}
              />
            ) : (
              <Table
                rowSelection={rowSelection}
                style={{ overflow: 'auto' }}
                columns={deliveriesColumnsMapped}
                dataSource={formatData(
                  deliveries.data,
                  {
                    intl,
                    history,
                    printAirWayBill,
                    setProposedCancelledDelivery,
                    setProposedResetDelivery,
                    setProposedRestartDelivery,
                    setOpenCancelModal,
                    setProposedCommentedDelivery,
                    setOpenCommentModal,
                    setOpenExceptionModal,
                    setProposedExceptionDelivery,
                    setOpenRerequestModal,
                    setOpenResetModal,
                    setOpenRestartModal,
                    setProposedRerequestedDelivery,
                    setProposedTerminateDelivery,
                    setOpenTerminateModal,
                    setProposedLostDelivery,
                    setOpenLostModal,
                    setOpenRedispatchModal,
                    setProposedRedispatchDelivery,
                    cancelDeliveryAsUncoveredZone,
                    handleAddCompensationClick
                  },
                  hubs,
                  stars
                )}
                pagination={{
                  current: deliveries?.pagination?.pageNumber || 1,
                  total: deliveries?.count,
                  pageSize: deliveries?.pagination?.pageLimit || tablePageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['20', '50', '100', '200']
                }}
                // onRow={record => ({
                //   onClick: evt => {
                //     if (
                //       evt.target.tagName === 'svg' ||
                //       evt.target.classList.contains('ant-dropdown-menu-item')
                //     ) {
                //       evt.stopPropagation();
                //       return;
                //     }
                //     history.push(`/deliveries/${record.id}/details`);
                //   },
                // })}
                loading={loading}
                onChange={handleTableChange}
                scroll={{ x: 3500, y: 600 }}
              />
            )}

            <CancelDeliveryReasonModal
              show={openCancelModal}
              cancel={() => {
                setOpenCancelModal(false);
              }}
              confirm={(reason) => {
                const { _id } = proposedCancelledDelivery;
                confirmCancelDelivery(reason, [_id]);
                setProposedCancelledDelivery(null);
                setOpenCancelModal(false);
              }}
            />
            <AddCommentToDeliveryModal
              show={openCommentModal}
              cancel={() => {
                setOpenCommentModal(false);
              }}
              confirm={confirmAddCommentToDelivery}
            />

            <ConfirmationModal
              show={openRerequestModal}
              cancel={() => {
                setOpenRerequestModal(false);
              }}
              confirm={confirmRerequestDelivery}
              title="Confirm Re-Request"
              body="You are going to re-request this delivery, Are you sure?"
            />
            <ConfirmationModal
              show={openResetModal}
              cancel={() => {
                setOpenResetModal(false);
              }}
              confirm={confirmResetDelivery}
              title="Confirm Reset"
              body="You are going to Reset this delivery, Are you sure?"
            />
            <ConfirmationModal
              show={openRestartModal}
              cancel={() => {
                setOpenRestartModal(false);
              }}
              confirm={confirmRestartDelivery}
              title="Confirm Restart"
              body="You are going to Restart this delivery, Are you sure?"
            />
            {/* <ConfirmationModal
              show={openAssignRouteModal}
              cancel={() => {
                setOpenAssignRouteModal(false);
              }}
              confirm={confirmAssignToRoutes}
              title="Add to route"
              disableConfirm={!!assignedRoute}
              body={
                <Select
                  required
                  value={assignedRoute}
                  onChange={evt => setAssignedRoute(evt)}
                  placeholder="Select a route"
                  options={formatOptions('_id', 'name', activeRoutes)}
                  isClearable
                />
              }
            /> */}
            <ConfirmationModal
              show={openRedispatchModal}
              cancel={() => {
                setOpenRedispatchModal(false);
              }}
              confirm={confirmReDispatchDelivery}
              title="Confirm Redispatch"
              body="You are going to Re-dispatch this delivery, Are you sure?"
            />
            <ConfirmationModal
              show={openTerminateModal}
              cancel={() => {
                setOpenTerminateModal(false);
              }}
              confirm={confirmTerminate}
              title="Terminate Deliveries"
              body="Are you sure you want to terminate these orders. You won't be able to revert this!"
              confirmText="Yes, terminate orders!"
            />
            <CancelDeliveryReasonModal
              show={openCancelMultiModal}
              cancel={() => {
                setOpenCancelMultiModal(false);
              }}
              confirm={(reason) => {
                confirmCancelDelivery(reason, selectedRowsIds);
                setOpenCancelMultiModal(false);
              }}
            />
            <LostOrDamagedDeliveryModal
              show={openLostModal}
              cancel={() => {
                setOpenLostModal(false);
              }}
              confirm={async (type, data) => {
                await confirmLostOrDamaged(type, data);
                setOpenLostModal(false);
              }}
              options={{ stars: stars.data, hubs }}
            />
          </div>
        </div>
      }
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchDeliveries: (data) => dispatch(getAllDeliveries(data)),
  fetchHubs: () => dispatch(getAllHubs()),
  fetchCities: () => dispatch(getAllCities()),
  fetchZones: () => dispatch(getAllZones()),
  fetchDistricts: () => dispatch(getAllDistricts()),
  fetchStars: () => dispatch(getAllStars()),
  printAirWayBill: (data) => dispatch(printBill(data)),
  clearBill: () => dispatch(clearAirWayBill()),
  cancelDeliveryWithReason: (data) => dispatch(cancelDelivery(data)),
  addCommentToDelivery: (data) => dispatch(addDeliveryComment(data)),
  rerequestDelivery: (data) => dispatch(rerequestCancelledDelivery(data)),
  fetchActiveRoutes: () => dispatch(getAllActiveRoutes()),
  resetDeliveries: (ids) => dispatch(resetDeliveredDeliveries(ids)),
  returnDeliveriesToBusiness: (ids) => dispatch(returnToBusiness(ids)),
  addToRoute: (ids) => dispatch(addDeliveriesToRoute(ids)),
  terminateDeliveries: (ids) => dispatch(terminateDelivery(ids)),
  resetDelivery: (data) => dispatch(resetDeliveredDelivery(data))
});
const mapStateToProps = ({ deliveries }) => ({
  ...deliveries
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(mediaHook(Deliveries))));
