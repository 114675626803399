import { useIntl } from 'react-intl';

import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { isUserAuthorized } from 'utils/helpers';
import { openModal } from 'utils/modal';
import {
  confirmTeamMemeberInfoValidation,
  editTeamMemeberInfoValidation
} from 'services/business';

import BROTPCodeModal from 'components/BROTPCodeModal/BROTPCodeModal';
import EditPhoneEmailModal from 'components/Businesses/EditPhoneEmailModal/EditPhoneEmailModal';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as EditIcon } from 'assets/bosta-icons/Edit.svg';

const EditEmailButton = ({ item, refreshData }) => {
  const intl = useIntl();

  const hideButton =
    !isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
      ACL_MATRIX.BUSINESS_EDIT_TEAM_MEMBER_EMAIL
    ]) || !item?.profile;

  const handleEditEmail = () => {
    openModal(BROTPCodeModal, {
      title: intl.formatMessage({
        id: 'business_details.team_members.otp_screens.business_validation.edit_email.title'
      }),
      subtitle: intl.formatMessage(
        {
          id: 'business_details.team_members.otp_screens.business_validation.edit_email.subtitle'
        },
        {
          phone: <span>{item.profile.phone?.slice(-3)}</span>
        }
      ),
      confirmOTP: (otp) =>
        confirmTeamMemeberInfoValidation({
          _id: item._id,
          contact: 'email',
          otp
        }),
      generateOTP: () =>
        editTeamMemeberInfoValidation({
          _id: item._id,
          contact: 'email'
        }),
      onSuccess: () => {
        openModal(EditPhoneEmailModal, {
          newEmail: true,
          memeberId: item._id,
          refreshData
        });
      }
    });
  };

  if (hideButton) {
    return null;
  }

  return (
    <BRButton
      type="table-actions"
      prefixIcon={<EditIcon />}
      onClick={handleEditEmail}
    />
  );
};

export default EditEmailButton;
