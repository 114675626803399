import { useCallback, useEffect, useState } from 'react';

import { isUserAuthorized } from 'utils/helpers';
import { getPromotionsInfo } from 'utils/business-details';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { PromotionsCells } from 'constants/business-details';
import { getActivePromotions } from 'services/promotions';

import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';

const PromotionsInfo = ({ businessId }) => {
  const [loadingPromotionData, setLoadingPromotionData] = useState(false);
  const [promotionData, setPromotionData] = useState(undefined);

  const userHasPromotionsPermission = isUserAuthorized(
    aclMatrix.BUSINESSES_DETAILS,
    [ACL_MATRIX.PROMOTIONS_LIST]
  );

  const showPromotions = promotionData && userHasPromotionsPermission;

  const fetchPromotionsList = useCallback(async () => {
    setLoadingPromotionData(true);
    try {
      const {
        data: { promotions }
      } = await getActivePromotions({
        businessId,
        pageNumber: 0,
        pageSize: 1
      });

      setPromotionData(getPromotionsInfo(promotions));
    } catch (e) {
      notify(e.message);
    } finally {
      setLoadingPromotionData(false);
    }
  }, [businessId]);

  useEffect(() => {
    if (userHasPromotionsPermission) {
      fetchPromotionsList();
    }
  }, [fetchPromotionsList, userHasPromotionsPermission]);

  if (!showPromotions) {
    return null;
  }

  return (
    <LoadingWrapper loading={loadingPromotionData}>
      <BRInformationTable
        dataSource={promotionData}
        cells={PromotionsCells()}
        totalCol={3}
      />
    </LoadingWrapper>
  );
};

export default PromotionsInfo;
