import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import { fmt } from 'components/IntlWrapper/IntlWrapper';
import BRTag from 'components/BRTag/BRTag';
import { AttemptsCount } from 'components/OpsControl/WrongZone/components/AttemptsCount/AttemptsCount';

import { ReactComponent as FlagIcon } from 'assets/bosta-icons/flag-opaque.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/bosta-icons/Arrow-down.svg';

export const WRONG_ZONE_QUICK_FILTERS = [
  {
    label: fmt({ id: 'ops_control.wrong_zone.filters.new' }),
    value: 'NO_ACTION_TAKEN'
  },
  {
    label: fmt({ id: 'ops_control.wrong_zone.filters.hub_changed' }),
    value: 'REASSIGNED_TO_ANOTHER_HUB'
  },
  {
    label: fmt({ id: 'ops_control.wrong_zone.filters.no_change' }),
    value: 'KEEP_AT_HUB'
  }
];

export const TRACKING_NUMBERS = 'trackingNumbers';

export const WRONG_ZONE_COLUMNS = ({ renderActions, showNoChangeAction }) => [
  {
    dataIndex: 'tracking_number',
    title: fmt({
      id: 'ops_control.wrong_zone.table.headers.tracking_number'
    }),
    fixed: 'left',
    width: 150,
    render: (tracking_number) => (
      <Link
        to={`/deliveries/${tracking_number}/details`}
        className="br-order-actions-delivery-link"
        target="_blank"
      >
        {tracking_number}
      </Link>
    )
  },
  {
    dataIndex: 'source_hub_name',
    title: fmt({
      id: 'ops_control.wrong_zone.table.headers.source_hub'
    }),
    width: 145
  },
  {
    dataIndex: 'address_city_name',
    title: fmt({
      id: 'ops_control.wrong_zone.table.headers.address'
    }),
    width: 331,
    render: (
      address_city_name,
      { address_zone_name, address_first_line, address_second_line }
    ) => (
      <>
        <h3>
          {address_city_name}
          {address_zone_name ? `, ${address_zone_name}` : ''}
        </h3>
        <p>
          {address_first_line && address_first_line}
          {address_second_line ? `, ${address_second_line}` : ''}
        </p>
      </>
    )
  },
  {
    dataIndex: 'current_assigned_hub_name',
    title: fmt({
      id: 'ops_control.wrong_zone.table.headers.assigned_hub'
    }),
    width: 145,
    render: (
      current_assigned_hub_name,
      {
        action_hub_name,
        action_hub_id,
        current_assigned_hub_id,
        auto_assigned_hub_id
      }
    ) => {
      const hubWasManuallyAssigned =
        auto_assigned_hub_id &&
        ![current_assigned_hub_id, action_hub_id].includes(
          auto_assigned_hub_id
        );

      return (
        <>
          {action_hub_name ?? current_assigned_hub_name}

          {hubWasManuallyAssigned && (
            <Tooltip
              title={fmt({
                id: 'ops_control.wrong_zone.table.headers.manually_assigned_hub'
              })}
              className="br-ops-control-wrong-zone__changed_hub_flag"
            >
              <FlagIcon />
            </Tooltip>
          )}
        </>
      );
    }
  },
  {
    dataIndex: 'destination_hub_name',
    title: fmt({
      id: 'ops_control.wrong_zone.table.headers.transferred_to'
    }),
    width: 155
  },
  {
    dataIndex: 'aging',
    title: (
      <div className="br-ops-control-wrong-zone__aging">
        {fmt({
          id: 'ops_control.wrong_zone.table.headers.aging'
        })}
        <ArrowDownIcon />
      </div>
    ),
    width: 127,
    render: (aging) => (aging > 10 ? <BRTag color="red">{aging}</BRTag> : aging)
  },
  {
    dataIndex: 'transfers_count',
    title: fmt({
      id: 'ops_control.wrong_zone.table.headers.transfers'
    }),
    width: 127,
    render: (transfersCount) =>
      transfersCount ?? fmt({ id: 'common.empty_field' })
  },
  {
    dataIndex: 'attempts_count',
    title: fmt({
      id: 'ops_control.wrong_zone.table.headers.attempts'
    }),
    width: 88,
    render: (attempts_count) => <AttemptsCount attemptsCount={attempts_count} />
  },
  {
    dataIndex: 'user_name',
    title: fmt({
      id: 'ops_control.wrong_zone.table.headers.last_transferred_by'
    }),
    width: 331,
    render: (user_name, { user_role, user_hub_name }) => (
      <>
        <h3>{user_name.replace(user_role, '')}</h3>
        <p>
          {user_role.replace('_', ' ').toLowerCase()} - {user_hub_name}
        </p>
      </>
    )
  },
  {
    fixed: 'right',
    width: showNoChangeAction ? 231 : 130,
    title: fmt({
      id: 'ops_control.wrong_zone.table.headers.action'
    }),
    render: renderActions
  }
];

export const WRONG_ZONE_PAGE_OPTIONS = [50, 100, 200];
