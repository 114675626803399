import { useIntl } from 'react-intl';

import { openModal } from 'utils/modal';

import WrongZoneConfirmationModal from 'components/OpsControl/WrongZone/components/WrongZoneConfirmationModal/WrongZoneConfirmationModal';
import useCheckOrderHub from 'components/OpsControl/WrongZone/hooks/useCheckOrderHub';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as InfoIcon } from 'assets/bosta-icons/info.svg';

const NoChangeButton = ({ dispatchOrderAction, order, loading }) => {
  const intl = useIntl();
  const { wrongZoneActionHandler, loading: checkingOrderHub } =
    useCheckOrderHub(order);

  const viewConfirmationModal = () => {
    openModal(WrongZoneConfirmationModal, {
      title: intl.formatMessage(
        {
          id: 'ops_control.wrong_zone.modals.no_change.title'
        },
        {
          hubName: order.current_assigned_hub_name
        }
      ),
      icon: <InfoIcon />,
      onSuccess: () =>
        dispatchOrderAction({
          targetHubId: order.current_assigned_hub_id,
          successMessage: intl.formatMessage({
            id: 'ops_control.wrong_zone.modals.no_change.success_message'
          })
        })
    });
  };

  const handleOnClick = () => wrongZoneActionHandler(viewConfirmationModal);

  return (
    <BRButton
      type="link-color"
      label={intl.formatMessage({
        id: 'ops_control.wrong_zone.table.headers.no_change'
      })}
      onClick={handleOnClick}
      loading={checkingOrderHub}
      disabled={loading}
    />
  );
};

export default NoChangeButton;
