import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useCallback, useContext, useRef, useState } from 'react';

import { OpsControl } from 'contexts/ops-control.context';
import { ACL_MATRIX } from 'common/permissions';
import aclMatrix from 'common/aclMatrix';
import {
  TRACKING_NUMBERS,
  WRONG_ZONE_PAGE_OPTIONS
} from 'constants/ops-wrong-zone';
import {
  cleanEmptyString,
  getPaginationCount,
  isUserAuthorized
} from 'utils/helpers';
import { openModal } from 'utils/modal';
import {
  FOUND_IT,
  LOST_ORDER_SLA_MAX_LEVEL,
  LOST_ORDERS_COLUMNS,
  LOST_ORDERS_QUICK_FILTERS,
  STAR_LIABLE
} from 'constants/lost-or-damaged';
import { calculateAging } from 'utils';
import {
  getLostOrders,
  lostOrdersCount,
  markAsFound,
  markAsLiable
} from 'services/ops-control';
import { getCurrentUserHubInfo } from 'utils/hubs';

import PendingTransitFilter from '../PendingTransit/PendingTransitFilter/PendingTransitFilter';
import LostOrDamagedModal from 'containers/Deliveries/DeliveriesSearch/components/LostOrDamagedModal/LostOrDamagedModal';
import EscalationSeverity from 'components/Escalation/components/TicketsAging/components/EscalationSeverity/EscalationSeverity';
import { notify } from 'components/Notify/Notify';
import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as DeliveriesIcon } from 'assets/bosta-icons/Orders.svg';
import { ReactComponent as ClosedEye } from 'assets/bosta-icons/Eye-1.svg';
import { ReactComponent as Star } from 'assets/bosta-icons/Out for Delivery.svg';

import './LostOrders.less';

const LostOrders = () => {
  const intl = useIntl();
  const { allHubs } = useContext(OpsControl);

  const isHubView = () => {
    return isUserAuthorized(aclMatrix.DELIVERIES, [
      ACL_MATRIX.DElIVERY_MARK_STAR_AS_LIABLE_FOR_LOST_ORDER
    ]);
  };

  const preFillwarehouseId = () => {
    const userWareHouse = getCurrentUserHubInfo()?._id;
    if (userWareHouse && isHubView()) return userWareHouse;
  };

  const [loading, setLoading] = useState(false);
  const [searchOptions, setSearchOptions] = useState({
    trackingNumber: undefined,
    warehouseId: preFillwarehouseId()
  });
  const [delieveriesCounts, setDelieveriesCounts] = useState();
  const [isDeliveriesCountLoading, setIsDeliveriesCountLoading] =
    useState(false);
  const [showDeliveriesCount, setShowDeliveriesCount] = useState(false);
  const refreshTableRef = useRef(() => {});

  const updateSearchOption = useCallback((option) => {
    return (value) => {
      let updatedSearchOptions;

      setSearchOptions((curr) => {
        updatedSearchOptions = {
          ...curr,
          [option]:
            option === 'trackingNumber'
              ? value?.trim() === ''
                ? undefined
                : value
              : value
        };
        return updatedSearchOptions;
      });
    };
  }, []);

  const formatData = (data) => {
    const formattedData = data.map((delivery) => {
      const hubName = delivery?.state?.deliveryLossLiability?.liableHubName;
      const flaggedBy = delivery?.state?.deliveryLossLiability?.flaggedBy?.name;
      const hubId = delivery?.state?.deliveryLossLiability?.liableHubId;
      const slaAging =
        calculateAging(delivery?.state?.deliveryLossLiability?.time) + 1;

      return {
        order_id: (
          <Link
            to={`/deliveries/${delivery.trackingNumber}/details`}
            style={{ textDecoration: 'underline' }}
            target="_blank"
          >
            {delivery.trackingNumber}
          </Link>
        ),
        _id: delivery?._id,
        liableHubId: hubId,
        current_hub: hubName,
        flagged_by: flaggedBy,
        sla: (
          <EscalationSeverity
            severityLevel={slaAging >= 3 ? 3 : slaAging}
            severityMaxLevel={LOST_ORDER_SLA_MAX_LEVEL}
          />
        )
      };
    });
    return formattedData;
  };

  const handleHubChange = (hubValue) => {
    const updatedSearchOptions = updateSearchOption('warehouseId')(hubValue);
    refreshTableRef.current({
      filterValues: {
        ...updatedSearchOptions,
        warehouseId: hubValue
      }
    });
  };

  const renderActions = (order) => {
    return (
      <div className="br-lost-orders__action-buttons-wrapper">
        <BRButton
          type={'outline'}
          className={'br-lost-orders__found-it'}
          label={intl.formatMessage({
            id: 'ops_control.lost_orders.table_columns.found_it'
          })}
          onClick={() => {
            openModal(LostOrDamagedModal, {
              headerTitle: intl.formatMessage({
                id: 'ops_control.lost_orders.table_columns.found_it'
              }),
              allHubs,
              delivery: order,
              modalType: FOUND_IT,
              confirmEndPoint: markAsFound
            });
          }}
        />
        <BRButton
          className={'br-lost-orders__star-liable'}
          type={'basic'}
          label={intl.formatMessage({
            id: 'ops_control.lost_orders.table_columns.star_liable'
          })}
          onClick={() => {
            openModal(LostOrDamagedModal, {
              headerTitle: intl.formatMessage({
                id: 'ops_control.lost_orders.table_columns.star_liable'
              }),
              allHubs,
              delivery: order,
              modalType: STAR_LIABLE,
              confirmEndPoint: markAsLiable
            });
          }}
          prefixIcon={<Star />}
        />
      </div>
    );
  };

  const listFunction = useCallback(
    async ({ page, limit, filterValues, searchInputText }) => {
      const updatedSearchOptions = {
        ...searchOptions,
        ...filterValues,
        trackingNumber: searchInputText
      };

      setShowDeliveriesCount(false);
      setLoading(true);
      try {
        const { data } = await getLostOrders(
          cleanEmptyString({
            limit,
            skip: (page - 1) * limit,
            ...updatedSearchOptions
          })
        );

        updateSearchOption('trackingNumber')(searchInputText);

        return {
          list: formatData(data),
          total: getPaginationCount({
            page,
            result: data,
            pageSize: limit
          })
        };
      } catch (e) {
        notify(e.message);
      } finally {
        setLoading(false);
      }
    },
    [searchOptions, updateSearchOption]
  );

  const shareMethods = (refreshMethod) => {
    refreshTableRef.current = refreshMethod;
  };

  const getDeliveriesCount = async () => {
    setIsDeliveriesCountLoading(true);
    try {
      const res = await lostOrdersCount({
        ...searchOptions
      });
      setDelieveriesCounts(res?.data);
      setShowDeliveriesCount(true);
    } catch (error) {
      notify(error.message);
    }

    setIsDeliveriesCountLoading(false);
  };

  return (
    <div className="br-lost-orders__container">
      <div className="br-lost-orders__header-wrapper">
        <PendingTransitFilter
          // isHubFilterDisabled={preFillwarehouseId()}
          loading={loading}
          intl={intl}
          allHubs={allHubs}
          currentHub={searchOptions?.warehouseId}
          setCurrentHub={updateSearchOption('warehouseId')}
          handleHubChange={handleHubChange}
          hubSelectionPlaceholder={intl.formatMessage({
            id: 'ops_control.wrong_zone.filters.hubs'
          })}
        />
      </div>
      <BRTable
        title={
          <div>
            <div className="ops_control__table-header title-sm">
              <DeliveriesIcon />
              <span className="ml-2">
                {showDeliveriesCount ? (
                  intl.formatMessage(
                    { id: 'ops_control.lost_orders.orders_count' },
                    { delieveriesCounts }
                  )
                ) : (
                  <BRButton
                    label={intl.formatMessage({
                      id: 'pickups.pickup_request.show_count'
                    })}
                    loading={isDeliveriesCountLoading}
                    prefixIcon={<ClosedEye />}
                    onClick={getDeliveriesCount}
                  />
                )}
              </span>
            </div>
          </div>
        }
        className="br-lost-orders__table"
        isLoading={loading}
        searchPlaceholder={intl.formatMessage({
          id: 'ops_control.lost_orders.search_place_holder'
        })}
        onSearchInputChange={updateSearchOption('trackingNumber')}
        listFunction={listFunction}
        columns={LOST_ORDERS_COLUMNS(renderActions)}
        pageSizeOptions={WRONG_ZONE_PAGE_OPTIONS}
        shareMethods={shareMethods}
        quickFilters={LOST_ORDERS_QUICK_FILTERS}
        hideFilterButton
        showSearch
      />
    </div>
  );
};

export default LostOrders;
