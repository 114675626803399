import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const ORDER_TYPES_VALUE = {
  CRP: 'CUSTOMER_RETURN_PICKUP',
  EXCHANGE: 'EXCHANGE',
  DELIVER: 'SEND',
  SIGN_AND_RETURN: 'SIGN_AND_RETURN',
  RTO: 'RTO',
  CASH_COLLECTION: 'CASH_COLLECTION',
  FXF_SEND: 'FXF_SEND'
};

export const CANCELLED = 'Canceled';
export const EXCEPTION = 'Exception';

export const SIGN_AND_RETURN_PICKUP = 'Sign and Return Pickup';

export const SATURDAY = 'Saturday';
export const FRIDAY = 'Friday';

export const AlLOWED_USER_TO_PUSH_UP_PRIORITY = [
  // 'CUSTOMER_SUPPORT',
  'SUPER_ADMIN'
];

export const STAR_ACTIONS = {
  CALL: 'CALL',
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP'
};

export const CALL_TYPES = {
  CALL: fmt({ id: 'deliveries.delivery_details.call_logs.outgoing_call' }),
  MISSED_CALL: fmt({ id: 'deliveries.delivery_details.call_logs.missed_call' }),
  INCOMING_CALL: fmt({
    id: 'deliveries.delivery_details.call_logs.incoming_call'
  })
};

export const DELIVERY_DETAILS_SECTIONS = {
  CONSIGNEE_RATE: 'CONSIGNEE_RATE'
};

export const YES_OR_NO_OPTIONS = [
  { label: fmt({ id: 'common.yes' }), value: true },
  { label: fmt({ id: 'common.no' }), value: false }
];
