import { useIntl } from 'react-intl';
import { Typography } from 'antd';

import { openModal } from 'utils/modal';
import { useReceiveFromBusinessContext } from 'customHooks';

import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal';
import BRButton from 'components/BRButton/BRButton';

const PickupScanningHeader = () => {
  const intl = useIntl();
  const { allowSubmit, businessContactPersons, packages, form } =
    useReceiveFromBusinessContext();

  const showConfirmationModal = businessContactPersons.length > 1;
  const packageCount = packages?.length;
  const getSelectedContactPerson = () => businessContactPersons.find(
    (el) => el.contactPerson._id === form.getFieldValue('contactPerson')
  );

  const handleShowConfirmationModal = () => {
    if (!showConfirmationModal) {
      return;
    }
    openModal(ConfirmationModal, {
      packageCount,
      getSelectedContactPerson,
      handleSubmit: () => form.submit()
    });
  };

  return (
    <>
      <Typography.Title level={4}>
        {intl.formatMessage({
          id: 'hubs.receive_from_business.pickup_scanning.title'
        })}
      </Typography.Title>

      <BRButton
        type="primary"
        label={intl.formatMessage({
          id: 'hubs.receive_from_business.pickup_scanning.confirm_receiving'
        })}
        disabled={!allowSubmit}
        onClick={handleShowConfirmationModal}
        htmlType={!showConfirmationModal ? 'submit' : 'button'}
      />
    </>
  );
};

export default PickupScanningHeader;
