import http from 'utils/http.js';

export const getPricingOrigins = (tierId, payload) => {
  return http.get(`/pricing/tiers/${tierId}/origins`, payload);
};

export const getPricingDestinations = (tierId, payload) => {
  return http.get(`/pricing/tiers/${tierId}/destinations`, payload);
};

export const getPricingServiceTypes = (tierId, payload) => {
  return http.get(`/pricing/tiers/${tierId}/types`, payload);
};

export const getPricingTiers = (payload) => {
  return http.get(`/pricing/tiers`, payload);
};

export const getPricingSizes = (tierId, payload) => {
  return http.get(`/pricing/tiers/${tierId}/sizes`, payload);
};

export const updatePricingOrigins = (id, payload) => {
  return http.put(`/pricing/origins/${id}`, payload);
};

export const updatePricingDestinations = (id, payload) => {
  return http.put(`/pricing/destinations/${id}`, payload);
};

export const updatePricingServiceTypes = (id, payload) => {
  return http.put(`/pricing/types/${id}`, payload);
};

export const updatePricingTiers = (id, payload) => {
  return http.put(`/pricing/tiers/${id}`, payload);
};

export const updatePricingSizes = (id, payload) => {
  return http.put(`/pricing/sizes/${id}`, payload);
};

export const getTransitCosts = (tierId, payload) => {
  return http.get(`/pricing/tiers/${tierId}/transits`, payload);
};

export const updateTransitCosts = (id, payload) => {
  return http.put(`/pricing/transits/${id}`, payload);
};

export const getPricingInfo = (tier, pickupSectorId, vatIncluded = false) => {
  return http.get(
    `/pricing/tiers/${tier}/calculator?pickupSectorId=${pickupSectorId}&vatIncluded=${vatIncluded}`
  );
};

export const getBusinessPricingTierInfo = (businessId) => {
  return http.get(`/pricing/info/${businessId}`);
};

export const clonePricePlan = (id, payload) => {
  return http.post(`/pricing/tiers/${id}/clone`, payload);
};

export const getTierPromotion = (id) => {
  return http.get(`/pricing/tiers/${id}/promotions`);
};

export const addTierPromotion = (id, payload) => {
  return http.post(`/pricing/tiers/${id}/promotions`, payload);
};

export const deleteTierPromotion = (id) => {
  return http.delete(`/pricing/promotions/${id}`);
};

export const updateTierPromotion = (id, payload) => {
  return http.patch(`/pricing/promotions/${id}`, payload);
};

export const deletePricingPlan = (id, payload) => {
  return http.delete(`/pricing/tiers/${id}`, payload);
};

export const getFlyersPricingData = (tierId) => {
  return http.get(`flyers/pricing/${tierId}`);
};

export const updateFlyersPricingData = (tierId, payload) => {
  return http.put(`flyers/pricing/${tierId}`, payload);
};

export const getInternationalPricingTiers = () => {
  return http.get(`/pricing/international/tiers`);
};

export const getInternationalTierDetails = (id) => {
  return http.get(`/pricing/international/tiers/${id}`);
};

export const cloneInternationalTier = (id, payload) => {
  return http.post(`/pricing/international/tiers/${id}/clone`, payload);
};

export const deleteInternationalTier = (payload) => {
  return http.delete(`/pricing/international/tiers`, payload);
};

export const editInternationalTransitCost = (id, payload) => {
  return http.put(`/pricing/international/transitCost/${id}`, payload);
};

export const editInternationalTier = (id, payload) => {
  return http.put(`/pricing/international/tiers/${id}`, payload);
};

export const getInternationalExchangeRate = (payload) => {
  return http.get(`pricing/currency/exchange`, payload);
};

export const editExchangeRate = (payload) => {
  return http.put(`pricing/currency/edit-exchange-rate`, payload);
};

export const getInsurancePlans = () => {
  return http.get('/pricing/insurancePlan');
};

export const updateInsurancePlan = ({ businessId, payload }) => {
  return http.put(`/businesses/${businessId}/insurance-plan`, payload);
};
