import React, { useState, useEffect, useRef, memo } from 'react';
import { injectIntl } from 'react-intl';
import {
  GoogleMap,
  useLoadScript,
  Polygon,
  Marker,
  InfoBox
} from '@react-google-maps/api';
import { Select, Form, InputNumber, Input } from 'antd';

import { getHubs, editHub } from 'services/hubs';
import { DEFAULT_GEOMETRY_TYPE } from 'constants/hubs';
import { GOOGLE_MAP_API_KEY } from 'constants/dynamic-routing';
import { mapDefaultLocation } from 'common/countries/countries-mapping';
import { simplifyPoints } from 'utils/bosta-coding';
import { NUMBER_TEXT } from 'constants/form';

import Container from 'components/Container/index';
import BRHeader from 'components/BRHeader/BRHeader';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';
import BRFormInputs from 'components/BRFormInputs/BRFormInputs';

import './Geofences.less';

const containerStyle = {
  width: '100%',
  height: '70vh'
};

const colors = [
  '#0098A5',
  '#DC6803',
  '#12B76A',
  '#FA7066',
  '#FEC84B',
  '#7A2E0E',
  '#667085',
  '#101828'
];

const Geofences = ({ intl }) => {
  const [hubs, setHubs] = useState([]);
  const [selectedHub, setSelectedHub] = useState({
    hubId: [],
    formattedPaths: []
  });

  const [isLoading, setIsLoading] = useState(false);
  const [plottedGeoLocation, setPlottedGeoLocation] = useState(null);

  const polygonRef = useRef(null);
  const formRef = useRef(null);
  const simplifyFormRef = useRef(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY
  });

  const getAllHubs = async () => {
    setIsLoading(true);
    try {
      const { result } = await getHubs();
      setHubs(result);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleHubChange = (value) => {
    setIsLoading(true);
    const newFormattedPaths = value.map((hubID) => {
      const hub = hubs.find(({ _id }) => _id === hubID);
      const paths = hub?.geometry
        ? hub.geometry.coordinates[0].map((coordinate) => ({
            lat: coordinate[1],
            lng: coordinate[0]
          }))
        : [];
      return paths;
    });

    setSelectedHub({
      hubId: value,
      formattedPaths: newFormattedPaths
    });
    if (value.length > 1) {
      polygonRef?.current?.polygon?.setEditable(false);
    }
    setIsLoading(false);
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    const { hubId } = selectedHub;
    const hub = hubs.find(({ _id }) => _id === hubId[0]);
    const newCoordinates =
      polygonRef?.current?.state?.polygon
        ?.getPath()
        ?.getArray()
        ?.map((value) => [value.lng(), value.lat()]) || [];
    //if the hub didn't have coordinates initially we need to add endpoint to be the same as first point in coordinates array
    //or the user changed the endpoint and it doesn't match the starting point
    if (
      !hub?.geometry?.coordinates ||
      newCoordinates[0]?.filter(
        (pt, idx) => pt !== newCoordinates[newCoordinates.length - 1][idx]
      ).length
    ) {
      newCoordinates.push(newCoordinates[0]);
    }
    try {
      const payload = {
        geometry: {
          type: DEFAULT_GEOMETRY_TYPE,
          coordinates: [newCoordinates]
        }
      };
      await editHub(hub._id, payload);
      getAllHubs();
      notify(
        intl.formatMessage({
          id: 'create_edit_hubs.edit_success'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleAddNewGeofence = ({ latLng: { lat: getLat, lng: getLng } }) => {
    const lat = getLat() + 0.1;
    const lng = getLng() - 0.1;

    setSelectedHub((prev) => ({
      ...prev,
      formattedPaths: [
        [
          { lat, lng },
          { lat: lat - 0.1, lng },
          { lat: lat - 0.2, lng },
          { lat: lat - 0.2, lng: lng + 0.2 },
          { lat: lat - 0.1, lng: lng + 0.2 },
          { lat, lng: lng + 0.2 }
        ],
        ...prev.formattedPaths
      ]
    }));
  };

  const handleSimplifyPolygon = (values) => {
    const { tolerance } = values;

    const currentPolygonPath =
      polygonRef?.current?.state?.polygon
        ?.getPath()
        ?.getArray()
        ?.map((value) => [value.lng(), value.lat()]) || [];

    const formattedPolygon = currentPolygonPath.map((point) => ({
      lng: point[0],
      lat: point[1]
    }));

    const simplifiedPolygon = simplifyPoints(formattedPolygon, tolerance);

    polygonRef.current?.state?.polygon?.setPath(simplifiedPolygon);
  };

  useEffect(() => {
    getAllHubs();
  }, []);

  const handlePlotGeolocation = (values) => {
    setPlottedGeoLocation({
      lat: Number(values?.lat),
      lng: Number(values?.lng)
    });
  };

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={9}
        center={
          selectedHub?.formattedPaths?.length === 1 &&
          selectedHub?.formattedPaths[0]?.length > 6
            ? selectedHub?.formattedPaths[0][0]
            : mapDefaultLocation
        }
        streetView={false}
        options={{
          streetViewControl: false,
          clickableIcons: false,
          mapTypeControl: false
        }}
        mapTypeControl={false}
        onClick={
          selectedHub?.hubId?.length &&
          !selectedHub?.formattedPaths[0]?.length &&
          handleAddNewGeofence
        }
      >
        <InfoBox
          position={mapDefaultLocation}
          options={{
            isHidden: !(
              selectedHub?.hubId?.length &&
              !selectedHub?.formattedPaths[0]?.length
            )
          }}
        >
          <h5 className="br-geofences-info-box__container">
            {intl.formatMessage({
              id: 'create_edit_hubs.add_geofence'
            })}
          </h5>
        </InfoBox>
        {selectedHub?.formattedPaths?.map((path, idx) => (
          <Polygon
            ref={idx === 0 ? polygonRef : null}
            editable={idx === 0}
            options={{
              fillColor: colors[idx % 8],
              fillOpacity: 0.6,
              strokeColor: colors[idx % 8],
              strokeOpacity: 0.8,
              strokeWeight: 2
            }}
            paths={path}
          />
        ))}
        {plottedGeoLocation && <Marker position={plottedGeoLocation} />}
      </GoogleMap>
    );
  };

  return (
    <Container
      containerClassName="br-geofences__container"
      header={
        <BRHeader title={intl.formatMessage({ id: 'sidebar.geofences' })} />
      }
      content={
        <LoadingWrapper loading={isLoading}>
          <div className="br-geofences">
            <div className="display-flex justify-space-between">
              <div className="br-geofences__hub-selectors display-flex flex-dir-col">
                <Form.Item
                  label={intl.formatMessage({
                    id: 'sidebar.hub'
                  })}
                >
                  <Select
                    mode="multiple"
                    value={selectedHub?.hubId}
                    placeholder={intl.formatMessage({
                      id: 'hubs.rto_modal.select_hub_modal.dropdown_placeholder'
                    })}
                    showSearch
                    showArrow
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={handleHubChange}
                  >
                    {hubs?.map(({ _id, name }) => (
                      <Select.Option key={_id} value={_id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <div className="br-geofences__actions">
                  <BRButton
                    // className="br-geofences__save-btn"
                    type="primary"
                    disabled={!selectedHub?.formattedPaths?.length}
                    onClick={handleSaveClick}
                    label={intl.formatMessage({ id: 'common.save' })}
                  />
                </div>
                <Form
                  ref={simplifyFormRef}
                  initialValues={{
                    tolerance: 0.0001
                  }}
                  onFinish={handleSimplifyPolygon}
                >
                  <div className="br-geofences__simplify-action">
                    <BRFormInputs
                      formRef={simplifyFormRef}
                      type={NUMBER_TEXT}
                      name="tolerance"
                      acceptFractions
                      label="Tolerance"
                      rules={[{ required: true }]}
                    />
                    <BRButton
                      type="primary"
                      label="Simplify"
                      disabled={!selectedHub?.formattedPaths?.length}
                      htmlType="submit"
                    />
                  </div>
                </Form>
              </div>
              <div className="display-flex flex-dir-col">
                <Form ref={formRef} onFinish={handlePlotGeolocation}>
                  <div className="display-flex">
                    <Form.Item
                      name="lat"
                      label={intl.formatMessage({
                        id: 'create_edit_hubs.latitude'
                      })}
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      name="lng"
                      label={intl.formatMessage({
                        id: 'create_edit_hubs.longitude'
                      })}
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </div>

                  <BRButton
                    type="primary"
                    htmlType="submit"
                    className="br-geofences__save-btn"
                    label={intl.formatMessage({
                      id: 'create_edit_hubs.plot'
                    })}
                  />
                </Form>
              </div>
            </div>
            <div className="br-geofences__map-container">
              {isLoaded ? (
                renderMap()
              ) : (
                <div>
                  {intl.formatMessage({ id: 'common.map_failed_to_load' })}
                </div>
              )}
            </div>
          </div>
        </LoadingWrapper>
      }
    />
  );
};

export default injectIntl(memo(Geofences));
