import { Tooltip } from 'antd';

import { SLLR_URL } from './sllr-analytics';
import { JOB_TITLES, MAP_PERMISSIONS_CODES, STATE_COLOR } from './Businesses';

import { fmt } from 'components/IntlWrapper/IntlWrapper';
import BRTag from 'components/BRTag/BRTag';
import EditPhoneButton from 'components/NewBusinessDetails/TeamMembers/EditPhoneButton/EditPhoneButton';
import EditEmailButton from 'components/NewBusinessDetails/TeamMembers/EditEmailButton/EditEmailButton';

import { ReactComponent as Info } from 'assets/bosta-icons/report.svg';
import { ReactComponent as Price } from 'assets/bosta-icons/price-tag.svg';
import { ReactComponent as SettingsAlt } from 'assets/bosta-icons/settings-03.svg';
import { ReactComponent as WalletIcon } from 'assets/bosta-icons/wallet-02.svg';
import { ReactComponent as Flyers } from 'assets/bosta-icons/flyers.svg';
import { ReactComponent as Bank } from 'assets/bosta-icons/bank.svg';
import { ReactComponent as InternationalWallet } from 'assets/bosta-icons/BostaBox.svg';
import { ReactComponent as Settings } from 'assets/bosta-icons/settings-02.svg';
import { ReactComponent as FulfillmentIcon } from 'assets/bosta-icons/building.svg';
import { ReactComponent as PromotionsIcon } from 'assets/bosta-icons/promotion.svg';
import { ReactComponent as TeamMembers } from 'assets/bosta-icons/team-members-alt.svg';
import { ReactComponent as Location } from 'assets/bosta-icons/Location-alt.svg';
import { ReactComponent as Clock } from 'assets/bosta-icons/Clock.svg';
import { ReactComponent as Monitor } from 'assets/bosta-icons/Monitor-alt.svg';
import { ReactComponent as Lock } from 'assets/bosta-icons/lock.svg';

export const BUSINESS_FEES_APPLICATION_MAP = {
  true: fmt({
    id: `business_details.pricing_info.applied`
  }),
  false: fmt({
    id: `business_details.pricing_info.not_applied`
  })
};

const BUSINESS_TYPES_LABELS = {
  FAWRY_DONATIONS: fmt({ id: `businesses.details.walletInfo.fawry_donation` })
};

export const BUSINESS_TEAM_MEMBERS_COLUMNS = (
  teamMembersData,
  refreshData = () => {}
) => [
  {
    width: 152,
    title: fmt({
      id: 'business_details.team_members.table_columns.name'
    }),
    render: (user) => {
      const member = teamMembersData.find(
        (member) => member.email === user.profile?.email
      );
      const jobTitle = user.profile?.jobTitle;

      return (
        <div className="br-business-details__team-member-name">
          <div>
            <span>
              {user.profile ? (
                <>
                  {user.profile?.firstName || ''} {user.profile?.lastName || ''}
                </>
              ) : (
                <>{member.emails[0]?.address.split('@')[0]}</>
              )}
            </span>
            {user.organizationalAccount && (
              <Tooltip
                getPopupContainer={() => document.body}
                title={fmt({
                  id: 'business_details.team_members.organizational_account'
                })}
              >
                <Lock />
              </Tooltip>
            )}
          </div>

          {jobTitle && (
            <div className="br-business-details__team-member-job">
              {JOB_TITLES[jobTitle]}
            </div>
          )}
        </div>
      );
    }
  },
  {
    dataIndex: ['profile', 'phone'],
    width: 152,
    title: fmt({
      id: 'business_details.team_members.table_columns.phone'
    }),
    render: (phone, item) => (
      <div>
        {phone ?? fmt({ id: 'common.empty_field' })}{' '}
        <EditPhoneButton refreshData={refreshData} item={item} />
      </div>
    )
  },
  {
    dataIndex: 'emails',
    width: 152,
    title: fmt({
      id: 'business_details.team_members.table_columns.email'
    }),
    render: (emails, item) => (
      <div>
        {emails[0]?.address ?? fmt({ id: 'common.empty_field' })}{' '}
        <EditEmailButton refreshData={refreshData} item={item} />
      </div>
    )
  },
  {
    dataIndex: ['group', 'code'],
    width: 152,
    title: fmt({
      id: 'business_details.team_members.table_columns.permissions'
    }),
    render: (itemCode) => {
      return MAP_PERMISSIONS_CODES[itemCode]?.value || '-';
    }
  },
  {
    dataIndex: ['state', 'code'],
    width: 152,
    title: fmt({
      id: 'business_details.team_members.table_columns.status'
    }),
    render: (code) => {
      return (
        code && (
          <BRTag color={STATE_COLOR[code]}>
            {fmt({
              id: `business_details.team_members.state.${code}`
            })}
          </BRTag>
        )
      );
    }
  }
];

export const BUSINESS_LOGS_TABLE_COLUMNS = [
  {
    dataIndex: 'type',
    width: '25%',
    title: fmt({
      id: 'business_details.logs.table_columns.type'
    })
  },
  {
    dataIndex: 'value',
    width: '25%',
    title: fmt({
      id: 'business_details.logs.table_columns.value'
    })
  },
  {
    dataIndex: 'date',
    width: '25%',
    title: fmt({
      id: 'business_details.logs.table_columns.date'
    })
  },
  {
    dataIndex: 'takenBy',
    width: '25%',
    title: fmt({
      id: 'business_details.logs.table_columns.taken_by'
    })
  }
];

export const BUSINESS_DATES_FORMAT = 'DD MMM YYYY hh:mm a';

export const BusinessInfoCells = ({
  renderDownloadLegalButton,
  renderDownloadPaymentButton
}) => {
  return [
    {
      key: 'label',
      colSpan: 3,
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <Info />
            <span className="heading">
              {fmt({
                id: 'businesses.details.businessInfo.title'
              })}
            </span>
          </div>
        );
      }
    },
    {
      key: 'arabicName',
      label: fmt({
        id: 'businesses.details.businessInfo.arabic_name'
      })
    },
    {
      key: 'description',
      label: fmt({
        id: 'businesses.details.businessInfo.description'
      })
    },
    {
      key: 'website',
      label: fmt({
        id: 'businesses.details.businessInfo.website'
      })
    },
    {
      key: 'joiningDate',
      label: fmt({
        id: 'businesses.details.businessInfo.joining_date'
      })
    },
    {
      key: 'industry',
      label: fmt({
        id: 'businesses.details.businessInfo.industry'
      })
    },
    {
      key: 'type',
      label: fmt({
        id: 'businesses.details.businessInfo.type'
      })
    },
    {
      key: 'accountType',
      label: fmt({
        id: 'businesses.details.businessInfo.account_type'
      })
    },
    {
      key: 'netSuitId',
      label: fmt({
        id: 'businesses.details.businessInfo.netsuit_id'
      })
    },
    {
      key: 'signupSource',
      label: fmt({
        id: 'businesses.details.businessInfo.signup_source'
      })
    },
    {
      key: 'salesChannel',
      label: fmt({
        id: 'businesses.details.businessInfo.sales_channel'
      })
    },
    {
      key: 'legalDocuments',
      label: fmt({
        id: 'businesses.details.businessInfo.legal_documents'
      }),
      render: renderDownloadLegalButton
    },
    {
      key: 'paymentDocuments',
      label: fmt({
        id: 'businesses.details.businessInfo.payment_documents'
      }),
      render: renderDownloadPaymentButton
    },
    {
      key: 'numberOfPickupLocations',
      label: fmt({
        id: 'businesses.details.businessInfo.number_of_pickup_locations'
      })
    }
  ];
};

export const PricingInfoCells = () => {
  return [
    {
      key: 'label',
      colSpan: 3,
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <Price />
            <span className="heading">
              {fmt({
                id: 'businesses.details.pricingInfo.title'
              })}
            </span>
          </div>
        );
      }
    },
    {
      key: 'extraFees',
      label: 'Extra COD Fees'
    },
    {
      key: 'zeroDiscount',
      label: 'Zero COD Discount'
    },
    {
      key: 'insuranceFees',
      label: 'Insurance Fees'
    },
    {
      key: 'nextDayFees',
      label: 'Next Day COD Fees'
    },
    {
      key: 'codFees',
      label: 'COD Fees'
    },
    {
      key: 'ccodFees',
      label: 'CCOD Fees'
    }
  ];
};

export const ManageBusinessCells = (renderSettings) => {
  return [
    {
      key: 'label',
      colSpan: 4,
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <SettingsAlt />
            <span className="heading">
              {fmt({
                id: 'businesses.details.manageBusiness.title'
              })}
            </span>
          </div>
        );
      }
    },
    {
      key: 'settings',
      render: renderSettings
    }
  ];
};

export const WalletInfoCells = () => {
  return [
    {
      key: 'label',
      colSpan: 3,
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <WalletIcon />
            <span className="heading">
              {fmt({
                id: 'businesses.details.walletInfo.title'
              })}
            </span>
          </div>
        );
      }
    },
    {
      key: 'businessType',
      label: fmt({
        id: 'businesses.details.walletInfo.business_type'
      }),
      render: (type) => BUSINESS_TYPES_LABELS[type] ?? type
    },
    {
      key: 'businessCategory',
      label: fmt({
        id: 'businesses.details.walletInfo.business_category'
      })
    },
    {
      key: 'paymentGateway',
      label: fmt({
        id: 'businesses.details.walletInfo.payment_gateway'
      })
    },
    {
      key: 'paymentType',
      label: fmt({
        id: 'businesses.details.walletInfo.payment_type'
      })
    },
    {
      key: 'creditLimit',
      label: fmt({
        id: 'businesses.details.walletInfo.credit_limit'
      })
    },
    {
      key: 'cashoutFrequency',
      label: fmt({
        id: 'businesses.details.walletInfo.cashout_frequency.title'
      })
    }
  ];
};

export const FlyersCells = (renderActionBtn) => {
  return [
    {
      key: 'label',
      colSpan: 2,
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <Flyers />
            <span className="heading">
              {fmt({
                id: 'businesses.details.flyersInfo.title'
              })}
            </span>
            {renderActionBtn}
          </div>
        );
      }
    },
    {
      key: 'currentMerchantQuota',
      label: fmt({ id: 'businesses.details.flyersInfo.current_quota' })
    },
    {
      key: 'initialQuota',
      label: fmt({ id: 'businesses.details.flyersInfo.initial_quota' })
    }
  ];
};

export const BankInfoCells = (
  labelId = 'businesses.details.bankInfo.title'
) => {
  return [
    {
      key: 'label',
      colSpan: 4,
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <Bank />
            <span className="heading">
              {fmt({
                id: labelId
              })}
            </span>
          </div>
        );
      }
    },
    {
      key: 'bankName',
      label: fmt({
        id: 'businesses.details.bankInfo.bank_name'
      })
    },
    {
      key: 'accountOwnerName',
      label: fmt({
        id: 'businesses.details.bankInfo.account_owner_name'
      })
    },
    {
      key: 'accountNumber',
      label: fmt({
        id: 'businesses.details.bankInfo.account_number'
      })
    },
    {
      key: 'ibanNumber',
      label: fmt({
        id: 'businesses.details.bankInfo.iban_number'
      }),
      render: (iban) => (
        <span className="br-business-details__iban">{iban}</span>
      )
    }
  ];
};

export const InternationalCells = () => {
  return [
    {
      key: 'label',
      colSpan: 2,
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <InternationalWallet />
            <span className="heading">
              {fmt({
                id: 'businesses.details.internationalWalletInfo.title'
              })}
            </span>
          </div>
        );
      }
    },
    {
      key: 'internationalPricingPlan',
      label: fmt({
        id: 'businesses.details.internationalWalletInfo.international_pricing_plan'
      })
    },
    {
      key: 'cashoutFrequency',
      label: fmt({
        id: 'businesses.details.internationalWalletInfo.cashout_frequency'
      })
    }
  ];
};

export const OperationsCells = () => {
  return [
    {
      key: 'label',
      colSpan: 3,
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <Settings />
            <span className="heading">
              {fmt({
                id: 'businesses.details.operationsSettings.title'
              })}
            </span>
          </div>
        );
      }
    },
    {
      key: 'numberOfAttempts',
      label: fmt({
        id: 'businesses.details.operationsSettings.number_of_attempts'
      })
    },
    {
      key: 'proofOfReturn',
      label: fmt({
        id: 'businesses.details.operationsSettings.proof_of_return'
      })
    },
    {
      key: 'pickupProof',
      label: fmt({
        id: 'businesses.details.operationsSettings.pickup_proof'
      })
    }
  ];
};

export const FulfillmentCells = () => [
  {
    key: 'label',
    render: () => {
      return (
        <div className="br-business-details__info-tables-header">
          <FulfillmentIcon />
          <span className="heading">
            {fmt({
              id: 'businesses.details.fulfillmentInfo.title'
            })}
          </span>
        </div>
      );
    }
  },
  {
    key: 'pricingPlan',
    label: fmt({
      id: 'businesses.details.fulfillmentInfo.pricing_plan'
    })
  }
];

export const PromotionsCells = () => {
  return [
    {
      key: 'label',
      colSpan: 3,
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <PromotionsIcon />
            <span className="heading">
              {fmt({
                id: 'businesses.details.promotions.title'
              })}
            </span>
          </div>
        );
      }
    },
    { key: 'value', label: fmt({ id: 'businesses.details.promotions.value' }) },
    {
      key: 'effectiveFrom',
      label: fmt({ id: 'businesses.details.promotions.effective_from' })
    },
    {
      key: 'expiryDate',
      label: fmt({ id: 'businesses.details.promotions.expiry_date' })
    },
    {
      key: 'subType',
      label: fmt({ id: 'businesses.details.promotions.subtype' })
    },
    {
      key: 'takenBy',
      label: fmt({ id: 'businesses.details.promotions.taken_by' })
    },
    {
      key: 'createdAt',
      label: fmt({ id: 'businesses.details.promotions.created_at' })
    }
  ];
};

export const BusinessLocationsCells = ({
  viewAllContacts,
  actionBtn,
  location,
  defaultContact
}) => {
  return [
    {
      key: 'label',
      colSpan: 3,
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <Location />
            <span className="heading">
              {fmt({
                id: 'businesses.details.businessLocations.title'
              })}
            </span>
            {actionBtn}
          </div>
        );
      }
    },
    {
      key: 'locationName',
      label: fmt({ id: 'businesses.details.businessLocations.location_name' })
    },
    {
      key: 'location',
      label: fmt({ id: 'businesses.details.businessLocations.location' }),
      render: () => location
    },
    {
      key: 'defaultContact',
      label: (
        <div className="br-business-details__default-location__field-label">
          {fmt({ id: 'businesses.details.businessLocations.default_contact' })}
          {viewAllContacts}
        </div>
      ),
      render: () => defaultContact
    }
  ];
};

export const TeamMembersCells = (renderTeamMembersTable) => {
  return [
    {
      key: 'label',
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <TeamMembers />
            <span className="heading">
              {fmt({
                id: 'businesses.details.teamMembers.title'
              })}
            </span>
          </div>
        );
      }
    },
    {
      render: renderTeamMembersTable
    }
  ];
};

export const LogsTimelineCells = (renderLogsTable) => {
  return [
    {
      key: 'label',
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <Clock />
            <span className="heading">
              {fmt({
                id: 'businesses.details.logsTimeline.title'
              })}
            </span>
          </div>
        );
      }
    },
    {
      render: renderLogsTable
    }
  ];
};

export const SllrCells = ({ renderActionBtn, extraInfo }) => {
  const extendedInfoCells = !extraInfo
    ? []
    : [
        {
          key: 'productInventoryCount',
          label: fmt({
            id: 'business_details.sllr.table_columns.products_inventory'
          })
        },
        {
          key: 'productsCount',
          label: fmt({
            id: 'business_details.sllr.table_columns.products_catalog'
          })
        },
        {
          key: 'completedSllrOrders',
          label: fmt({
            id: 'business_details.sllr.table_columns.placed_orders'
          })
        },
        {
          key: 'confirmedSllrOrders',
          label: fmt({
            id: 'business_details.sllr.table_columns.confirmed_orders'
          })
        },
        {
          key: 'pickedUpSllrOrders',
          label: fmt({
            id: 'business_details.sllr.table_columns.pickedup_orders'
          })
        },
        {
          key: 'successfulSllrOrders',
          label: fmt({
            id: 'business_details.sllr.table_columns.successful_orders'
          })
        },
        {
          key: 'totalSllrRevenue',
          label: fmt({
            id: 'business_details.sllr.table_columns.total_sllr_revenue'
          })
        },
        {
          render: () => <></>
        }
      ];

  return [
    {
      key: 'label',
      colSpan: 4,
      render: () => {
        return (
          <div className="br-business-details__info-tables-header">
            <Monitor />
            <span className="heading">
              {fmt({
                id: 'businesses.details.sllrInfo.title'
              })}
            </span>
            {renderActionBtn}
          </div>
        );
      }
    },
    {
      key: 'storeLink',
      label: fmt({
        id: 'businesses.details.sllrInfo.store_link'
      }),
      render: (storeLink) =>
        storeLink &&
        storeLink !== 'N/A' && (
          <a href={`${SLLR_URL}${storeLink}`} target="_blank">
            {storeLink}
          </a>
        )
    },
    {
      key: 'storeName',
      label: fmt({
        id: 'businesses.details.sllrInfo.store_name'
      })
    },
    {
      key: 'sllrStore',
      label: fmt({
        id: 'businesses.details.sllrInfo.sllr_store'
      })
    },
    {
      key: 'businessStatus',
      label: fmt({
        id: 'businesses.details.sllrInfo.business_status'
      })
    },
    ...extendedInfoCells
  ];
};
