import { useState } from 'react';

import {
  addWrongScanLog,
  formatPackages,
  formatReceivedOrders
} from 'utils/hubs';

import { notify } from 'components/Notify/Notify';

const useHubPackages = () => {
  const [packages, setPackages] = useState([]);

  const addPackage = (newPackage) => {
    setPackages((prev) => [...prev, newPackage]);
  };

  const removePackage = async (id) => {
    const item = packages.find((item) => item._id === id);

    if (!item) {
      return;
    }

    setPackages((prev) => prev.filter((el) => el._id !== id));

    try {
      await addWrongScanLog([item.trackingNumber]);
    } catch (e) {
      notify(e.message);

      setPackages(packages);
    }
  };

  const updatePackageWeight = ({ value, delivery }) => {
    if (!value) {
      return;
    }

    const weightValue = value.split('KG')[0];

    if (isNaN(weightValue)) {
      notify('Please enter number not text');
      return;
    }

    setPackages((prev) => {
      return [
        ...prev.map((d) => {
          if (d.trackingNumber === delivery?.trackingNumber) {
            d.packageWeight = parseInt(weightValue);
          }
          return d;
        })
      ];
    });
  };

  const clearPackages = async (addLog = true) => {
    setPackages([]);

    if (!addLog) {
      return;
    }

    try {
      await addWrongScanLog(packages.map((item) => item.trackingNumber));
    } catch (e) {
      notify(e.message);

      setPackages(packages);
    }
  };

  const showFailedPackages = (failedDeliveries) => {
    if (!failedDeliveries || !failedDeliveries.length) {
      setPackages([]);
      return;
    }

    setPackages((prev) =>
      prev.filter((el) =>
        failedDeliveries.some(
          (trackingNumber) => el.trackingNumber === trackingNumber
        )
      )
    );
  };

  return {
    packages,
    formatPackages,
    formatReceivedOrders,
    addPackage,
    removePackage,
    updatePackageWeight,
    clearPackages,
    showFailedPackages
  };
};

export default useHubPackages;
