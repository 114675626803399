import { useIntl } from 'react-intl';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import useKeyboardInput from 'hooks/useKeyboardInput';

import BRButton from 'components/BRButton/BRButton';

const FilterFooter = ({
  handleSubmitFilters,
  handleResetFilters,
  handleExpandToggle,
  isExpanded
}) => {
  const intl = useIntl();

  useKeyboardInput('Enter', handleSubmitFilters);
  return (
    <div className="br-filters-search__footer">
      <span
        onClick={handleExpandToggle}
        className="br-filters-search-collapse-filters"
      >
        {isExpanded ? (
          <>
            <span>
              {intl.formatMessage({
                id: 'pickups.pickup_request.search.collapse_filters'
              })}
            </span>{' '}
            <UpOutlined />
          </>
        ) : (
          <>
            <span>
              {intl.formatMessage({
                id: 'pickups.pickup_request.search.view_all_filters'
              })}
            </span>
            <DownOutlined />
          </>
        )}
      </span>
      <div className="br-filters-search__btns-wrapper">
        <BRButton
          className={'br-filters-search__cancel-btn'}
          label={intl.formatMessage({ id: 'common.clear' })}
          onClick={handleResetFilters}
        />
        <BRButton
          type="primary"
          htmlType="submit"
          label={intl.formatMessage({ id: 'common.apply' })}
          onClick={handleSubmitFilters}
        />
      </div>
    </div>
  );
};

export default FilterFooter;
