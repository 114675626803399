import http from 'utils/http';

export const getFulfillmentTierList = (options = {}) => {
  return http.get(`/pricing/fulfillment/tiers`, undefined, options);
};

export const cloneFulfillmentPricePlan = (payload) => {
  return http.post(`/pricing/fulfillment/tiers`, payload);
};

export const updateFulfillmentPricingTiers = (id, payload) => {
  return http.put(`/pricing/fulfillment/tiers/${id}`, payload);
};

export const deleteFulfillmentPricingPlan = (id, payload) => {
  return http.delete(`/pricing/fulfillment/tiers/${id}`, payload);
};
