import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Upload, Button, Select } from 'antd';
import Icon from '@ant-design/icons';
import { saveAs } from 'file-saver';
import classnames from 'classnames';

import { createMassOrders } from 'services/shipments';
import {
  createFulfillmentMassOrders,
  getValidFulfillmentBusinesses
} from 'services/fulfillment';
import { getBusiness, fetchFXFBusinesses } from 'services/business';

import {
  convertExcelToJSON,
  massUploadError,
  validateOrders,
  validateFulfillmentOrders
} from 'utils/orders';
import { useCancellablePromise } from 'utils/promise';
import { sendSegment } from 'utils/segment';
import { isUserAuthorized } from 'utils/helpers';
import { IS_SAUDI } from 'constants/helper';
import {
  NORMAL_SHEET_COLUMN_LENGTH,
  FULFILLMENT_SHEET_COLUMN_LENGTH
} from 'constants/mass-upload';
import aclMatrix from 'common/aclMatrix';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import Container from 'components/Container';
import BRHeader from 'components/BRHeader/BRHeader';
import MassUploadErrorTable from 'components/Shipments/components/MassUploadErrorTable/MassUploadErrorTable';
import MassUploadSuccess from 'components/Shipments/components/MassUploadSuccess/MassUploadSuccess';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BusinessSelector from 'components/BRSelectors/BusinessSelector/BusinessSelector';

import UploadOrdersTemplate from 'assets/Bosta Upload Template V2.8.xlsx';
import UploadOrdersTemplateKSA from 'assets/Bosta Upload Template KSA.V2.7.xlsx';
import UploadFXFOrdersTemplate from 'assets/Bosta Fulfillment Upload Template.V4.2.xlsx';
import UploadFXFOrdersTemplateKSA from 'assets/Bosta Fulfillment Upload Template KSA.V4.2.xlsx';
import { ReactComponent as uploadImage } from 'assets/imgRevamp/upload.svg';
import { ReactComponent as warningIcon } from 'assets/imgRevamp/warning_icon.svg';

import './MassUpload.less';

const MassUpload = ({ intl }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorObject, setErrorObject] = useState(null);
  const [file, setFile] = useState({});
  const [ordersLength, setOrdersLength] = useState(0);
  const [ordersIds, setOrdersIds] = useState(null);
  const [isReUpload, setIsReUpload] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [fxfBusinesses, setFXFBusinesses] = useState([]);
  const [businessId, setBusinessId] = useState(null);
  const [businessName, setBusinessName] = useState(null);
  const [fulfillmentMassUpload, setFulfillmentMassUpload] = useState(true);
  const [showHeaderOptions, setShowMassUploadHeader] = useState(true);
  let isCorrectSheetTemplate = true;

  const { cancellablePromise } = useCancellablePromise();
  const accessFXF = isUserAuthorized(aclMatrix.INVENTORY);

  useEffect(() => {
    fetchData();
    setFulfillmentMassUpload(accessFXF);
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (accessFXF) {
        const [oldFxfBusinesses, newFxfBusinesses] = await Promise.all([
          fetchFXFBusinesses(),
          getValidFulfillmentBusinesses()
        ]);

        const allFxfBusinesses = [...oldFxfBusinesses, ...newFxfBusinesses];

        setFXFBusinesses(allFxfBusinesses);
        setBusinesses(allFxfBusinesses);
      }
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleMassUploadOptions = (fxfMassUpload) => {
    setFulfillmentMassUpload(fxfMassUpload);
    setBusinessId(null);
    setBusinesses(fxfMassUpload && fxfBusinesses);
  };

  const sheetColumnsLength = (sheetLength) => {
    // The length of columns in the normal massupload sheet is 25 and the fulfillment is 23
    isCorrectSheetTemplate = fulfillmentMassUpload
      ? sheetLength === FULFILLMENT_SHEET_COLUMN_LENGTH
      : sheetLength === NORMAL_SHEET_COLUMN_LENGTH;
  };

  const handleBusinessChange = useCallback((businessId) => {
    setBusinessId(businessId);
  }, []);

  const handleUpload = async (file) => {
    if (!businessId) {
      notify(
        intl.formatMessage({
          id: 'orders.mass_upload.error_select_business'
        })
      );
      return false;
    }

    setFile({ name: file.name, size: file.size / 1024 / 1024 });
    setIsLoading(true);
    let orders = null;
    let selectedBusinessName;

    try {
      const business = await getBusiness(businessId);
      selectedBusinessName = business?.name;
      setBusinessName(selectedBusinessName);

      orders = await cancellablePromise(
        convertExcelToJSON(
          file,
          selectedBusinessName,
          !!fulfillmentMassUpload,
          sheetColumnsLength
        )
      );
      setOrdersLength(orders.length);
      const { isValid, errors } = await cancellablePromise(
        fulfillmentMassUpload
          ? validateFulfillmentOrders(orders, business)
          : validateOrders(orders, business)
      );

      if (!isValid) {
        setOrdersIds(null);
        setErrorObject(errors);
      } else {
        const { data, createdDeliveriesArr: fxf_data } = fulfillmentMassUpload
          ? await createFulfillmentMassOrders({
              deliveries: orders,
              businessId
            })
          : await createMassOrders({
              deliveries: orders,
              businessId
            });
        setErrorObject(null);
        setOrdersIds(data || fxf_data[0]._id);
      }

      if (isReUpload) {
        setIsReUpload(false);
      }
    } catch (error) {
      if (!error.failed || !error.data) {
        sendSegment(
          'E_ADMIN_UPLOAD_FAILED',
          {
            'Business Name': selectedBusinessName,
            'number of orders': ordersLength,
            'upload error': 'Yes',
            'Error type': error.message
          },
          'Failed'
        );
      }
      if (error?.failed?.length && error?.failed[0]?.errorMessage) {
        notify(error?.failed[0]?.errorMessage);
      }

      if (error?.data?.length && error?.data[0]?.errorMessage) {
        notify(error?.data[0]?.errorMessage);
      }
      //  {key: 1, name: "Street Name", issue: "Field is required", rows: "3"}
      if (error.failed || error.data) {
        const errors = massUploadError(orders, error.failed || error.data);
        setErrorObject(errors);
      } else if (error.message.includes('Old template')) {
        setErrorObject(null);
        notify(
          intl.formatMessage({
            id: 'orders.mass_upload.error_old_template'
          })
        );
      } else {
        notify(error.message);
      }
    }
    setIsLoading(false);
    return false;
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.xlsx',
    showUploadList: false,
    beforeUpload: handleUpload
  };

  const onCancel = () => {
    setOrdersIds(null);
    setErrorObject(null);
    setShowMassUploadHeader(true);
  };

  const onReUpload = () => {
    setIsReUpload(true);
  };

  const handleDownloadTemplateClick = () => {
    fulfillmentMassUpload && accessFXF
      ? saveAs(
          IS_SAUDI ? UploadFXFOrdersTemplateKSA : UploadFXFOrdersTemplate,
          'Bosta Fulfillment Upload Template.V4.2.xlsx'
        )
      : saveAs(
          IS_SAUDI ? UploadOrdersTemplateKSA : UploadOrdersTemplate,
          'Bosta Upload Template.V2.8.xlsx'
        );
  };

  const handleHelpGuideClick = () => {
    // Normal and Fulfillment Mass Uploads are using the same URL, but with a different ID
    const urlID =
      fulfillmentMassUpload && accessFXF ? '36000338344' : '36000267665';
    sendSegment('E_READ_UPLOAD_HELP_ARTICLE');
    window.open(
      `https://bosta.freshdesk.com/a/solutions/articles/${urlID}?lang=en`,
      '_blank'
    );
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Container
        className="br-orders-mass-upload-container"
        header={<BRHeader />}
        content={
          <div className="br-orders-mass-upload">
            {showHeaderOptions && (
              <div className="br-orders-mass-upload__upload-options">
                <div
                  className={classnames(
                    {
                      'br-orders-mass-upload__upload-option-active':
                        !fulfillmentMassUpload || !accessFXF
                    },
                    'br-orders-mass-upload__option'
                  )}
                  onClick={() => handleMassUploadOptions(false)}
                >
                  {intl.formatMessage({
                    id: 'orders.mass_upload.title'
                  })}
                </div>
                {accessFXF && (
                  <div
                    className={classnames(
                      {
                        'br-orders-mass-upload__upload-option-active':
                          fulfillmentMassUpload
                      },
                      'br-orders-mass-upload__option'
                    )}
                    onClick={() => handleMassUploadOptions(true)}
                  >
                    {intl.formatMessage({
                      id: 'orders.mass_upload.fulfillment.title'
                    })}
                  </div>
                )}
              </div>
            )}
            {errorObject?.length ? (
              <MassUploadErrorTable
                setShowMassUploadHeader={setShowMassUploadHeader}
                errorArray={errorObject}
                file={file}
                orders={ordersLength}
                onCancel={onCancel}
                uploadProps={uploadProps}
                onReUpload={onReUpload}
                businessName={businessName}
                isFulfillment={fulfillmentMassUpload}
              />
            ) : ordersIds ? (
              <MassUploadSuccess
                setShowMassUploadHeader={setShowMassUploadHeader}
                ordersIds={ordersIds}
                onCancel={onCancel}
                businessName={businessName}
                isFulfillment={fulfillmentMassUpload}
              />
            ) : (
              <div className="display-flex justify-space-between full-height flex-equal-children">
                <div className="br-orders-mass-upload__left-side">
                  <BRContentHeader
                    title={intl.formatMessage({
                      id: `orders.mass_upload${
                        fulfillmentMassUpload && accessFXF ? '.fulfillment' : ''
                      }.title`
                    })}
                    subtitle={intl.formatMessage({
                      id: `orders.mass_upload${
                        fulfillmentMassUpload && accessFXF ? '.fulfillment' : ''
                      }.sub_title`
                    })}
                  />
                  {(fulfillmentMassUpload && accessFXF) || (
                    <div className="br-orders-mass-upload__old-template">
                      <Icon component={warningIcon} />
                      <div>
                        <p className="br-orders-mass-upload__old-template__title">
                          {intl.formatMessage({
                            id: 'orders.mass_upload.error_old_template_title'
                          })}
                        </p>
                        <p className="br-orders-mass-upload__old-template__sub-title">
                          {intl.formatMessage({
                            id: 'orders.mass_upload.error_old_template_sub_title'
                          })}
                        </p>
                        <p className="br-orders-mass-upload__old-template__hint">
                          {intl.formatMessage({
                            id: 'orders.mass_upload.error_old_template_hint'
                          })}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="br-orders-mass-upload__steps">
                    <span className="br-orders-mass-upload__steps__title">
                      {intl.formatMessage({
                        id: 'orders.mass_upload.steps_title'
                      })}
                    </span>
                    <span>
                      {intl.formatMessage(
                        {
                          id: `orders.mass_upload${
                            fulfillmentMassUpload && accessFXF
                              ? '.fulfillment'
                              : ''
                          }.step_one`
                        },
                        {
                          download: (
                            <Button
                              type="link"
                              onClick={handleDownloadTemplateClick}
                            >
                              {intl.formatMessage({
                                id: 'orders.mass_upload.download'
                              })}
                            </Button>
                          )
                        }
                      )}
                    </span>
                    <span>
                      {intl.formatMessage(
                        {
                          id: 'orders.mass_upload.step_two'
                        },
                        {
                          learn: (
                            <Button type="link" onClick={handleHelpGuideClick}>
                              {intl.formatMessage({
                                id: 'orders.mass_upload.learn'
                              })}
                            </Button>
                          )
                        }
                      )}
                    </span>
                    <span>
                      {intl.formatMessage({
                        id: 'orders.mass_upload.step_three'
                      })}
                    </span>
                    <span className="br-orders-mass-upload__business-label">
                      {intl.formatMessage({
                        id: 'orders.mass_upload.business'
                      })}
                    </span>
                    {fulfillmentMassUpload ? (
                      <Select
                        className="br-orders-mass-upload__business-selector"
                        placeholder={intl.formatMessage({
                          id: `orders.mass_upload.business_placeholder`
                        })}
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        value={businessId}
                        onChange={handleBusinessChange}
                        getPopupContainer={(trigger) => trigger.parentElement}
                      >
                        {businesses.map(({ _id, name }) => (
                          <Select.Option key={_id} value={_id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <BusinessSelector
                        className="br-orders-mass-upload__business-selector"
                        placeholder={intl.formatMessage({
                          id: `orders.mass_upload.business_placeholder`
                        })}
                        onChange={handleBusinessChange}
                        value={businessId}
                        getPopupContainer={(trigger) => trigger.parentElement}
                      />
                    )}
                  </div>
                </div>
                <Upload.Dragger {...uploadProps}>
                  <p className="ant-upload-drag-icon">
                    <Icon component={uploadImage} />
                  </p>
                  <p className="ant-upload-text">
                    {intl.formatMessage({
                      id: 'orders.mass_upload.upload_dialog.title'
                    })}
                  </p>
                  <p className="ant-upload-hint">
                    <span>
                      {intl.formatMessage({
                        id: 'orders.mass_upload.upload_dialog.sub_title'
                      })}
                    </span>
                    <span>
                      {intl.formatMessage({
                        id: 'orders.mass_upload.upload_dialog.hint'
                      })}
                    </span>
                  </p>
                  <Button>
                    {intl.formatMessage({
                      id: 'orders.mass_upload.upload_dialog.upload_file'
                    })}
                  </Button>
                </Upload.Dragger>
              </div>
            )}
          </div>
        }
      />
    </LoadingWrapper>
  );
};

export default injectIntl(MassUpload);
