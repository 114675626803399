import { useEffect, useState } from 'react';

import { getAllPriceSize } from 'services/priceTiers';
import { IS_SAUDI } from 'constants/helper';

import { notify } from 'components/Notify/Notify';

const useWeights = IS_SAUDI;

const usePackageSizes = () => {
  const [loading, setLoading] = useState(false);
  const [allPricingPackageSize, setAllPricingPackageSize] = useState([]);

  useEffect(() => {
    if (useWeights) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const priceSize = await getAllPriceSize();
        const formattedPriceSizes = priceSize?.data?.sizes.map((item) => {
          const oldPricing = ['Normal', 'White Bag'].indexOf(item.name);
          return {
            ...item,
            name:
              item?.alias !== item.name
                ? `${item.alias} (${item.name})`
                : item.name,
            value: item.name,
            oldPricing: oldPricing === -1 ? 0 : oldPricing,
            dimmed: ['Large', 'X-Large'].includes(item.name)
          };
        });
        setAllPricingPackageSize(formattedPriceSizes);
      } catch (error) {
        notify(error.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    allPricingPackageSize,
    loadingPricingPackageSizes: loading
  };
}

export default usePackageSizes;