import { Modal, Form, Select, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { createStreetCode, getZonesAndDistricts } from 'services/zoning';
import { formatPolygonCoordinates } from 'utils/bosta-coding';
import { POLYGON } from 'constants/dynamic-routing';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import './AddToDistrictModal.less';

const AddToDistrictModal = ({
  close,
  zones,
  hubId,
  polygonRef,
  onSuccess,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDistrictsLoading, setIsDistrictsLoading] = useState(false);
  const [districts, setDistricts] = useState([]);

  const intl = useIntl();
  const [form] = Form.useForm();
  const zoneId = Form.useWatch('zoneId', form);

  const handleOnFinish = async (values) => {
    setIsLoading(true);

    try {
      const payload = {
        warehouseId: hubId,
        geometry: {
          coordinates: [formatPolygonCoordinates(polygonRef)],
          type: POLYGON
        },
        ...values
      };
      await createStreetCode(payload);
      notify(
        intl.formatMessage({
          id: 'bosta_coding.district_mapping.add_to_district_success_msg'
        }),
        'success'
      );
      close();
      onSuccess();
    } catch (error) {
      notify(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchZonesAndDistricts = async (cityId) => {
    setIsDistrictsLoading(true);

    try {
      const data = await getZonesAndDistricts(cityId);
      const zoneDistricts = data.find(
        (zone) => zone.zoneId === zoneId
      )?.districts;

      setDistricts(zoneDistricts);
    } catch (error) {
      notify(error.message);
    } finally {
      setIsDistrictsLoading(false);
    }
  };

  useEffect(() => {
    if (zoneId) {
      const cityId = zones.find((zone) => zone._id === zoneId)?.city?._id;

      if (cityId) {
        fetchZonesAndDistricts(cityId);
      }
    }
  }, [zoneId]);

  return (
    <Modal
      title={intl.formatMessage({
        id: 'bosta_coding.district_mapping.add_to_district'
      })}
      footer={
        <>
          <BRButton
            onClick={close}
            label={intl.formatMessage({ id: 'common.cancel' })}
            disabled={isLoading}
          />
          <BRButton
            type="primary"
            label={intl.formatMessage({
              id: 'bosta_coding.district_mapping.add'
            })}
            form="addToDistrictForm"
            htmlType="submit"
            loading={isLoading}
          />
        </>
      }
      wrapClassName="br-add-to-district__modal"
      {...props}
    >
      <Form name="addToDistrictForm" onFinish={handleOnFinish} form={form}>
        <Form.Item
          name="zoneId"
          label={intl.formatMessage({
            id: 'bosta_coding.district_mapping.labels.zone'
          })}
          rules={[{ required: true }]}
        >
          <Select
            options={zones}
            fieldNames={{ label: 'name', value: '_id' }}
            showSearch
            showArrow
            optionFilterProp="name"
            placeholder={intl.formatMessage({
              id: 'common.select'
            })}
          />
        </Form.Item>
        <Form.Item
          name="districtId"
          label={intl.formatMessage({
            id: 'bosta_coding.district_mapping.labels.district'
          })}
          rules={[{ required: true }]}
        >
          <Select
            options={districts}
            fieldNames={{ label: 'name', value: '_id' }}
            showSearch
            showArrow
            optionFilterProp="name"
            loading={isDistrictsLoading}
            disabled={!districts?.length}
            placeholder={intl.formatMessage({
              id: 'common.select'
            })}
          />
        </Form.Item>
        <div className="br-add-to-district__modal-form-row">
          <Form.Item
            name="name"
            label={intl.formatMessage({
              id: 'bosta_coding.district_mapping.labels.en_street_name'
            })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'bosta_coding.district_mapping.placeholder.enter_name'
              })}
            />
          </Form.Item>
          <Form.Item
            name="nameAr"
            label={intl.formatMessage({
              id: 'bosta_coding.district_mapping.labels.ar_street_name'
            })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'bosta_coding.district_mapping.placeholder.enter_name'
              })}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="l2SectorCode"
          label={intl.formatMessage({
            id: 'bosta_coding.district_mapping.labels.sector_code'
          })}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'bosta_coding.district_mapping.placeholder.enter_code'
            })}
          />
        </Form.Item>
        <Form.Item
          name="l2DistrictCode"
          label={intl.formatMessage({
            id: 'bosta_coding.district_mapping.labels.district_code'
          })}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'bosta_coding.district_mapping.placeholder.enter_code'
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddToDistrictModal;
