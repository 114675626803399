import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { Tooltip } from 'antd';

import { BusinessLocationsCells } from 'constants/business-details';
import { isUserAuthorized } from 'utils/helpers';
import { EXPERIENCE, SUPER_ADMIN } from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';
import { openModal } from 'utils/modal';

import AddReturnContactModal from 'components/Businesses/AddReturnContactModal/AddReturnContactModal';
import BRButton from 'components/BRButton/BRButton';
import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import LocationInfo from './LocationInfo/LocationInfo';

import { ReactComponent as ViewAllIcon } from 'assets/bosta-icons/Eye.svg';

import './DefaultBusinessLocation.less';

const DefaultBusinessLocation = ({
  defaultBusinessLocationInfo,
  refreshData
}) => {
  const intl = useIntl();
  const {
    businessId,
    location = {},
    businessContacts = [],
    defaultContact = {}
  } = defaultBusinessLocationInfo ?? {};

  const showActionBtn = isUserAuthorized(
    [SUPER_ADMIN, EXPERIENCE],
    [ACL_MATRIX.BUSINESS_ADD_RETURN_CONTACT]
  );

  const handleActionBtnClick = useCallback(
    () =>
      openModal(AddReturnContactModal, {
        businessId,
        onSuccess: refreshData
      }),
    [businessId, refreshData]
  );

  const actionBtn = showActionBtn && (
    <BRButton
      onClick={handleActionBtnClick}
      label={intl.formatMessage({
        id: 'businesses.details.businessLocations.add_contact'
      })}
      type="outline"
    />
  );

  const viewAllContacts = businessContacts.length > 0 && (
    <Tooltip
      title={
        <div className="business-contacts">
          {businessContacts.map(({ name, phone }) => (
            <div>
              {name} <span>{phone}</span>
            </div>
          ))}
        </div>
      }
    >
      <BRButton
        type="link-color"
        prefixIcon={<ViewAllIcon />}
        label={intl.formatMessage({
          id: 'businesses.details.businessLocations.view_all'
        })}
      />
    </Tooltip>
  );

  return (
    <BRInformationTable
      totalCol={3}
      dataSource={defaultBusinessLocationInfo}
      cells={BusinessLocationsCells({
        viewAllContacts,
        actionBtn,
        location: <LocationInfo location={location} />,
        defaultContact: (
          <div className="br-business-details__default-location-cell">
            <div>{defaultContact.name}</div>
            <p>{defaultContact.phone}</p>
          </div>
        )
      })}
    />
  );
};

export default DefaultBusinessLocation;
