import intersect from '@turf/intersect';
import { feature, polygon } from '@turf/helpers';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { simplify } from '@turf/turf';

export const getPolygonCentroid = (coords) => {
  let latSum = 0;
  let lngSum = 0;
  let areaSum = 0;
  for (let i = 0; i < coords.length; i++) {
    const j = (i + 1) % coords.length;
    const area = coords[i].lng * coords[j].lat - coords[j].lng * coords[i].lat;
    areaSum += area;
    latSum += (coords[i].lat + coords[j].lat) * area;
    lngSum += (coords[i].lng + coords[j].lng) * area;
  }
  if (areaSum === 0) {
    return null;
  } else {
    const centroidLat = latSum / (3 * areaSum);
    const centroidLng = lngSum / (3 * areaSum);
    return { lat: centroidLat, lng: centroidLng };
  }
};

export const generateInitialPolygon = ({
  lat,
  lng,
  sides = 5,
  radius = 0.01
}) => {
  const coords = [];
  const angleStep = (2 * Math.PI) / sides;
  for (let i = 0; i < 5; i++) {
    const angle = i * angleStep;
    const lat_i = lat + radius * Math.cos(angle);
    const lng_i = lng + radius * Math.sin(angle);
    coords.push({ lat: lat_i, lng: lng_i });
  }
  return coords;
};

export const polygonContainsPointsOutsideParentPolygon = ({
  polygon,
  parentPolygonCoords
}) => {
  for (let i = 0; i < polygon.length; i++) {
    const point = polygon[i];
    if (!isInsidePolygon(point, parentPolygonCoords)) {
      return true;
    }
  }
  return false;
};

const isInsidePolygon = (point, polygon) => {
  let crossings = 0;
  for (let i = 0; i < polygon.length; i++) {
    const vertex1 = polygon[i];
    const vertex2 = polygon[(i + 1) % polygon.length];
    if (
      ((vertex1.lat <= point.lat && point.lat < vertex2.lat) ||
        (vertex2.lat <= point.lat && point.lat < vertex1.lat)) &&
      point.lng <
        ((vertex2.lng - vertex1.lng) * (point.lat - vertex1.lat)) /
          (vertex2.lat - vertex1.lat) +
          vertex1.lng
    ) {
      crossings++;
    }
  }
  return crossings % 2 !== 0;
};

export function checkPolygonsIntersection(polygons) {
  // Convert each polygon to Turf.js format
  const turfPolygons = polygons.map((polygon) => {
    const turfCoords = polygon.map((coord) => [coord.lng, coord.lat]);
    return feature({
      type: 'Polygon',
      coordinates: [turfCoords]
    });
  });

  // Check if any pair of polygons intersect
  for (let i = 0; i < turfPolygons.length; i++) {
    for (let j = i + 1; j < turfPolygons.length; j++) {
      if (intersect(turfPolygons[i], turfPolygons[j])) {
        return true; // Polygons intersect
      }
    }
  }

  return false; // Polygons do not intersect
}

export const getZoomLevelForPolygon = (polygon) => {
  // Calculate bounding box of polygon
  const latitudes = polygon.map((vertex) => vertex.lat);
  const longitudes = polygon.map((vertex) => vertex.lng);
  const minLat = Math.min(...latitudes);
  const maxLat = Math.max(...latitudes);
  const minLng = Math.min(...longitudes);
  const maxLng = Math.max(...longitudes);

  // Calculate appropriate zoom level based on bounding box
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 20;

  const ne = { lat: maxLat, lng: maxLng };
  const sw = { lat: minLat, lng: minLng };

  const latFraction = (getRadians(ne.lat) - getRadians(sw.lat)) / Math.PI;
  const lngDiff = ne.lng - sw.lng;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(WORLD_DIM.height, latFraction);
  const lngZoom = zoom(WORLD_DIM.width, lngFraction);

  const zoomLevel = Math.min(
    Math.max(Math.min(latZoom, lngZoom), 10),
    ZOOM_MAX
  );

  return zoomLevel;
};

const getRadians = (degrees) => {
  return (degrees * Math.PI) / 180;
};

const zoom = (mapPx, fraction) => {
  return Math.floor(Math.log(mapPx / 256 / fraction) / Math.LN2);
};

export const getRandomHexColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215);
  const hexColor = randomColor.toString(16).padStart(6, '0');
  return '#' + hexColor;
};

export const formatPolygonCoordinates = (polygonRef) => {
  const coordinates =
    polygonRef?.current?.state?.polygon
      ?.getPath()
      ?.getArray()
      ?.map((latLng) => [latLng.lng(), latLng.lat()]) || [];

  if (!isEqual(coordinates[0], last(coordinates))) {
    coordinates.push(coordinates[0]);
  }

  return coordinates;
};

export const simplifyPoints = (points, tolerance = 0.0001) => {
  const geoJson = polygon([[...points.map((point) => [point.lng, point.lat])]]);
  const simplified = simplify(geoJson, { tolerance, highQuality: true });

  return simplified.geometry.coordinates[0].map((coord) => ({
    lat: coord[1],
    lng: coord[0]
  }));
};
