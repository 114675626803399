import DatePicker from 'components/OpsControl/FilterComponents/DatePicker';
import HubSelection from 'components/OpsControl/FilterComponents/HubSelection';

import './PendingTransitFilter.less';
import classNames from '../../../../../node_modules/classnames/index';

const PendingTransitFilter = ({
  intl,
  selectedDate,
  allHubs,
  currentHub,
  setCurrentHub,
  loading,
  handleHubChange,
  changeDateValue,
  hubSelectionPlaceholder,
  isHubFilterDisabled
}) => {
  return (
    <div
      className={classNames('br-pending-transit-filter__container', {
        'br-pending-transit-filter__hidden-hubs-container': !allHubs
      })}
    >
      {allHubs && (
        <HubSelection
          handleHubChange={handleHubChange}
          loading={loading}
          intl={intl}
          allHubs={allHubs}
          setCurrentHub={setCurrentHub}
          currentHub={currentHub}
          hubSelectionPlaceholder={hubSelectionPlaceholder}
          isHubFilterDisabled={isHubFilterDisabled}
        />
      )}
      {changeDateValue && (
        <DatePicker
          loading={loading}
          selectedDate={selectedDate}
          setSelectedDate={changeDateValue}
        />
      )}
    </div>
  );
};
export default PendingTransitFilter;
