import { useIntl } from 'react-intl';

import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX, PAGES_ACCESS } from 'common/permissions';
import { isAdminGroupUser, isUserAuthorized } from 'utils/helpers';
import { openModal } from 'utils/modal';
import {
  confirmTeamMemeberInfoValidation,
  editTeamMemeberInfoValidation
} from 'services/business';

import BROTPCodeModal from 'components/BROTPCodeModal/BROTPCodeModal';
import EditPhoneEmailModal from 'components/Businesses/EditPhoneEmailModal/EditPhoneEmailModal';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as EditIcon } from 'assets/bosta-icons/Edit.svg';

const isHaveRoleToEdit = isUserAuthorized(
  aclMatrix.BUSINESSES_EDIT,
  PAGES_ACCESS.BUSINESS_EDIT
);

const EditPhoneButton = ({ item, refreshData }) => {
  const intl = useIntl();
  const hideButton =
    (isAdminGroupUser()
      ? !isUserAuthorized(aclMatrix.BUSINESSES_EDIT, [
          ACL_MATRIX.BUSINESS_EDIT_TEAM_MEMBER_EMAIL
        ])
      : !isHaveRoleToEdit) ||
    !item?.emails[0]?.address ||
    !item?.profile;

  const handleEditPhone = () => {
    openModal(BROTPCodeModal, {
      title: intl.formatMessage({
        id: 'business_details.team_members.otp_screens.business_validation.edit_phone.title'
      }),
      subtitle: intl.formatMessage({
        id: 'business_details.team_members.otp_screens.business_validation.edit_phone.subtitle'
      }),
      confirmOTP: (otp) =>
        confirmTeamMemeberInfoValidation({
          _id: item._id,
          contact: 'phone',
          otp
        }),
      generateOTP: () =>
        editTeamMemeberInfoValidation({
          _id: item._id,
          contact: 'phone'
        }),
      onSuccess: () => {
        openModal(EditPhoneEmailModal, {
          newPhone: true,
          memeberId: item._id,
          refreshData
        });
      }
    });
  };

  if (hideButton) {
    return null;
  }

  return (
    <BRButton
      type="table-actions"
      prefixIcon={<EditIcon />}
      onClick={handleEditPhone}
    />
  );
};

export default EditPhoneButton;
