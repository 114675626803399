import {
  BUSINESS_LOGS_TABLE_COLUMNS,
  LogsTimelineCells
} from 'constants/business-details';

import BRInformationTable from 'components/BRInformationTable/BRInformationTable';
import BRTable from 'components/BRTable/BRTable';

const LogsTimeline = ({ logsTimelineInfo }) => {
  const { formattedLogs } = logsTimelineInfo;

  const renderLogsTable = () => (
    <BRTable
      listingData={formattedLogs}
      columns={BUSINESS_LOGS_TABLE_COLUMNS}
      showFilter={false}
      pageLimit={5}
      pageSizeOptions={[5, 10, 20]}
    />
  );

  return (
    !!formattedLogs.length && (
      <BRInformationTable
        cells={LogsTimelineCells(renderLogsTable)}
        totalCol={1}
      />
    )
  );
};

export default LogsTimeline;
