import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { openModal } from 'utils/modal';
import { cleanEmptyString } from 'utils/helpers';
import { POLYGON } from 'constants/dynamic-routing';
import {
  createStreetCode,
  deleteStreetCode,
  getStreetCodes,
  updateStreetCode
} from 'services/zoning';
import { getHubStars } from 'services/hubs';
import { formatPolygonCoordinates } from 'utils/bosta-coding';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import CodesGeofencingSelectors from './components/CodesGeofencingSelectors/CodesGeofencingSelectors';
import CodesGeofencingMap from './components/CodesGeofencingMap/CodesGeofencingMap';
import CreateEditCodeModal from './components/CreateEditCodeModal/CreateEditCodeModal';
import { notify } from 'components/Notify/Notify';

import './CodesGeofencing.less';

const CodesGeofencing = () => {
  const [selectedHubId, setSelectedHubId] = useState(null);
  const [hubZones, setHubZones] = useState([]);
  const [hubStars, setHubStars] = useState([]);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [zoneCodes, setZoneCodes] = useState([]);
  const [selectedStreetCode, setSelectedStreetCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreatingCodeEnabled, setIsCreatingCodeEnabled] = useState(false);
  const [isDrawingCodeGeofencing, setIsDrawingCodeGeofencing] = useState(false);
  const [selectedCodeGeometry, setSelectedCodeGeometry] = useState([]);
  const [showAllCodes, setShowAllCodes] = useState(false);
  const [otherStreetCodesGeometry, setOtherStreetCodesGeometry] = useState([]);
  const [codeGeofencingErrors, setCodeGeofencingErrors] = useState({
    containOutsidePoints: false,
    polygonsIntersecting: false
  });

  const codePolygonRef = useRef();

  const intl = useIntl();

  useEffect(() => {
    if (selectedStreetCode) {
      setIsCreatingCodeEnabled(false);
      setIsDrawingCodeGeofencing(false);

      const streetCode = zoneCodes.find(
        (code) => code._id === selectedStreetCode
      );

      streetCode.geometry
        ? setSelectedCodeGeometry(
            streetCode.geometry.coordinates[0].map((coordinates) => ({
              lat: coordinates[1],
              lng: coordinates[0]
            }))
          )
        : setSelectedCodeGeometry([]);
    }
  }, [selectedStreetCode]);

  useEffect(() => {
    if (selectedHubId) {
      setShowAllCodes(false);
      setOtherStreetCodesGeometry([]);
      setSelectedZoneId(null);
      handleReset();
      fetchHubStars();
    }
  }, [selectedHubId]);

  useEffect(() => {
    if (selectedZoneId) {
      handleReset();
      setShowAllCodes(false);
      setOtherStreetCodesGeometry([]);
    }
  }, [selectedZoneId]);

  useEffect(() => {
    if (showAllCodes) {
      const otherStreetCodes = zoneCodes.filter(
        (streetCode) => streetCode._id !== selectedStreetCode
      );

      const streetCodesGeometry = otherStreetCodes.map((streetCode) => {
        if (streetCode.geometry) {
          const coordinates = streetCode.geometry.coordinates[0].map(
            (coordinates) => ({
              lat: coordinates[1],
              lng: coordinates[0]
            })
          );

          return {
            ...streetCode,
            coordinates
          };
        }

        return;
      });

      setOtherStreetCodesGeometry(streetCodesGeometry.filter(Boolean));
    } else {
      setOtherStreetCodesGeometry([]);
    }
  }, [showAllCodes, selectedZoneId, selectedStreetCode, zoneCodes]);

  const updateStreetCodeData = () => {
    const selectedStreetCodeIndex = zoneCodes.findIndex(
      (streetCode) => streetCode._id === selectedStreetCode
    );
    const newZoneStreetCodes = [...zoneCodes];
    newZoneStreetCodes[selectedStreetCodeIndex].geometry.coordinates[0] =
      formatPolygonCoordinates(codePolygonRef);

    setZoneCodes(newZoneStreetCodes);
  };

  const handleOnFinish = () => {
    openModal(CreateEditCodeModal, {
      onSubmit: handleOnSubmit,
      isEdit: selectedStreetCode,
      hubStars,
      selectedCode: zoneCodes.find((code) => code._id === selectedStreetCode)
    });
  };

  const filterStreetCodes = (streetCodes = []) => {
    const seen = new Set();
    return streetCodes.filter((area) => {
      const coordinatesStr = JSON.stringify(area.geometry.coordinates);
      if (seen.has(coordinatesStr)) {
        return false;
      }
      seen.add(coordinatesStr);
      return true;
    });
  };

  const fetchStreetCodes = async () => {
    setIsLoading(true);
    try {
      const { data } = await getStreetCodes({
        zoneId: selectedZoneId
      });
      const filteredStreetCodes = filterStreetCodes(data);

      setZoneCodes(filteredStreetCodes);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const fetchHubStars = async () => {
    setIsLoading(true);
    try {
      const { message } = await getHubStars(selectedHubId);
      setHubStars(message);
    } catch (error) {
      setHubStars([]);
    }
    setIsLoading(false);
  };

  const handleDeleteStreetCode = async () => {
    setIsLoading(true);
    try {
      await deleteStreetCode(selectedStreetCode);
      handleReset();
      notify(
        intl.formatMessage({
          id: 'bosta_coding.codes_geofencing.delete_success'
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleOnSubmit = async (values) => {
    setIsLoading(true);
    try {
      const payload = {
        warehouseId: selectedHubId,
        zoneId: selectedZoneId,
        geometry: {
          coordinates: [formatPolygonCoordinates(codePolygonRef)],
          type: POLYGON
        },
        ...values
      };

      selectedStreetCode
        ? await updateStreetCode({
            streetCodeId: selectedStreetCode,
            payload: cleanEmptyString(payload)
          })
        : await createStreetCode(cleanEmptyString(payload));

      if (selectedStreetCode) {
        updateStreetCodeData();
      }

      handleReset();
      notify(
        intl.formatMessage({
          id: `bosta_coding.codes_geofencing.${
            selectedStreetCode ? 'update_success' : 'creation_success'
          }`
        }),
        'success'
      );
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleReset = () => {
    setSelectedCodeGeometry([]);
    setIsDrawingCodeGeofencing(false);
    setSelectedStreetCode(null);
    fetchStreetCodes();
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-codes-geofencing__container">
        <CodesGeofencingSelectors
          selectedHubId={selectedHubId}
          setSelectedHubId={setSelectedHubId}
          hubZones={hubZones}
          setHubZones={setHubZones}
          selectedZoneId={selectedZoneId}
          setSelectedZoneId={setSelectedZoneId}
          zoneCodes={zoneCodes}
          isCreatingCodeEnabled={isCreatingCodeEnabled}
          setIsCreatingCodeEnabled={setIsCreatingCodeEnabled}
          isDrawingCodeGeofencing={isDrawingCodeGeofencing}
          setIsDrawingCodeGeofencing={setIsDrawingCodeGeofencing}
          setSelectedCodeGeometry={setSelectedCodeGeometry}
          codeGeofencingErrors={codeGeofencingErrors}
          selectedStreetCode={selectedStreetCode}
          setSelectedStreetCode={setSelectedStreetCode}
          handleOnFinish={handleOnFinish}
          fetchStreetCodes={fetchStreetCodes}
          setShowAllCodes={setShowAllCodes}
          showAllCodes={showAllCodes}
          handleDeleteStreetCode={handleDeleteStreetCode}
        />
        <CodesGeofencingMap
          selectedZoneId={selectedZoneId}
          hubZones={hubZones}
          isCreatingCodeEnabled={isCreatingCodeEnabled}
          setIsCreatingCodeEnabled={setIsCreatingCodeEnabled}
          isDrawingCodeGeofencing={isDrawingCodeGeofencing}
          setIsDrawingCodeGeofencing={setIsDrawingCodeGeofencing}
          selectedCodeGeometry={selectedCodeGeometry}
          setSelectedCodeGeometry={setSelectedCodeGeometry}
          codePolygonRef={codePolygonRef}
          setCodeGeofencingErrors={setCodeGeofencingErrors}
          otherStreetCodesGeometry={otherStreetCodesGeometry}
          setSelectedStreetCode={setSelectedStreetCode}
          selectedStreetCode={selectedStreetCode}
          zoneCodes={zoneCodes}
        />
      </div>
    </LoadingWrapper>
  );
};

export default CodesGeofencing;
