import { useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';

import { NUMBER_TEXT } from 'constants/form';
import { updateBusinessConfigurations } from 'services/business';

import BRButton from 'components/BRButton/BRButton';
import BRFormInputs from 'components/BRFormInputs/BRFormInputs';

import './BostaCreditLimit.less';

const BostaCreditLimit = ({
  minimumBostaCreditsAmountForOrderCreation,
  submitBusinessUpdate,
  intl
}) => {
  const formRef = useRef();

  const onFinish = (values) => {
    const payload = {
      bostaCredits: {
        minimumAmountForOrderCreation:
          values?.minimumBostaCreditsAmountForOrderCreation || 0
      }
    };

    submitBusinessUpdate(payload, { callback: updateBusinessConfigurations });
  };

  useEffect(() => {
    formRef.current.setFieldsValue({
      minimumBostaCreditsAmountForOrderCreation
    });
  }, [minimumBostaCreditsAmountForOrderCreation]);

  return (
    <div className="br-business__bosta-credit-limit-form">
      <Form onFinish={onFinish} ref={formRef}>
        <BRFormInputs
          formRef={formRef}
          name="minimumBostaCreditsAmountForOrderCreation"
          label={intl.formatMessage({
            id: 'businesses.details.manageBusiness.bosta_credit_limits'
          })}
          type={NUMBER_TEXT}
        />
        <BRButton
          type="primary"
          htmlType="submit"
          label={intl.formatMessage({ id: 'common.edit' })}
        />
      </Form>
    </div>
  );
};

export default injectIntl(BostaCreditLimit);
