import { useRef, useState } from 'react';
import { Form } from 'antd';

import DistrictsMappingFilters from 'components/BostaCoding/components/DistrictsMapping/components/DistrictsMappingFilters/DistrictsMappingFilters';
import DistrictsMappingMap from 'components/BostaCoding/components/DistrictsMapping/components/DistrictsMappingMap/DistrictsMappingMap';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './DistrictsMapping.less';

const DistrictsMapping = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hubStarsActionsPolygons, setHubStarsActionsPolygons] = useState([]);
  const [existingHubStreetCodesPolygons, setExistingHubStreetCodesPolygons] =
    useState([]);
  const [selectedPolygonIndex, setSelectedPolygonIndex] = useState(null);

  const polygonRef = useRef();

  const [form] = Form.useForm();

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-districts-mapping__container">
        <Form form={form}>
          <DistrictsMappingFilters
            form={form}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            setHubStarsActionsPolygons={setHubStarsActionsPolygons}
            setExistingHubStreetCodesPolygons={
              setExistingHubStreetCodesPolygons
            }
            selectedPolygonIndex={selectedPolygonIndex}
            polygonRef={polygonRef}
          />
          <DistrictsMappingMap
            form={form}
            hubStarsActionsPolygons={hubStarsActionsPolygons}
            existingHubStreetCodesPolygons={existingHubStreetCodesPolygons}
            setSelectedPolygonIndex={setSelectedPolygonIndex}
            selectedPolygonIndex={selectedPolygonIndex}
            polygonRef={polygonRef}
          />
        </Form>
      </div>
    </LoadingWrapper>
  );
};

export default DistrictsMapping;
