import { Modal } from 'antd';
import { useIntl } from 'react-intl';

import './WrongZoneConfirmationModal.less';

const WrongZoneConfirmationModal = ({
  icon,
  title,
  close,
  children,
  onSuccess = () => {},
  disableConfirmButton = false,
  ...props
}) => {
  const intl = useIntl();

  const handleOk = () => {
    close();
    onSuccess();
  };

  return (
    <Modal
      {...props}
      className="br-ops-control-wrong-zone__confirmation-modal"
      onCancel={close}
      width={408}
      title={
        <>
          <div>{icon}</div>
          {title}
        </>
      }
      okText={intl.formatMessage({ id: 'common.confirm' })}
      onOk={handleOk}
      okButtonProps={{
        disabled: disableConfirmButton
      }}
    >
      {children}
    </Modal>
  );
};

export default WrongZoneConfirmationModal;
