import { createContext } from 'react';

export const HubsContext = createContext({
  allHubs: [],
  scopedHubs: []
});

export const ReceiveFromBusinessContext = createContext({
  packages: [],
  removePackage: () => {},
  businessInfo: {},
  businessContactPersons: [],
  loading: false,
  allowSubmit: true,
  form: {}
});
