import React from 'react';
import { withRouter, Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { isUserAuthorized } from 'utils/helpers';
import aclMatrix from 'common/aclMatrix';
import { ACL_MATRIX } from 'common/permissions';

import HubManagement from 'containers/HubManagment/hubManagment';
import ManageDeliveries from './ManageDeliveries';
import moneyDebriefingReport from './moneyDebriefingReport';
import hubTransfer from './hubTransfer';
import UpdateCod from 'containers/HubManagment/UpdateCod';
import ReceivedByMistake from 'components/HubManagment/ReceivedByMistake/ReceivedByMistake';

const listRoutes = [
  {
    path: '/hubs/hub-management/all-hubs',
    text: 'All Hubs',
    component: HubManagement,
    access: aclMatrix.HUB_MANAGEMENT_TABS_EXCEPT_MONEY_DEBREIF_REPORT,
    permission: [ACL_MATRIX.HUBS_CREATE]
  },
  {
    path: '/hubs/hub-management/money-debriefing-report',
    text: 'Money Debriefing Report',
    component: moneyDebriefingReport,
    access: aclMatrix.HUB_MANAGEMENT,
    permission: [ACL_MATRIX.CASH_MONEY_GET_MONEY_DEBRIEFING_REPORT]
  },
  {
    path: '/hubs/hub-management/transfers',
    text: 'Hub Transfers',
    component: hubTransfer,
    access: aclMatrix.HUB_MANAGEMENT_TABS_EXCEPT_MONEY_DEBREIF_REPORT,
    permission: [ACL_MATRIX.HUBS_TRANSFER_LIST]
  },
  {
    path: '/hubs/hub-management/deliveries',
    text: 'Manage Deliveries',
    component: ManageDeliveries,
    access: aclMatrix.HUB_MANAGEMENT_TABS_EXCEPT_MONEY_DEBREIF_REPORT,
    permission: [ACL_MATRIX.DELIVERY_MARK_AS_AUTO_DELIVERED]
  },
  {
    path: '/hubs/hub-management/received-by-mistake',
    text: 'Received by Mistake',
    component: ReceivedByMistake,
    access: aclMatrix.RECEIVED_BY_MISTAKE,
    permission: [ACL_MATRIX.DELIVERY_MARK_AS_RECEIVED_BY_MISTAKE]
  },
  {
    path: '/hubs/hub-management/update-cod',
    text: 'Update Cod',
    component: UpdateCod,
    permission: [ACL_MATRIX.DELIVERY_UPDATE_COD_SKIPPING_CHECKS]
  }
];

const getDefaultTabPath = () => {
  let defaultPathName = '/hubs/hub-management/money-debriefing-report';

  listRoutes.some((route) => {
    if (isUserAuthorized(route.access, route.permission)) {
      defaultPathName = route.path;
      return true;
    }
  });

  return defaultPathName;
};

class HubManagmentRoutes extends React.Component {
  render() {
    const {
      match: { path }
    } = this.props;

    return (
      <Switch>
        {listRoutes.map(
          (route) =>
            isUserAuthorized(route.access, route.permission) && (
              <Route path={[route.path]} component={route?.component} />
            )
        )}
        <Redirect from={path} to={getDefaultTabPath()} />
      </Switch>
    );
  }
}

export default injectIntl(withRouter(HubManagmentRoutes));
