import { useState, useRef, useCallback, useEffect } from 'react';
import { Modal, Form } from 'antd';
import { injectIntl } from 'react-intl';
import debounce from 'lodash/debounce';

import {
  COMPENSATION_REASONS,
  COMPENSATION_TYPE
} from 'constants/compensation';
import { createCompensationRecord } from 'services/wallet';
import { calculateCompensationAmount } from 'services/compensation';
import { getBusiness } from 'services/business';

import { notify } from 'components/Notify/Notify';
import CompensationForm from 'components/AddCompensationFormModal/components/CompensationInfoSection/CompensationForm';

import './AddCompensationFormModal.less';

const AddCompensationFormModal = ({
  onSuccess,
  close,
  onSumbit,
  intl,
  delivery,
  ...props
}) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [eachDelivery, setEachDelivery] = useState({});
  const [amountLoading, setAmountLoading] = useState(false);
  const [selectedDeliveries, setSelectedDeliveries] = useState([]);
  const [selectedReasonObj, setSelectedReasonObj] = useState(null);
  const [departmentsPerReason, setDepartmentsPerReason] = useState([]);
  const [deparments, setDepartments] = useState([]);
  const [businessDetails, setBusinessDetails] = useState({});

  const formRef = useRef('');
  const [form] = Form.useForm();

  useEffect(() => {
    if (delivery?.sender?._id) {
      getBusinessDetails();
    }
  }, [delivery]);

  const handleOnTrackClick = () => {
    window.open(`/wallet/compensation/all`, '_blank');
  };

  const calculate = async () => {
    const compensationType = formRef.current.getFieldValue('compensationType');
    const sizeConfirmation = formRef.current.getFieldValue('sizeConfirmation');
    const compensationReason =
      formRef.current.getFieldValue('compensationReason');
    const assignedDepartmentsValues = formRef.current.getFieldValue(
      'assignedDepartments'
    );
    const noOfFreeOrder = formRef.current.getFieldValue('noOfFreeOrder') || {
      [delivery.trackingNumber]: 1
    };

    if (!compensationType || !compensationReason) {
      setAmountLoading(false);
      return;
    }

    if (
      compensationReason === COMPENSATION_REASONS.WRONG_PACKAGE_SIZE &&
      !sizeConfirmation
    ) {
      setAmountLoading(false);
      return;
    }

    let totalFromDepartments = 0;

    if (assignedDepartmentsValues) {
      Object.values(assignedDepartmentsValues).forEach((val) => {
        totalFromDepartments += parseFloat(val?.compensationAmount || 0);
      });
    }

    let deliveries;
    let payload;

    if (compensationType === COMPENSATION_TYPE.FREE) {
      deliveries = Object.keys(noOfFreeOrder).map((key) => ({
        trackingNumber: key,
        orderMultiples: noOfFreeOrder[key]
      }));

      payload = {
        deliveriesObject: deliveries,
        params: {
          type: COMPENSATION_TYPE.FREE,
          reason: compensationReason,
          ...(sizeConfirmation && {
            selectedSize: sizeConfirmation
          })
        }
      };
    } else {
      deliveries = Object.keys(noOfFreeOrder).map((key) => ({
        trackingNumber: key
      }));

      payload = {
        deliveriesObject: deliveries,
        params: {
          type: COMPENSATION_TYPE.CREDIT,
          reason: compensationReason,
          ...(sizeConfirmation && {
            selectedSize: sizeConfirmation
          }),
          compensationAmount: totalFromDepartments
        }
      };

      if (compensationReason !== COMPENSATION_REASONS.WRONG_PACKAGE_SIZE) {
        setTotalAmount(totalFromDepartments);
        setAmountLoading(false);
        return;
      }
    }

    setAmountLoading(true);
    try {
      const { totalAmount, deliveriesAmount } =
        await calculateCompensationAmount(payload);

      setEachDelivery(deliveriesAmount);
      setTotalAmount(parseFloat(totalAmount).toFixed(2) || 0);

      if (compensationReason === COMPENSATION_REASONS.WRONG_PACKAGE_SIZE) {
        formRef.current.setFieldsValue({
          assignedDepartments: {
            ...assignedDepartmentsValues,
            OPs: {
              ...assignedDepartmentsValues.OPs,
              compensationAmount: parseFloat(totalAmount).toFixed(2)
            }
          }
        });
      }
    } catch (error) {
      notify(error.message);
      setTotalAmount(0);
    }
    setAmountLoading(false);
  };

  const getBusinessDetails = async () => {
    try {
      const data = await getBusiness(delivery.sender._id);
      setBusinessDetails(data);
    } catch (error) {
      notify(error.message);
    }
  };

  const handleFormChange = () => {
    setAmountLoading(true);
    debouncedCalculate();
  };

  const debouncedCalculate = useCallback(
    debounce(async () => {
      try {
        calculate();
      } catch (error) {}
    }, 1600),
    [form]
  );

  useEffect(() => {
    // Cleanup function to cancel debounce
    return () => {
      debouncedCalculate.cancel();
    };
  }, [debouncedCalculate]);

  const handleOnOk = async (values) => {
    const {
      ticketUrl,
      compensationType,
      orderId,
      noOfFreeOrder,
      assignedDepartments,
      additionalApproval
    } = values;

    const filledDepartments = deparments.filter(
      (dep) => !!assignedDepartments[dep.name]
    );

    let eachOrderTotalAmount = {};
    selectedDeliveries.forEach((delivery) => {
      eachOrderTotalAmount[delivery?.trackingNumber] =
        delivery?.pricing?.priceAfterVat *
        parseInt(noOfFreeOrder?.[delivery?.trackingNumber] || 1);
    });

    try {
      const payload = {
        compensation: {
          reasonDetails: {
            ticketUrl,
            reasonText: selectedReasonObj?.reason,
            reasonId: selectedReasonObj?._id,
            ...(selectedReasonObj?.reason ===
              COMPENSATION_REASONS.WRONG_PACKAGE_SIZE && {
              confirmationSize:
                formRef?.current?.getFieldValue('sizeConfirmation')
            })
          },
          businessInfo: {
            businessName: delivery?.sender?.name,
            businessId: delivery?.sender?._id
          },
          deliveries: [
            ...(compensationType === COMPENSATION_TYPE.FREE
              ? Object.keys(noOfFreeOrder)?.map((tn) => {
                  return {
                    trackingNumber: tn,
                    totalAmount: eachOrderTotalAmount[tn],
                    liabilityType: compensationType,
                    liabilityFactor: noOfFreeOrder[tn]
                  };
                })
              : orderId
                  ?.replace(' ', '')
                  .split(',')
                  .map((tn) => {
                    return {
                      trackingNumber: tn,
                      totalAmount:
                        selectedReasonObj?.reason ===
                        COMPENSATION_REASONS.WRONG_PACKAGE_SIZE
                          ? parseFloat(eachDelivery[tn]).toFixed(2)
                          : parseFloat(totalAmount).toFixed(2),
                      liabilityType: compensationType
                    };
                  }))
          ],
          assignedDepartments: [
            ...filledDepartments.map(({ name, _id }) => {
              const mappedDep = assignedDepartments[name];
              return {
                approvedBy: mappedDep?.approvedBy,
                departmentName: name,
                departmentId: _id,
                assignedOn: mappedDep?.assignedOn,
                contractorName: mappedDep?.contractorName,
                liability: {
                  hasLiability:
                    compensationType === COMPENSATION_TYPE.FREE ? false : true,
                  liabilityAmount:
                    compensationType === COMPENSATION_TYPE.FREE
                      ? 0
                      : mappedDep?.compensationAmount,
                  liabilityOn: mappedDep?.liability
                    ? [mappedDep?.liability]
                    : [],
                  ...(mappedDep?.hub && {
                    liabilityHubs: mappedDep.hub
                  })
                },
                reason: mappedDep?.investigationReason
              };
            })
          ],
          additionalApproval: {
            isMDApproved: additionalApproval?.includes('MD') || false,
            isCFOApproved: additionalApproval?.includes('CFO') || false
          }
        }
      };

      await createCompensationRecord(payload);
      notify(
        <div className="br-compensation-form-modal__success-message">
          {intl.formatMessage(
            { id: 'request_compensation_modal.success_message' },
            {
              trackRequest: (
                <span
                  className="br-compensation__track-request-message"
                  onClick={handleOnTrackClick}
                >
                  {intl.formatMessage({
                    id: 'request_compensation_modal.track_request'
                  })}
                </span>
              )
            }
          )}
        </div>,
        'success',
        false,
        1,
        'br-compensation__success-notify-message'
      );
      close();
    } catch (error) {
      notify(error.message);
    }
  };

  return (
    <Modal
      onCancel={close}
      {...props}
      title="Request compensation"
      width={null}
      okText="Confirm"
      className="br-add-compensation-form-modal__container"
      okButtonProps={{
        htmlType: 'submit',
        form: 'compensationInfoForm',
        disabled: amountLoading || totalAmount <= 0
      }}
    >
      <CompensationForm
        form={form}
        formRef={formRef}
        delivery={delivery}
        onSubmit={handleOnOk}
        handleOnChange={handleFormChange}
        totalAmount={totalAmount}
        amountLoading={amountLoading}
        selectedReasonObj={selectedReasonObj}
        setSelectedReasonObj={setSelectedReasonObj}
        departmentsPerReason={departmentsPerReason}
        setDepartmentsPerReason={setDepartmentsPerReason}
        deparments={deparments}
        setDepartments={setDepartments}
        selectedDeliveries={selectedDeliveries}
        setSelectedDeliveries={setSelectedDeliveries}
        recalcuate={debouncedCalculate}
        businessDetails={businessDetails}
      />
    </Modal>
  );
};

export default injectIntl(AddCompensationFormModal);
