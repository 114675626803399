import { useEffect, useState } from 'react';
import { Switch } from 'antd';

import { openModal } from 'utils/modal';

const BusinessSwitch = ({
  initialChecked = false,
  disabled = false,
  onChange = () => {},
  confirmationModal = {},
  ...rest
}) => {
  const [isChecked, setChecked] = useState(initialChecked);

  const { modal, modalProps = () => {} } = confirmationModal;

  const onChangeOptions = (value) => ({
    value,
    onSuccess: () => setChecked(value),
    onError: () => setChecked(!value)
  });

  const handleSwitchChange = (checked) => {
    const options = onChangeOptions(checked);

    if (confirmationModal.modal) {
      openModal(modal, {
        ...modalProps(options)
      });
    } else {
      setChecked(checked);

      onChange(options);
    }
  };

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  return (
    <Switch
      {...rest}
      checked={isChecked}
      onChange={handleSwitchChange}
      disabled={disabled}
    />
  );
};

export default BusinessSwitch;
