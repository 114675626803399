import { useIntl } from 'react-intl';
import { Card, Input } from 'antd';

import { useReceiveFromBusinessContext } from 'customHooks';

import BusinessInfoHeader from './components/BusinessInfoHeader/BusinessInfoHeader';
import ContactPersonSelect from './components/ContactPersonSelect/ContactPersonSelect';

import { ReactComponent as ShopIcon } from 'assets/bosta-icons/Shop.svg';

import './BusinessInfo.less';

const BusinessInfo = () => {
  const intl = useIntl();
  const { businessInfo } = useReceiveFromBusinessContext();

  return (
    <Card
      title={<BusinessInfoHeader />}
      className="br-hubs-receive-from-business__pickup-scanning__business-info"
    >
      <div className="pickup-scanning__business-info__business-name">
        <label htmlFor="businessName">
          {intl.formatMessage({
            id: 'hubs.receive_from_business.pickup_scanning.business_info.business_name'
          })}
        </label>

        <Input
          disabled
          value={businessInfo?.name}
          prefix={<ShopIcon />}
          name="businessName"
        />
      </div>

      <ContactPersonSelect />
    </Card>
  );
};

export default BusinessInfo;
