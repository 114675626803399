import BusinessSwitch from '../BusinessSwitch';

const BusinessThirdAttemptSwitch = ({
  businessData = {},
  submitBusinessUpdate
}) => {
  const isThirdAttemptDisabled = !businessData.limitNumberOfAttempts;

  const switchBusinessThirdAttempt = ({
    value: limitNumberOfAttempts,
    ...options
  }) =>
    submitBusinessUpdate(
      { limitNumberOfAttempts: !limitNumberOfAttempts },
      options
    );

  return (
    <BusinessSwitch
      initialChecked={isThirdAttemptDisabled}
      onChange={switchBusinessThirdAttempt}
    />
  );
};

export default BusinessThirdAttemptSwitch;
