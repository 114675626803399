import { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { Form, Select, Button, DatePicker, Skeleton } from 'antd';
import dayjs from 'dayjs';

import {
  getStarContractors,
  getAllHubs,
  getCycleDetails,
  exportStarsCycleDetailTable,
  exportStarsMonthlyCycleDetailTable,
  getAllStars
} from 'services/stars';
import {
  STAR_SUMMARY_CARDS,
  STAR_CYCLE_DETAILS_COLUMNS
} from 'constants/stars';
import { cleanEmptyString, formatBigMoneyValues } from 'utils/helpers';
import { DD_MM_YYYY, YYYY_MM_DD } from 'constants/wallet';

import { notify } from 'components/Notify/Notify';
import Container from 'components/Container';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRHeader from 'components/BRHeader/BRHeader';
import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';

import './StarSalary.less';

const StarSalary = ({ intl, location: { state } }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stars, setStars] = useState([]);
  const [allContractors, setAllContractors] = useState([]);
  const [allHubs, setHubs] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [cyclesData, setCyclesDate] = useState({ all: {}, summary: {} });
  const [dates, setDates] = useState([]);
  const [firstSelectedRange, setFirstSelectedRange] = useState('start');

  const formRef = useRef('');
  const exportFormRef = useRef('');
  const refreshCycleTable = useRef();

  const acceptMethods = (refreshMethod) => {
    refreshCycleTable.current = refreshMethod;
  };

  const handleResetFilter = async () => {
    formRef.current.resetFields();
    await setFilterValues({});
    refreshCycleTable.current();
  };

  const getHubs = async () => {
    try {
      setIsLoading(true);
      const { result } = await getAllHubs();
      setHubs(result);
      setIsLoading(false);
      return result;
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const fetchAllStars = useCallback(async () => {
    try {
      setIsLoading(true);
      const {
        message: { stars }
      } = await getAllStars({ verified: true });
      setStars(stars);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  }, []);

  const getContractors = async () => {
    try {
      setIsLoading(true);
      const { message } = await getStarContractors();
      setAllContractors(message || []);
    } catch (err) {
      notify(err.message);
    }
    setIsLoading(false);
  };

  const getStarCycleDetails = useCallback(
    async ({ pageId, pageLimit }) => {
      try {
        setIsLoading(true);
        const payload = {
          limit: pageLimit,
          page: pageId,
          ...filterValues
        };
        const { data } = await getCycleDetails(payload);
        setCyclesDate(data);
        setIsLoading(false);
        return {
          list: data?.all || [],
          total: data?.count || 0
        };
      } catch (error) {
        notify(error.message);
        setIsLoading(false);
      }
    },
    [filterValues]
  );

  const handleFormSubmit = async (value) => {
    if (value?.date?.length) {
      value.start_date = value.date[0].format(YYYY_MM_DD);
      value.end_date = value.date[1].format(YYYY_MM_DD);
      delete value.date;
    }
    setFilterValues(cleanEmptyString(value));
    refreshCycleTable.current();
  };

  const handleExportedFile = async () => {
    const payload = filterValues;
    setIsLoading(true);
    try {
      const res = await exportStarsCycleDetailTable(payload);
      notify(res.msg, 'success');
    } catch (e) {
      notify(e.message);
    }
    setIsLoading(false);
  };

  const exportByMonth = async ({ selectedMonth }) => {
    const month_date = dayjs(selectedMonth).format(YYYY_MM_DD);
    try {
      const res = await exportStarsMonthlyCycleDetailTable({ month_date });
      notify(res.message, 'success');
    } catch (e) {
      notify(e.message);
    }
  };

  const disabledMonths = (current) => {
    const endDate = dayjs().startOf('month');
    return current && current.isSameOrAfter(endDate, 'month');
  };

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }

    const tooLate = dates[0] && !current.isSame(dates[0], 'months');
    const tooEarly = dates[1] && !current.isSame(dates[1], 'months');
    return tooEarly || tooLate;
  };

  const onOpenChange = async (open) => {
    if (open) {
      await setDates([]);
    } else {
      const dates = formRef.current.getFieldValue('date');
      if (firstSelectedRange === 'start') {
        if (dates?.length && !dates[0].isSame(dates[1], 'months')) {
          formRef.current.setFieldsValue({
            date: [dates[0], dayjs(dates[0]).endOf('month')]
          });
        }
      } else {
        if (dates?.length && !dates[1].isSame(dates[0], 'months')) {
          formRef.current.setFieldsValue({
            date: [dayjs(dates[1]).startOf('month'), dates[1]]
          });
        }
      }
    }
  };

  const handleOnCalenderChange = (val, _, { range }) => {
    setFirstSelectedRange(range);
    setDates(range === 'start' ? [val[0], null] : [null, val[1]]);
  };

  useEffect(() => {
    fetchAllStars();
    getContractors();
    getHubs();
  }, []);

  return (
    <LoadingWrapper loading={isLoading}>
      <Container
        header={
          <BRHeader
            title={intl.formatMessage({
              id: `sidebar.star_salary`
            })}
          />
        }
        content={
          <div className="br-cycle-details">
            <Form onFinish={handleFormSubmit} ref={formRef} scrollToFirstError>
              <div className="display-flex br-cycle-details__filter">
                <Form.Item
                  name="star_id"
                  label={intl.formatMessage({
                    id: `star_cycles.cycle_details.form_labels.star`
                  })}
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: `hubs.packages_debrief.select`
                    })}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        ?.join('')
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    getPopupContainer={(trigger) => trigger.parentElement}
                    loading={isLoading}
                    allowClear
                  >
                    {stars.map((star) => (
                      <Select.Option key={star._id} value={star._id}>
                        {star.profile.firstName} {star.profile.lastName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({
                    id: `star_cycles.cycle_details.form_labels.contractor`
                  })}
                  name="contractor_id"
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: `form.select_placeholder`
                    })}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    autoComplete={`${Math.random()}`}
                    loading={isLoading}
                    allowClear
                  >
                    {allContractors.map((contractor) => (
                      <Select.Option
                        key={contractor._id}
                        value={contractor._id}
                      >
                        {contractor.companyName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="hub_id"
                  label={intl.formatMessage({
                    id: 'stars.create_edit_star.form_labels.hub'
                  })}
                >
                  <Select
                    placeholder={intl.formatMessage({
                      id: `form.select_placeholder`
                    })}
                    showSearch
                    autoComplete={`${Math.random()}`}
                    loading={isLoading}
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allHubs?.map((hub) => (
                      <Select.Option key={hub._id} value={hub._id}>
                        {hub.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="date"
                  label={intl.formatMessage({
                    id: 'stars.create_edit_star.form_labels.date'
                  })}
                >
                  <DatePicker.RangePicker
                    disabledDate={disabledDate}
                    format={[DD_MM_YYYY, DD_MM_YYYY]}
                    onCalendarChange={handleOnCalenderChange}
                    onOpenChange={onOpenChange}
                    defaultValue={[
                      dayjs().clone().startOf('month'),
                      dayjs().clone().endOf('month')
                    ]}
                  />
                </Form.Item>
                <span className="br-cycle-details__filter__actions">
                  <Button onClick={handleResetFilter}>
                    {' '}
                    {intl.formatMessage({
                      id: `star_cycles.cycle_details.actions.clear`
                    })}
                  </Button>
                  <Button type="danger" htmlType="submit">
                    {intl.formatMessage({
                      id: `star_cycles.cycle_details.actions.submit`
                    })}
                  </Button>
                </span>
              </div>
            </Form>
            <div className="br-star-cycles__summary">
              {STAR_SUMMARY_CARDS.map(({ label, icon, suffix }) => (
                <div className="br-star-cycles__summary__card">
                  {/* <Icon component={icon} /> */}
                  <div className="display-flex flex-dir-col">
                    <span className="br-star-cycles__summary__card--label">
                      {intl.formatMessage({
                        id: `star_cycles.${label}`
                      })}
                    </span>
                    {isLoading ? (
                      <Skeleton.Input active={true} />
                    ) : (
                      <>
                        <span className="br-star-cycles__summary__card--value">
                          {(cyclesData?.summary &&
                            formatBigMoneyValues(cyclesData?.summary[label])) ||
                            0}
                          <span className="br-star-cycles__summary__card--value__suffix">
                            {suffix}
                          </span>
                        </span>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <Form
              className="br-star-cycles__export-monthly"
              ref={exportFormRef}
              onFinish={exportByMonth}
            >
              <Form.Item name="selectedMonth">
                <DatePicker
                  picker="month"
                  format="MMMM"
                  disabledDate={disabledMonths}
                />
              </Form.Item>
              <BRButton
                htmlType="submit"
                label={intl.formatMessage({
                  id: `star_cycles.export_by_month`
                })}
              />
            </Form>

            <BRTable
              title={intl.formatMessage(
                {
                  id: `star_cycles.tables.cycle_details_title`
                },
                { count: cyclesData?.count }
              )}
              listFunction={getStarCycleDetails}
              columns={STAR_CYCLE_DETAILS_COLUMNS}
              hideFilterButton
              // exportListFileFunction={() => {}}
              shareMethods={acceptMethods}
              withOutCheckBoxes
              // exportListFileFunction={handleExportedFile}
              showExportIcon={false}
            />
          </div>
        }
      />
    </LoadingWrapper>
  );
};

export default withRouter(injectIntl(StarSalary));
