import { useContext, useEffect, useRef, useState } from 'react';
import { Select, Form, Switch } from 'antd';
import { useIntl } from 'react-intl';

import { HubsContext } from 'contexts/hub.context';
import { getHubZones } from 'services/zoning';
import { openModal } from 'utils/modal';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import ConfirmationModal from 'components/BRModals/ConfirmationModal/ConfirmationModal';

import './CodesGeofencingSelectors.less';

const CodesGeofencingSelectors = ({
  selectedHubId,
  setSelectedHubId,
  hubZones,
  setHubZones,
  selectedZoneId,
  setSelectedZoneId,
  zoneCodes,
  isCreatingCodeEnabled,
  setIsCreatingCodeEnabled,
  isDrawingCodeGeofencing,
  setIsDrawingCodeGeofencing,
  setSelectedCodeGeometry,
  codeGeofencingErrors,
  setSelectedStreetCode,
  selectedStreetCode,
  handleOnFinish,
  fetchStreetCodes,
  setShowAllCodes,
  showAllCodes,
  handleDeleteStreetCode
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { scopedHubs } = useContext(HubsContext);

  const intl = useIntl();

  useEffect(() => {
    if (selectedHubId) {
      fetchHubZones();
    }
  }, [selectedHubId]);

  const fetchHubZones = async () => {
    setIsLoading(true);
    try {
      const hubZones = await getHubZones(selectedHubId);
      setHubZones(hubZones);
    } catch (error) {
      notify(error.message);
    }
    setIsLoading(false);
  };

  const handleCreateCodeClick = () => {
    setSelectedStreetCode(null);
    setSelectedCodeGeometry([]);
    setIsCreatingCodeEnabled(!isCreatingCodeEnabled);

    if (isDrawingCodeGeofencing) {
      setIsDrawingCodeGeofencing(false);
    }
  };

  const handleDeleteStreetCodeClick = () => {
    openModal(ConfirmationModal, {
      title: intl.formatMessage({
        id: 'bosta_coding.codes_geofencing.delete_modal.title'
      }),
      content: intl.formatMessage({
        id: 'bosta_coding.codes_geofencing.delete_modal.content'
      }),
      modalAction: handleDeleteStreetCode
    });
  };

  return (
    <div className="br-bosta-coding-selectors__container">
      <Form onFinish={handleOnFinish}>
        <div className="br-bosta-coding-selectors-row">
          <Form.Item
            label={intl.formatMessage({
              id: 'bosta_coding.zones_geofencing.selectors.hub_label'
            })}
          >
            <Select
              options={scopedHubs}
              showSearch
              onChange={setSelectedHubId}
              optionFilterProp="name"
              placeholder={intl.formatMessage({
                id: 'bosta_coding.zones_geofencing.selectors.hub_placeholder'
              })}
              fieldNames={{ label: 'name', value: '_id' }}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'bosta_coding.zones_geofencing.selectors.zone_label'
            })}
          >
            <Select
              options={hubZones}
              disabled={!selectedHubId || isLoading}
              onChange={setSelectedZoneId}
              value={selectedZoneId}
              showSearch
              optionFilterProp="name"
              placeholder={intl.formatMessage({
                id: 'bosta_coding.zones_geofencing.selectors.zone_placeholder'
              })}
              fieldNames={{ label: 'name', value: '_id' }}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({
              id: 'bosta_coding.codes_geofencing.selectors.street_codes_label'
            })}
          >
            <Select
              options={zoneCodes}
              disabled={!selectedZoneId || isLoading}
              value={selectedStreetCode}
              onChange={setSelectedStreetCode}
              showSearch
              optionFilterProp="name"
              placeholder={intl.formatMessage({
                id: 'bosta_coding.codes_geofencing.selectors.street_codes_placeholder'
              })}
              fieldNames={{ label: 'name', value: '_id' }}
            />
          </Form.Item>
        </div>
        <div className="br-bosta-coding__extra-actions">
          <Form.Item>
            <div className="br-bosta-coding__switch">
              <Switch
                onChange={setShowAllCodes}
                checked={showAllCodes}
                disabled={!selectedZoneId}
              />
              <span className="body-medium">
                {intl.formatMessage({
                  id: 'bosta_coding.codes_geofencing.selectors.show_all_codes'
                })}
              </span>
            </div>
          </Form.Item>
        </div>
        <div className="br-bosta-coding__actions">
          <BRButton
            htmlType="submit"
            type="primary"
            disabled={
              !selectedHubId ||
              !selectedZoneId ||
              (!isDrawingCodeGeofencing && !selectedStreetCode) ||
              codeGeofencingErrors.containOutsidePoints ||
              codeGeofencingErrors.polygonsIntersecting
            }
            label={intl.formatMessage({
              id: isDrawingCodeGeofencing ? 'common.create' : 'common.save'
            })}
          />
          <BRButton
            type={
              isDrawingCodeGeofencing || isCreatingCodeEnabled
                ? 'basic'
                : 'primary'
            }
            onClick={handleCreateCodeClick}
            disabled={!selectedZoneId}
            label={intl.formatMessage({
              id: isCreatingCodeEnabled
                ? 'common.cancel'
                : isDrawingCodeGeofencing
                ? 'bosta_coding.codes_geofencing.selectors.clear_street_code_geofencing'
                : 'bosta_coding.codes_geofencing.selectors.create_new_street_code'
            })}
          />
          {selectedStreetCode && (
            <BRButton
              type="destructive-primary"
              onClick={handleDeleteStreetCodeClick}
              label={intl.formatMessage({
                id: 'bosta_coding.codes_geofencing.selectors.delete_street_code'
              })}
            />
          )}
        </div>
        <div className="br-bosta-coding__errors">
          {codeGeofencingErrors.containOutsidePoints && (
            <span>
              {intl.formatMessage({
                id: 'bosta_coding.codes_geofencing.errors.invalid_points'
              })}
            </span>
          )}
          {codeGeofencingErrors.polygonsIntersecting && (
            <span>
              {intl.formatMessage({
                id: 'bosta_coding.codes_geofencing.errors.intersecting_polygons'
              })}
            </span>
          )}
        </div>
      </Form>
    </div>
  );
};

export default CodesGeofencingSelectors;
