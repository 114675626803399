import { useIntl } from 'react-intl';

import { ReactComponent as SearchLensIcon } from 'assets/bosta-icons/SearchLens.svg';

import './EmptyDataLabel.less';

const EmptyDataLabel = () => {
  const intl = useIntl();

  return (
    <div className="br-hubs-receive-from-business__pickup-scanning__scanned-packages__empty-data-label">
      <SearchLensIcon />
      <div>
        {intl.formatMessage({
          id: 'hubs.receive_from_business.pickup_scanning.scanned_packages.empty_list'
        })}
      </div>
    </div>
  );
};

export default EmptyDataLabel;
