import React from 'react';
import classnames from 'classnames';

import { getMaxNumOfAttempts } from 'common/countries/countries-mapping';

import './AttemptsCount.less';

export const AttemptsCount = ({ attemptsCount, totalAttempts = getMaxNumOfAttempts().DELIVERY }) => {
  const attemptsArray = Array.from(Array(getMaxNumOfAttempts().DELIVERY).keys());

  const { 0: first, [attemptsArray.length - 1]: last } = attemptsArray;

  const color =
    attemptsCount === 0
      ? 'gray'
      : attemptsCount === first + 1
      ? 'green'
      : attemptsCount === last + 1 || attemptsCount > last + 1
      ? 'red'
      : 'yellow';

  return (
    <div className="display-flex align-center">
      <div className="br-attempts-count shadow-sm">
        {attemptsArray
          .map((count) => (
            <div
              key={count}
              className={classnames({
                [color]: attemptsCount >= count + 1,
                gray: attemptsCount < count + 1
              })}
            ></div>
          ))
          .reverse()}
      </div>
      <div
        className={classnames('br-attempts-count__text', {
          'attempts-count-text__with-underline': attemptsCount !== 0
        })}
      >
        <div className="attempts-count-text body-medium">
          {attemptsCount > totalAttempts ? `${totalAttempts}+` : attemptsCount}
        </div>
        {attemptsCount <= totalAttempts && (
          <div className="attempts-num-text body">/{totalAttempts}</div>
        )}
      </div>
    </div>
  );
};
